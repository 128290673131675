import moment from "moment";

export const getApexChartOptions = (data) => {

    let options = {
        chart: {
            id: data.name,
            type: 'line',
            zoom: {
                enabled: false
            },
            animations: {
                enabled: false,
                speed: 1000,
                easing: 'linear',
                // animateGradually: {
                //     enabled: false,
                //     speed: 1000
                // },
                // dynamicAnimation: {
                //     speed: 1000
                // }
            },
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            }
        },
        title:
        {
            text: data.title || '',
            align: 'left',
            floating: false,
            offsetX: -10,
            offsetY: 0,
            style: {
                color: data.titleColor,
                fontWeight: '400',
                fontFamily: 'Noto Sans',
                fontSize: '16px',
                lineHeight: '22',
            },
        },
        grid: {
            show: false,      // can either change here to disable all grids
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        tooltip: {
            enabled: false
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
            colors: [data.lineColor],
            width: 1.5,
            dashArray: 0,
        },
        xaxis: {
            // reversed: true,
            // show: true,
            show: data.showXAxis ? true : false,
            tickAmount: data.xTickPoint ? data.xTickPoint : 7,
            // forceNiceScale: true,
            type: "datetime",
            range: data.range,
            // tickPlacement: 'between',
            position: 'bottom',
            labels: {
                show: false,
                // show: data.showXAxisLabel ? true : false,
                formatter: function (value, timestamp) {
                    // return "";
                    return moment(timestamp).format("mm:ss");
                },
                style: {
                    colors: ['#707070'],
                    // fontSize: '12px',
                    // fontFamily: 'Open Sans',
                    font: 'normal normal 600 20px/30px Open Sans',
                    letterSpacing: '0px',
                    fontWeight: 400,
                    // cssClass: 'apexcharts-xaxis-label',
                }
            },
            axisBorder: {
                // show: true,
                show: data.showXAxisBorder ? true : false
            }
        },
        yaxis: {
            // show: data.showYAxis ? true : false,
            showAlways: true,
            // showForNullSeries: true,
            // tickAmount: 2,
            tickAmount: data.yTickPoint,
            min: data.yMin,
            max: data.yMax,
            forceNiceScale: true,
            labels: {
                show: data.showYAxis ? true : false,
                align: 'right',
                formatter: val => val.toFixed(0),
                style: {
                    colors: ['#707070'],
                    // fontSize: '12px',
                    // fontFamily: 'Open Sans',
                    font: 'normal normal 600 20px/30px Open Sans',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                }
            },
            axisBorder: {
                // show: true,
                show: data.showYAxisBorder ? true : false
            },
            
        }
    };

    return options;
}