import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import CustomSelect from "../CustomSelect/CustomSelect";
import { connect } from "react-redux";
import { DEVICE_TYPES, BLOOD_GROUP_OPTION } from "../../common/constant/common-constants";
import "./PatientDetails.scss";
import ModalBackdrop from "../ModalBackdrop/ModalBackdrop";
import { patienDischargeAction, patientDataAction } from '../../modules/actions/action';
import TimePicker from "react-time-picker/dist/TimePicker";
import { admitPatient, dischargePatient } from "../../helper/patient-helper";
import { getHospitalDetails } from "../../helper/home-page-helper";
import { hospitalDataAction } from "../../modules/actions/action";

function PatientDetails(props) {

    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [gender, setGender] = useState("");
    const [age, setAge] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [admissionDate, setAdmissionDate] = useState(new Date());
    const [admissionTime, setAdmissionTime] = useState(moment(new Date()).format("HH:mm"));
    const [bloodGroup, setBloodGroup] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [physician, setPhysician] = useState("");
    const [showDischargeModal, setShowDischargeModal] = useState(false);
    const [isAdmitVisible, setIsAdmitVisible] = useState(false);
    const [patientData, setPatientData] = useState(props.patientData);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (props.patientData && props.patientData.name) {
            let data = props.patientData;
            // if (data.admissionDate) data.admissionDate = moment(data.admissionDate).toDate();
            setPatientData(data);
        } else {
            emptyAllFields();
            setPatientData("");
        }

    }, [props.patientData])

    useEffect(() => {

        setErrorMsg(null);

        if (patientName && gender && age && admissionDate && admissionTime) {
            setIsAdmitVisible(true);
        } else {
            setIsAdmitVisible(false);
        }

    }, [patientName,
        patientId,
        gender,
        age,
        height,
        weight,
        admissionDate,
        admissionTime,
        bloodGroup,
        diagnosis,
        physician])

    const handleSubmitAdmit = (value) => {
        let data = {
            patientName: patientName,
            patientId: patientId,
            gender: gender,
            age: age,
            height: height,
            weight: weight,
            admissionDate: moment(admissionDate).format("DD-MM-YYYY"),
            admissionTime: admissionTime,
            bloodGroup: bloodGroup,
            diagnosis: diagnosis,
            physician: physician,
            bedId: props.bedId
        }

        admitPatient(data)
            .then((data) => {
                let newData = {
                    bedId: props.bedId,
                    data: {
                        session_id: data.sessionId,
                        name: patientName,
                        patientId: patientId,
                        gender: gender,
                        age: age,
                        height: height,
                        weight: weight,
                        admissionDate: admissionDate,
                        admissionTime: admissionTime,
                        bloodGroup: bloodGroup,
                        diagnosis: diagnosis,
                        physician: physician,
                    }
                }
                props.patientDataAction(newData);
            }).catch((err) => {
                setErrorMsg(err);
            })

        console.log("handle admit was clicked")
    }

    const handleSubmitDischarge = () => {
        setShowDischargeModal(true);
    }
    const handleModalCancel = () => {
        setShowDischargeModal(false);
    }

    const emptyAllFields = () => {

        setPatientName("");
        setPatientId("");
        setGender("");
        setAge("");
        setHeight("");
        setWeight("");
        setBloodGroup("");
        setDiagnosis("");
        setPhysician("")
    }

    const handleModalDischarge = () => {
        if (props.bedId) {
            let data = {
                bedId: props.bedId,
                patientSessionId: props.patientData.session_id
            };
            dischargePatient(data)
                .then((res) => {
                    props.patienDischargeAction({ bedId: props.bedId })
                    getHospitalDetails().then((res) => {
                        if (!res || !res.data && !res.data.wards) return;
                        res.data.wards.map((ward) => {
                            ward.beds.map((bed) => {
                                let data = {
                                    bedId: bed.id,
                                    data: bed.patientDetails
                                }
                                
                                props.patientDataAction(data);
                                return bed.id;
                            })
                    }).catch(err => {
                        console.log(err);
                    });
                }).catch((err) => {
                    setErrorMsg(err);
                })
                setShowDischargeModal(false);
            })
        }
    }

    return (
        <div className="patient-detail-container">
            <div className="patient-details">
                <div className="patient-name-container pd-input-container">
                    <div className="input-label patient-name-label">
                        Patient Name
                        <span className="required-field">*</span>
                    </div>
                    <input id="patient-name"
                        className="patient-name pd-input"
                        placeholder="Enter Patient Name"
                        value={patientData ? patientData.name : patientName}
                        readOnly={patientData ? true : false}
                        onChange={(event) => setPatientName(event.target.value)}
                    />
                </div>
                <div className="patient-id-container pd-input-container">
                    <div className="input-label patient-id-label">Patient ID</div>
                    <input
                        id="patient-id"
                        className="patient-id pd-input"
                        placeholder="Enter Patient Id"
                        value={patientData ? patientData.patientId : patientId}
                        readOnly={patientData ? true : false}
                        onChange={(event) => setPatientId(event.target.value)}
                    />
                </div>
                {/* <div className="patient-category-container pd-input-container">
                    <div className="input-label patient-category-label">Patient Category</div>
                    <input id="patient-category" className="patient-category pd-input" placeholder="Enter Patient Category" />
                </div> */}
                <div className="patient-category-container pd-input-container">
                    <div className="input-label patient-category-label">Sex
                        <span className="required-field">*</span></div>
                    <div className="gender-input-container">
                        <input
                            className="pd-input-radio"
                            type="radio"
                            id="male"
                            name="gender"
                            value="Male"
                            disabled={patientData ?
                                ((patientData.gender === "M") ? false : true)
                                : false}
                            // disabled={patientData ? true : false}
                            checked={(patientData && patientData.gender === "M") ? true :
                                (gender && gender === "M") ? true : false}
                            onChange={() => { if (!patientData) setGender("M") }}
                        />
                        <label htmlFor="male" className="male-label">Male</label><br />
                        <input
                            className="pd-input-radio"
                            type="radio"
                            id="female"
                            name="gender"
                            value="Female"
                            disabled={patientData ?
                                ((patientData.gender === "F") ? false : true)
                                : false}
                            // disabled={patientData ? true : false}
                            checked={(patientData && patientData.gender === "F") ? true :
                                gender && gender === "F" ? true : false}
                            onChange={() => { if (!patientData) setGender("F") }}
                        />
                        <label htmlFor="female" className="female-label">Female</label><br></br>
                        <input
                            className="pd-input-radio"
                            type="radio"
                            id="other"
                            name="gender"
                            value="Other"
                            disabled={patientData ?
                                ((patientData.gender === "Other") ? false : true)
                                : false}
                            // disabled={patientData ? true : false}
                            checked={(patientData && patientData.gender === "Other") ? true :
                                gender && gender === "Other" ? true : false}
                            // onChange={() => setGender("Other")}
                            onChange={() => { if (!patientData) setGender("Other") }}
                        />
                        <label htmlFor="other" className="other-label">Other</label><br></br>
                    </div>
                </div>
                <div className="patient-dob-height-weight-container pd-input-container">
                    <div className="age-container">
                        <div className="input-label patient-age-label">Age
                            <span className="required-field">*</span></div>
                        <input id="patient-age"
                            className="patient-age pd-input"
                            placeholder="Age"
                            value={patientData ? patientData.age : age}
                            readOnly={patientData ? true : false}
                            onChange={(event) => setAge(event.target.value)}
                        />
                    </div >
                    <div className="height-container">
                        <div className="input-label patient-height-label">Height (cm)</div>
                        <input
                            id="patient-height"
                            className="patient-height pd-input"
                            placeholder="Height (cm)"
                            value={patientData ? patientData.height : height}
                            readOnly={patientData ? true : false}
                            onChange={(event) => setHeight(event.target.value)}
                        />
                    </div >
                    <div className="weight-container">
                        <div className="input-label patient-weight-label">Weight (Kg)</div>
                        <input
                            id="patient-weight"
                            className="patient-weight pd-input"
                            placeholder="Weight (Kg)"
                            value={patientData ? patientData.weight : weight}
                            readOnly={patientData ? true : false}
                            onChange={(event) => setWeight(event.target.value)}
                        />
                    </div >
                    < div className="admission-calendar-container" >
                        <div className="input-label patient-admission-label">Admission Date
                            <span className="required-field">*</span></div>
                        <div className="admin-date">
                            <DatePicker
                                className="admission-calendar"
                                onChange={(date) => setAdmissionDate(date)}
                                value={patientData ? patientData.admissionDate : admissionDate}
                                clearIcon={null}
                                openCalendarOnFocus={true}
                                format="dd-MM-yyyy"
                                disabled={patientData ? true : false}
                                showLeadingZeros={true}
                            />
                        </div>
                    </div >
                    <div className="admission-time-container start-time form-input">
                        <div className="input-label label">Admission Time</div>
                        <div className="time-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <TimePicker
                                className="admission-time-picker"
                                onChange={(date) => setAdmissionTime(date)}
                                // value={patientData ? patientData.admissionTime : admissionTime}
                                clearIcon={false}
                                disabled={patientData ? true : false}
                            />
                        </div>
                    </div>

                    <div className="blood-group-container">
                        <div className="input-label patient-admission-label">Blood Group</div>
                        <div className="blood-group-select">
                            <CustomSelect
                                className="blood-group"
                                handleChange={(data) => setBloodGroup(data.name)}
                                value={
                                    patientData ? (
                                        patientData.bloodGroup ?
                                            (BLOOD_GROUP_OPTION.filter((option) => patientData.bloodGroup === option.name)) : "")
                                        : bloodGroup && BLOOD_GROUP_OPTION.filter((option) => bloodGroup === option.name)}
                                label='PARAMETER NAME'
                                options={BLOOD_GROUP_OPTION}
                                isDisabled={patientData ? true : false}
                                placeholder='Blood Group'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                    </div>
                </div >
                <div className="pd-input-container">
                    <div className="input-label ">Diagnosis</div>
                    <input
                        id="patient-diagnosis"
                        className="pd-input"
                        placeholder="Diagnosis"
                        value={patientData ? patientData.diagnosis : diagnosis}
                        readOnly={patientData ? true : false}
                        onChange={(event) => setDiagnosis(event.target.value)} />
                </div>
                <div className="pd-input-container">
                    <div className="input-label">Physician</div>
                    <input
                        id="patient-monitor-id"
                        className=" pd-input"
                        placeholder="Enter Physician..."
                        value={patientData ? patientData.physician : physician}
                        readOnly={patientData ? true : false}
                        onChange={(event) => setPhysician(event.target.value)}
                    />
                </div>
                {/* <div className="patient-ventilator-id-container pd-input-container">
                    <div className="input-label patient-ventiltor-id-label">Enter Ventilator Id</div>
                    <input id="patient-ventiltor-id" className="patient-ventiltor-id pd-input" placeholder="Enter Ventilator Id" />
                </div> */}
                <div className="submit-container">
                    <div className={
                        patientData ? (`discharge submit-button`) :
                            (isAdmitVisible ? 'submit-button' : `submit-button button-disabled`)}
                        onClick={patientData ?
                            (event) => handleSubmitDischarge(event) :
                            (event) => handleSubmitAdmit(event)
                        }>
                        {patientData ? "Discharge Patient" : "Admit Patient"}</div>
                </div>
            </div >
            {
                showDischargeModal && <ModalBackdrop />
            }
            {
                showDischargeModal &&
                <div className="discharge-modal-container">
                    <div className="discharge-content">
                        <span className="discharge-header">Are you sure you want to discharge the patient?</span>
                        <span className="discharge-text">Post discharge you won’t be able to view the patient’s data, so make sure you have recorded the required vitals</span>
                        <div className="discharge-btn-container">
                            <div className="modal-btn cancel" onClick={handleModalCancel}>Cancel</div>
                            <div className="modal-btn discharge" onClick={handleModalDischarge}>Discharge Patient</div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

const mapStateToProps = (state, ownProps) => {

    let bds = state.bedDetailsReducer.bedDetails
        && state.bedDetailsReducer.bedDetails[ownProps.bedId];

    let pData = state.patientDataReducer.patientData && state.patientDataReducer.patientData[ownProps.bedId];

    let mData = state.deviceVitalDataReducer.deviceVitalData
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId][DEVICE_TYPES.MONITOR];

    return {
        bedDetails: bds,
        patientData: pData,
        monitorData: mData,
        config: state.configReducer.config.bedDetailConfig
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        patienDischargeAction: (payload) => dispatch(patienDischargeAction(payload)),
        patientDataAction: (payload) => dispatch(patientDataAction(payload)),
        hospitalDataAction: (payload) => dispatch(hospitalDataAction(payload)),
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);