export const Types = {
    BED_DETAILS: "BED_DETAILS",
    BED_ID_ARRAY: "BED_ID_ARRAY",
    FAVORITE_BED_ID_ARRAY: "FAVORITE_BED_ID_ARRAY",
    HOSPITAL_DATA: "HOSPITAL_DATA",
    WARD_DATA: "WARD_DATA",
    PATIENT_DATA: "PATIENT_DATA",
    DEVICE_VITAL_DATA: "DEVICE_VITAL_DATA",
    MONITOR_HEARTBEAT_DATA: "MONITOR_HEARTBEAT_DATA",
    DEVICE_ECG_WAVEFORM_DATA: "DEVICE_ECG_WAVEFORM_DATA",
    DEVICE_HR_WAVEFORM_DATA: "DEVICE_HR_WAVEFORM_DATA",
    ALARM_DATA: "ALARM_DATA",
    VENTILATOR_ALARM_DATA: "VENTILATOR_ALARM_DATA",
    NOTIFICATION_DATA: "NOTIFICATION_DATA",
    CONFIG_VIEW_TYPE: "CONFIG_VIEW_TYPE",
    CONFIG_FILTER_TYPE: "CONFIG_FILTER_TYPE",
    CONFIG_MODAL: "CONFIG_MODAL",
    CONFIG_BED_DETAILS: "CONFIG_BED_DETAILS",
    CONFIG_ADD_COLUMN: "CONFIG_ADD_COLUMN",
    CONFIG_REMOVE_COLUMN: "CONFIG_REMOVE_COLUMN",
    CLEAR_ALARM: "CLEAR_ALARM",
    PATIENT_DISCHARGE: "PATIENT_DISCHARGE",
    CLEAR_STORE: "CLEAR_STORE",
    SELECTED_BED: 'SELECTED_BED',
    SELECTED_WARD: 'SELECTED_WARD',
    BED_DATA: 'BED_DATA',
    CLEAR_BED_ALARM: 'CLEAR_BED_ALARM',
    VENTILATOR_VITAL_DATA: "VENTILATOR_VITAL_DATA",
    SIDEBAR_ACTIVE_ICON: "SIDEBAR_ACTIVE_ICON",
    ACTIVE_DEVICE: "ACTIVE_DEVICE",
    VENTILATOR_MODES_DATA: 'VENTILATOR_MODES_DATA',
    VENTILATOR_WAVEFORM_DATA: "VENTILATOR_WAVEFORM_DATA",
    VENTILATOR_HEARTBEAT_DATA: "VENTILATOR_HEARTBEAT_DATA",
    VENTILATOR_STANDBY_DATA: "VENTILATOR_STANDBY_DATA"
};