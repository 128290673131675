import { io } from "socket.io-client";
import { getLocalStorageCustom } from "../common/utils/StorageUItils";

let socketInstance = null;
let bedDetailAction = null;
let wardDetailAction = null;
let bedIdAction = null;
let patientDataAction = null;
let deviceVitalDataAction = null;
let deviceEcgWaveformDataAction = null;
let deviceHrWaveformDataAction = null;
let alarmDataAction = null;
let ventilatorAlarmDataAction = null;
let monitorHeartbeatAction = null;
let ventilatorHeartbeatAction = null;
let notificationDataAction = null;
let ventilatorVitalDataAction = null;
let ventilatorModesDataAction = null;
// let ventilatorWaveformDataAction = null;
let ventilatorWaveformData = null;
let ventilatorStandbyDataAction = null;


let vInterval = null;
let mInterval = null;
let aInterval = null;

export const setBedDetailsAction = (bdAction) => {
    bedDetailAction = bdAction;
}

export const setWardDetailAction = (wdAction) => {
    wardDetailAction = wdAction;
}

export const setBedIdAction = (bIdAction) => {
    bedIdAction = bIdAction;
}

export const setPatientDataAction = (pdAction) => {
    patientDataAction = pdAction;
}

export const setDeviceVitalDataAction = (ddAction) => {
    deviceVitalDataAction = ddAction;
}

export const setMonitorHeartbeatAction = (ddAction) => {
    monitorHeartbeatAction = ddAction;
}

export const setDeviceEcgWaveformDataAction = (dewdAction) => {
    deviceEcgWaveformDataAction = dewdAction;
}

export const setDeviceHrWaveformDataAction = (dhwdAction) => {
    deviceHrWaveformDataAction = dhwdAction;
}

export const setAlarmDataAction = (adAction) => {
    alarmDataAction = adAction;
}

export const setVentilatorAlarmDataAction = (adAction) => {
    ventilatorAlarmDataAction = adAction;
}

export const setVentilatorHeartbeatDataAction = (hbAction) => {
    ventilatorHeartbeatAction = hbAction;
}

export const setNotificationDataAction = (ndAction) => {
    notificationDataAction = ndAction;
}

export const setVentilatorVitalDataAction = (vDataAction) => {
    ventilatorVitalDataAction = vDataAction;
}

export const setVentilatorModesDataAction = (vModesDataAction) => {
    ventilatorModesDataAction = vModesDataAction;
}

// export const setVentilatorWaveformDataAction = (vWaveformDataAction) => {
//     ventilatorWaveformDataAction = vWaveformDataAction;
// }

export const setVentilatorWaveformData = (vWaveformData) => {
    ventilatorWaveformData = vWaveformData;
}

export const setVentilatorStandbyDataAction = (ventilatorStandby) => {
    ventilatorStandbyDataAction = ventilatorStandby
}


export const openSocketConnection = (hospitalData) => {
    console.log("opening connection");

    let hospitalId = getLocalStorageCustom('hospitalId');
    if (socketInstance === null) {
        console.log("creating socket");
        // socketInstance = io("http://15.206.207.63:8080", {
        // socketInstance = io("http://3.109.221.46:8080", {

        socketInstance = io(process.env.REACT_APP_BASE_URL, {
            // socketInstance = io("https://backend-cms.noccarc-web.com", {


            // extraHeaders: {
            //     "Access-Control-Allow-Origin": "*",
            //     "channelName": hospitalData.hospitalId
            // },
            transports: ['websocket'],
            query: {
                channelName: hospitalId,
            }
        });

    } else if (socketInstance.connected === false) {
        socketInstance.connect();
        // socketInstance.open();
    }
    console.log("socketInstance", socketInstance)
    // produceMonitor();
    // Alarmproduce();
    handleMessages();
}

export const closeSocketConnection = () => {
    console.log("closing connection");
    socketInstance.close();

    socketInstance = null;
    // clearInterval(mInterval);
    // clearInterval(vInterval);
    // clearInterval(aInterval);
}

const handleMessages = () => {
    if (socketInstance === null) {
        console.log("socketInstance is null");
        return;
    }

    socketInstance.on("ward_details", (msg) => {
        // console.log("socket ward_details: " + msg);
        if (wardDetailAction) wardDetailAction(JSON.parse(msg));
    });

    socketInstance.on("patient_data", (msg) => {
        // console.log("socket patient_data: " + msg);
        if (patientDataAction) patientDataAction(JSON.parse(msg))
    });

    socketInstance.on("device_vitals_data", (msg) => {
        // console.log("socket patient_data: " + msg);
        if (deviceVitalDataAction) deviceVitalDataAction(JSON.parse(msg));
    });

    socketInstance.on("monitor_heartbeat_msg_data", (msg) => {
        //console.log("socket monitor_heartbeat_msg_data: " + msg);
        if (monitorHeartbeatAction) monitorHeartbeatAction(JSON.parse(msg));
    });

    socketInstance.on("device_ecg_waveform_data", (msg) => {
        let data = JSON.parse(msg);
        // console.log("deviceEcgWaveformDataAction", msg)
        if (deviceEcgWaveformDataAction) deviceEcgWaveformDataAction(data);
    })

    socketInstance.on("device_hr_waveform_data", (msg) => {
        // console.log("socket patient_data: " + msg);

        let data = JSON.parse(msg);
        // if (data.bedId === 24) {
        //     let time = data.waveform[0].time
        //     console.log("socket hr waveform data ----------------: ", time);
        // }

        if (deviceHrWaveformDataAction) deviceHrWaveformDataAction(JSON.parse(msg));
    })

    socketInstance.on("device_alarms_data", (msg) => {
        // console.log("device_alarms_data:" + msg);
        if (alarmDataAction) alarmDataAction(JSON.parse(msg));
    });

    socketInstance.on("notification_data", (msg) => {
        // console.log("socket notification_data: " + msg);
        if (notificationDataAction) notificationDataAction(JSON.parse(msg));
    });
    socketInstance.on("ventilator_modes_parameter_data", (msg) => {
        //console.log("ventilator_modes_parameter_data", JSON.parse(msg))
        if (ventilatorModesDataAction) ventilatorModesDataAction(JSON.parse(msg));
    })
    socketInstance.on("ventilator_active_alarms_data", (msg) => {
        //console.log("ventilator_active_alarms_data", msg)
        if (ventilatorAlarmDataAction) ventilatorAlarmDataAction(JSON.parse(msg));
    })
    socketInstance.on("ventilator_heartbeat_msg_data", (msg) => {
        //console.log("ventilator_heartbeat_msg_data", msg);
        if (ventilatorHeartbeatAction) ventilatorHeartbeatAction(JSON.parse(msg));
    })
    socketInstance.on("ventilator_alarm_profile_data", (msg) => {
       //console.log("ventilator_alarm_profile_data", msg)
    })
    socketInstance.on("ventilator_monitor_data", (msg) => {
        //console.log("ventilator_monitor_data", JSON.parse(msg))
        if (ventilatorVitalDataAction) ventilatorVitalDataAction(JSON.parse(msg));
    })
    
    socketInstance.on("ventilator_waveform_data", (msg) => {
        // console.log("ventilator_waveform_data", JSON.parse(msg))
        // if (ventilatorWaveformDataAction) ventilatorWaveformDataAction(JSON.parse(msg));
        if (ventilatorWaveformData) ventilatorWaveformData(JSON.parse(msg));
    })

    socketInstance.on("ventilator_ventilation_status_data", (msg) => {
        // console.log("ventilator_ventilation_status_data>>", JSON.parse(msg))
        if (ventilatorStandbyDataAction) ventilatorStandbyDataAction(JSON.parse(msg));
    })
}

const mparameters = {
    "rr": {
        "unit": "b/min",
        "alarm": {
            "low": 12,
            "high": 20
        }
    },
    "hr": {
        "unit": "b/min",
        "alarm": {
            "low": 60,
            "high": 100
        }
    },
    "spo2": {
        "unit": "%",
        "alarm": {
            "low": 95,
            "high": 101
        }
    },
    "temp": {
        "unit": "F",
        "alarm": {
            "low": 96,
            "high": 100
        }
    },
    "bpLow": {
        "unit": "mmHg",
        "alarm": {
            "low": 60,
            "high": 90
        }
    },
    "bpHigh": {
        "unit": "mmHg",
        "alarm": {
            "low": 90,
            "high": 140
        }
    }
}


const produceMonitor = async () => {
    let i = 0
    if (!mInterval) {
        mInterval = setInterval(async () => {
            try {
                // send a message to the configured topic with
                // the key and value formed from the current value of `i`
                const rr_array = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
                const hr_array = [58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93]
                const spo2_array = [92, 93, 94, 95, 96, 97, 98, 99, 100]
                const temp_array = [92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102]
                const bp_low_array = [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80]
                const bp_high_array = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125]

                var raw = {
                    bed_name: 'Bed1',
                    monitor_name: "PM1",
                    rr: rr_array[Math.floor(Math.random() * rr_array.length)],
                    hr: hr_array[Math.floor(Math.random() * hr_array.length)],
                    spo2: spo2_array[Math.floor(Math.random() * spo2_array.length)],
                    temp: temp_array[Math.floor(Math.random() * temp_array.length)],
                    bpLow: bp_low_array[Math.floor(Math.random() * bp_low_array.length)],
                    bpHigh: bp_high_array[Math.floor(Math.random() * bp_high_array.length)]
                }

                let data = {
                    hospitalId: 1,
                    wardId: 1,
                    bedId: '1',
                    deviceId: 'deviceId',
                    dongleId: 'dongle-00001',
                    deviceType: 'MONITOR',
                    channelName: 'device_vitals_data',
                    vitals: []
                }

                for (const [key, value] of Object.entries(mparameters)) {

                    const status = (raw[key] < value.alarm.low) ||
                        raw[key] > value.alarm.high ?
                        "CRITICAL" : "NORMAL"

                    const x = {
                        "name": key,
                        "unit": value.unit,
                        "data": [{
                            "value": raw[key],
                            "status": status,
                            "time": new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
                        }]
                    }

                    data['vitals'].push(x)
                }
                let value = JSON.stringify(data);

                // console.log("data: ", data);
                deviceVitalDataAction(data);

                // if the message is written successfully, log it and increment `i`
                console.log("writes: ", i)
                i++
            } catch (err) {
                console.error("could not write message " + err)
            }
        }, 1000);
    }
}

const Alarmproduce = async () => {

    let i = 0
    aInterval = setInterval(async () => {

        try {
            // send a message to the configured topic with
            // the key and value formed from the current value of `i`
            const alarm_name_array = ['High Systolic BP High Systolic BP High Systolic BP', 'High Diastolic BP High Diastolic BP High Diastolic BP ', 'High RR', 'High HR', 'High Temperature', 'Low Systolic BP', 'Low Diastolic BP', 'Low RR', 'Low HR', 'Low Temperature', 'Low Spo2']
            const alarm_value_array = [142, 97, 24, 112, 102.8, 84, 89, 59, 11, 57, 95.8, 61]
            const priority = ['HIGH', 'HIGH', 'HIGH', 'HIGH', 'HIGH', 'LOW', 'LOW', 'LOW', 'LOW', 'LOW', 'LOW']

            // const idx = Math.floor(Math.random() * alarm_name_array.length);
            const idx = 0;

            var raw = {
                bed_name: 1,
                monitor_name: 1,
                name: alarm_name_array[idx],
                value: alarm_value_array[idx],
                priority: priority[idx],
            }

            let data = {
                hospitalId: 1,
                wardId: 1,
                bedId: 1,
                deviceId: 1,
                dongleId: 'dongle-00001',
                deviceType: 'MONITOR',
                alarms: [{
                    id: 1,
                    name: raw.name,
                    value: raw.value,
                    priority: raw.priority,
                    rank: 1,
                    "time": new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
                }]
            }

            alarmDataAction(data);

            // if the message is written successfully, log it and increment `i`
            console.log("a writes: ", i);
            i++;
        } catch (err) {
            console.error("could not write message " + err)
        }
    }, 10000)
};

const produceMonitorWaveform = async () => {
    let i = 0
    if (!mInterval) {
        mInterval = setInterval(async () => {
            try {
                // send a message to the configured topic with
                // the key and value formed from the current value of `i`
                const rr_array = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
                const hr_array = [58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93]
                const spo2_array = [92, 93, 94, 95, 96, 97, 98, 99, 100]
                const temp_array = [92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102]
                const bp_low_array = [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80]
                const bp_high_array = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125]

                var raw = {
                    bed_name: 'Bed1',
                    monitor_name: "PM1",
                    rr: rr_array[Math.floor(Math.random() * rr_array.length)],
                    hr: hr_array[Math.floor(Math.random() * hr_array.length)],
                    spo2: spo2_array[Math.floor(Math.random() * spo2_array.length)],
                    temp: temp_array[Math.floor(Math.random() * temp_array.length)],
                    bpLow: bp_low_array[Math.floor(Math.random() * bp_low_array.length)],
                    bpHigh: bp_high_array[Math.floor(Math.random() * bp_high_array.length)]
                }

                let data = {
                    hospitalId: 1,
                    wardId: 1,
                    bedId: '1',
                    deviceId: 'deviceId',
                    dongleId: 'dongle-00001',
                    deviceType: 'MONITOR',
                    channelName: 'device_vitals_data',
                    vitals: []
                }

                for (const [key, value] of Object.entries(mparameters)) {

                    const status = (raw[key] < value.alarm.low) ||
                        raw[key] > value.alarm.high ?
                        "CRITICAL" : "NORMAL"

                    const x = {
                        "name": key,
                        "unit": value.unit,
                        "data": [{
                            "value": raw[key],
                            "status": status,
                            "time": new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
                        }]
                    }

                    data['vitals'].push(x)
                }
                let value = JSON.stringify(data);

                // console.log("data: ", data);
                deviceVitalDataAction(data);

                // if the message is written successfully, log it and increment `i`
                console.log("writes: ", i)
                i++
            } catch (err) {
                console.error("could not write message " + err)
            }
        }, 1000);
    }
};