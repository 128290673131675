export const SORT_TYPES = {

    "EWS_SCORE": "EWS_SCORE",
    "FAVORITE": "FAVORITE"
}

export const GRAPH_TYPE = {
    ECG: "ECG",
    PLETH: "PLETH",
    RESP: "RESP",
    ETCO2: "ETCO2",
    IBP1: "IBP1",
    IBP2: "IBP2",
}

export const VIEW_TYPES = {

    "GRID_VIEW": "GRID_VIEW",
    "LIST_VIEW": "LIST_VIEW"
}

export const ALARM_FILTER = {
    MONITOR: [
        { name: 'ALL', label: 'All', id: 1 },
        // { name: 'MONITOR', label: 'Monitor', id: 2 },
        // { name: 'VENTILATOR', label: 'Ventilator', id: 3 }
    ],
    VENTILATOR:[
        { name: null, label: 'All Events', id: 1 },
        { name: 'ACTIVE', label: 'Active Alarm', id: 2 },
        { name: 'PHYSIOLOGICAL', label: 'Physiological Alarms', id: 3 },
        { name: 'TECHNICAL', label: 'Technical Alarms', id: 4 },
        { name: 'OPERATIONAL', label: 'Operational Logs', id: 5 },
    ]
}

export const checkType = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
}

export const DUMMY_PATIENT = {
    NAME: "Dummy Patient",
    UHID: "00000"
}

export const FILTER_TYPES = {

    FAVORITE: "FAVORITE"
}

export const PATIENT_VITALS = {
    SPO2: "SPO2",
    BP: "BP",
    HR: "HR",
    TEMP: "TEMP",
    RR: "RR"
}

export const VITAL_ECG = {
    I: "I",
    II: "II",
    III: "III",
    AVL: "AVL",
    AVF: "AVF",
    V1: "V1"
}

export const MORE_GRAPH_OPTIONS_VITAL = {
    MONITOR: [{
        "name": "ECG1",
        "label": "I",
        "type": GRAPH_TYPE.ECG,
        "min": 1500,
        "max": 2100
    }, {
        "name": "ECG2",
        "label": "II",
        "type": GRAPH_TYPE.ECG,
        "min": 0,
        "max": 100
    }, {
        "name": "ECG3",
        "label": "III",
        "type": GRAPH_TYPE.ECG,
        "min": 0,
        "max": 100
    }, {
        "name": "ECGAVF",
        "label": "aVF",
        "type": GRAPH_TYPE.ECG,
        "min": 0,
        "max": 100
    }, {
        "name": "ECGAVL",
        "label": "aVL",
        "type": GRAPH_TYPE.ECG,
        "min": 0,
        "max": 100
    }, {
        "name": "ECGAVR",
        "label": "aVR",
        "type": GRAPH_TYPE.ECG,
        "min": 2000,
        "max": 2500
    }, {
        "name": "ECG5",
        "label": "V",
        "type": GRAPH_TYPE.ECG,
        "min": 0,
        "max": 100
    }]
}

export const RESP_OBJ = {
    "name": "RESP",
    "label": "resp",
    "type": GRAPH_TYPE.RESP,
    "min": 0,
    "max": 100
}

export const PLETH_OBJ = {
    "name": "PLETH",
    "label": "pleth",
    "type": GRAPH_TYPE.PLETH,
    "min": 0,
    "max": 100
}
export const ETCO2_OBJ = {
    "name": "ETCO2",
    "label": "etco2",
    "type": GRAPH_TYPE.ETCO2,
    "min": 0,
    "max": 100
}

export const IBP1_OBJ = {
    "name": "IBP1",
    "label": "ibp1",
    "type": GRAPH_TYPE.IBP1,
    "min": 0,
    "max": 100
}

export const IBP2_OBJ = {
    "name": "IBP2",
    "label": "ibp2",
    "type": GRAPH_TYPE.IBP2,
    "min": 0,
    "max": 100
}

export const PAGE_PARENT = {
    PATIENT_MODAL: "PATIENT_MODAL",
    BED_DETAIL: "BED_DETAIL"
}

export const ADD = "ADD";
export const REMOVE = "REMOVE";

export const TIMELINE = {
    GRAPH_ARRAY: ["LIVE", "4H", "8H", "12H", "24H"],
    LIVE: "LIVE"
}

export const CELCIUS_DEGREE_CODE = '&#8451';

export const VITAL_OPTION = {
    VITAL_GRAPH: "VITAL_GRAPH",
    VITAL_TABLE: "VITAL_TABLE"
}

export const parameterSorting = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

export const parameterOptionList = [
    { name: 'hr', label: 'HR', id: "1" },
    { name: 'rr', label: 'RR', id: "2" },
    { name: 'spo2', label: 'SPO2', id: "3" },
    { name: 'pr', label: 'PR', id: "4" },
    { name: 'bpSystolic', label: 'NIBP SYS', id: "5" },
    { name: 'bpDiastolic', label: 'NIBP DIA', id: "6" },
    { name: 'bpMean', label: 'NIBP Mean', id: "7" },
    { name: 'temperature1', label: 'Temp 1', id: "8" },
    { name: 'temperature2', label: 'Temp 2', id: "9" },
    { name: 'temperatureDelta', label: 'TD', id: "10" },
    { name: 'ibp1Systolic', label: 'IBP-1 Systolic', id: "11" },
    { name: 'ibp1Diastolic', label: 'IBP-1 Diastolic', id: "12" },
    { name: 'ibp1Mean', label: 'IBP-1 Mean', id: "13" },
    { name: 'ibp2Systolic', label: 'IBP-2 Systolic', id: "14" },
    { name: 'ibp2Diastolic', label: 'IBP-2 Diastolic', id: "15" },
    { name: 'ibp2Mean', label: 'IBP-2 Mean', id: "16" },
    { name: 'endTidalCo2', label: 'ETCO2', id: "17" },
    { name: 'inspiredMinimumCo2', label: 'Inspired Min. CO2', id: "18" },
    { name: 'airwayRespirationRate', label: 'Airway Respiration Rate', id: "19" },
];

export const ventilatorParameterOptionList = [
    { name: 'pip', label: 'PiP', id: "1" },
    { name: 'pmean', label: 'Pmean', id: "15" },
    { name: 'ppleatu', label: 'Pplat', id: "2" },
    { name: 'fio2', label: 'FiO2', id: "34" },
    { name: 'i_e', label: 'I:E', id: "3" },
    { name: 'vti', label: 'VTi', id: "5" },
    { name: 'vte', label: 'VTe', id: "6" },
    { name: 'rr', label: 'RR', id: "7" },
    { name: 'mve', label: 'MVe', id: "8" },
    { name: 'mveSpont', label: 'MVe sp', id: "9" },
    { name: 'flowRate', label: 'Flow Rate', id: "10" },
    { name: 'te', label: 'Te', id: "16" },
    { name: 'ti', label: 'Ti', id: "13" },
    // { name: 'ri', label: 'Ri', id: "14" },
    { name: 'flowee', label: 'Flowee', id: "17" },
    // { name: 'pwob', label: 'WOBpat', id: "19" },
    { name: 'rsbi', label: 'RSBI', id: "22" },
    { name: 'elastance', label: 'Elastance', id: "23" },
    {name: 'leakagePercentage', label: 'Leakage %', id: "27"},
    // { name: 'tvPBW', label: 'TV:PBW', id: "24" },
    // { name: 'tvBW', label: 'TV:BW', id: "25" },
    { name: 'cState', label: 'Cstat', id: "26" },
    { name: 'rStatic', label: 'Rstat', id: "27" },
    { name: 'cdyn', label: 'Cdyn', id: "28" },
    { name: 'paraCpap', label: 'Cpap', id: "31" },
    { name: 'vts', label: 'VT sp', id: "33" },
    { name: 'pDrive', label: 'Pdrive', id: "11" },
    { name: 'rrSpont', label: 'RR sp', id: "12" },
];

export const patientGenderList = [
    { name: 'M', label: 'Male', id: 1 },
    { name: 'F', label: 'Female', id: 2 },
    { name: 'Other', label: 'Other', id: 3 }
]

export const BLOOD_GROUP_OPTION = [
    { name: "A+", label: "A+", id: 1 },
    { name: "A-", label: "A-", id: 2 },
    { name: "B+", label: "B+", id: 3 },
    { name: "B-", label: "B-", id: 4 },
    { name: "AB+", label: "AB+", id: 5 },
    { name: "AB-", label: "AB-", id: 6 },
    { name: "O+", label: "O+", id: 7 },
    { name: "O-", label: "O-", id: 8 }
]

export const REPORT_TYPE = {
    ECG_REPORT: 'ECG_REPORT',
    VITAL_CMS_REPORT: 'VITAL_CMS_REPORT'
}
export const GENDER = {
    MALE: 'M',
    FEMALE: 'F',
    OTHER: 'Other',
}

export const durationOptionList = [
    { name: '15', label: '15M' },
    { name: '30', label: '30M' },
    { name: '60', label: '1H' },
    { name: '120', label: '2H' },
    { name: '240', label: '4H' },
    { name: '480', label: '8H' }
];


export const EWS_SCORE = {
    NO_RISK: {
        LABEL: "NO RISK",
        VALUE: "no_risk",
    },
    MILD_RISK: {
        LABEL: "MILD RISK",
        VALUE: "mild_risk",
    },
    CRITICAL: {
        LABEL: "CRITICAL",
        VALUE: "critical",
    }
}

export const MODAL_ITEMS = {
    CHARTING: 'Charting',
    NURSE_NOTES: 'Nurse Notes',
    DOCTOR_NOTES: 'Doctor Notes',
    PRESCRIPTION: 'Prescription',
    ALARM_HISTORY: 'Alarm History'
}

export const CHART_OPTIONS = {
    VITALS: 'Vitals',
    RS: "RS",
    CVS: 'CVS',
    CNS: 'CNS',
    NURSING_CHECKS: 'Nursing Checks',
    I_O_BAL: 'I/O Bal',
    DRUG_ADMINISTRATION: "Drug Administration",
    LAB_REPORTS: "Lab Reports",
    CLINICAL_SCORES: "Clinical Scores",
    BUNDLES: "Bundles"
}

export const CHART_TABLE_OPTIONS = [
    CHART_OPTIONS.VITALS,
    CHART_OPTIONS.RS,
    CHART_OPTIONS.CVS,
    CHART_OPTIONS.CNS,
    CHART_OPTIONS.NURSING_CHECKS,
];

export const NOTES_TYPE = {
    DOCTOR: "DOCTOR",
    NURSE: "NURSE"
}

export const PRESCRIPTION_OPTIONS = {

    DRUGS: "Drugs",
    INFUSION: "Infusion",
    STAT_ORDERS: "Stat Orders"
}

export const BED_DETAILS_MODULE = {
    PATIENT_DETAILS: "Patient Details",
    MONITOR: "Monitor",
    VENTILATOR: "Ventilator",
    ALARMS: "Alarm History",
    TRENDS_REPORTS: "Trends & Reports",
    NURSE_NOTES: "Nurse Notes",
    DOCTOR_NOTES: "Doctor Notes",
    CHARTING: "Charting",
    REPORT: "Report",
    PRESCRIPTION: "Prescription",
    VITAL_DETAILS: "Vital Details"
}

export const TRIGGER = {
    RIGHT: "RIGHT",
    LEFT: "LEFT"
}

export const DEVICE_TYPES = {
    "VENTILATOR": "VENTILATOR",
    "MONITOR": "MONITOR"
}

export const VENTILATOR_PARAM_LIST = [
    {
        name: 'pressure',
        html: {
            name: 'P',
            nameSuccessor: '',
            nameSub: 'insp',
            sub: 'cmH',
            subSuccessor: 'O',
            subSub: '2'
        }
    },
    {
        name: 'peep',
        html: {
            name: 'PEEP',
            nameSuccessor: '',
            nameSub: '',
            sub: 'cmH',
            subSuccessor: 'O',
            subSub: '2'
        }
    },
    {
        name: 'pPlateau',
        html: {
            name: 'P',
            nameSuccessor: '',
            nameSub: 'plat.',
            sub: 'cmH',
            subSuccessor: 'O',
            subSub: '2'
        }
    },
    {
        name: 'vti',
        html: {
            name: 'V',
            nameSuccessor: '',
            nameSub: 'ti',
            sub: 'ml',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'vte',
        html: {
            name: 'V',
            nameSuccessor: '',
            nameSub: 'te',
            sub: 'ml',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'fio2',
        html: {
            name: 'FiO',
            nameSuccessor: '',
            nameSub: '2',
            sub: '%',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'rr',
        html: {
            name: 'RR',
            nameSuccessor: '',
            nameSub: 'bpm',
            sub: '%',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'ie',
        html: {
            name: 'I:E',
            nameSuccessor: '',
            nameSub: '',
            sub: '-',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'mve',
        html: {
            name: 'MV',
            nameSuccessor: '',
            nameSub: 'e',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'mvi',
        html: {
            name: 'MV',
            nameSuccessor: '',
            nameSub: 'i',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'spontmve',
        html: {
            name: 'Spont. MV',
            nameSuccessor: '',
            nameSub: 'e',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'leakvol',
        html: {
            name: 'Leak Vol',
            nameSuccessor: '',
            nameSub: '',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'rsbi',
        html: {
            name: 'RSBI',
            nameSuccessor: '',
            nameSub: '',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'cstat',
        html: {
            name: 'C',
            nameSuccessor: '',
            nameSub: 'stat',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'cdyn',
        html: {
            name: 'C',
            nameSuccessor: '',
            nameSub: 'dyn',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'ti',
        html: {
            name: 'T',
            nameSuccessor: '',
            nameSub: 'i',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'te',
        html: {
            name: 'T',
            nameSuccessor: '',
            nameSub: 'e',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    },
    {
        name: 'pmean',
        html: {
            name: 'P',
            nameSuccessor: '',
            nameSub: 'mean',
            sub: '',
            subSuccessor: '',
            subSub: ''
        }
    }
];

export const CHART_HEADER_CONST = {
    PARAMETERS: "PARAMETERS",
    MORNING: "MORNING",
    EVENING: "EVENING",
    NIGHT: "NIGHT",
    TYPE: "TYPE",
    REMARKS: "REMARKS"
}


export const IO_DATA = {
    IO_COLUMNS: {
        OUTPUT_TOTAL: "outputTotal",
        DRAIN: "DRAIN",
        URINE: "URINE",
        IV_FLUID: "IV_FLUID",
        INFUSION: "INFUSION",
        ORAL_RT: "ORAL_RT",
        OTHERS: "OTHER",
        INTAKE_TOTAL: "intakeTotal",
        INTAKE: "INTAKE",
        OUTPUT: "OUTPUT",
        TI_TO: "TI_TO",
        BALANCE: "BALANCE",
        TIME: "TIME"
    },
    IO_ROWS: {
        NAME: "NAME",
        TOTAL: "TOTAL",
        TIME: "TIME"
    }
}

export const getModeFromIOColumn = (columnName) => {

    switch (columnName) {
        case IO_DATA.IO_COLUMNS.OUTPUT_TOTAL:
        case IO_DATA.IO_COLUMNS.DRAIN:
        case IO_DATA.IO_COLUMNS.URINE:
            return IO_DATA.IO_COLUMNS.OUTPUT;
        case IO_DATA.IO_COLUMNS.IV_FLUID:
        case IO_DATA.IO_COLUMNS.INFUSION:
        case IO_DATA.IO_COLUMNS.ORAL_RT:
        case IO_DATA.IO_COLUMNS.OTHERS:
        case IO_DATA.IO_COLUMNS.INTAKE_TOTAL:
            return IO_DATA.IO_COLUMNS.INTAKE;
        case IO_DATA.IO_COLUMNS.TI_TO:
            return IO_DATA.IO_COLUMNS.BALANCE;
    }
}

export const getChartHeader = (type) => {

    switch (type) {
        case CHART_OPTIONS.VITALS:
            return [CHART_HEADER_CONST.PARAMETERS, "08:00", "09:00", "10:00", "11:00", "12:00",
                "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00",
                "22:00", "23:00", "24:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00"];
        case CHART_OPTIONS.RS:
            return [CHART_HEADER_CONST.PARAMETERS, "08:00", "09:00", "10:00", "11:00", "12:00",
                "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00",
                "22:00", "23:00", "24:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00"];
        case CHART_OPTIONS.CVS:
            return [CHART_HEADER_CONST.PARAMETERS, "08:00", "09:00", "10:00", "11:00", "12:00",
                "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00",
                "22:00", "23:00", "24:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00"];
        case CHART_OPTIONS.CNS:
            return [CHART_HEADER_CONST.PARAMETERS, "08:00", "09:00", "10:00", "11:00", "12:00",
                "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00",
                "20:00", "21:00", "22:00", "23:00", "24:00"];
        case CHART_OPTIONS.NURSING_CHECKS:
            return [CHART_HEADER_CONST.PARAMETERS, CHART_HEADER_CONST.MORNING, CHART_HEADER_CONST.EVENING, CHART_HEADER_CONST.NIGHT];
        case CHART_OPTIONS.DRUG_ADMINISTRATION:
            return [CHART_HEADER_CONST.PARAMETERS, CHART_HEADER_CONST.TYPE, CHART_HEADER_CONST.REMARKS,
                "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00",
                "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00", "01:00",
                "02:00", "03:00", "04:00", "05:00", "06:00", "07:00"];
        case CHART_OPTIONS.CLINICAL_SCORES:
            return [CHART_HEADER_CONST.PARAMETERS, "08:00", "09:00", "10:00", "11:00", "12:00",
                "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00",
                "22:00", "23:00", "24:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00"];

        case CHART_OPTIONS.I_O_BAL:
            return [
                [
                    {
                        id: "1",
                        label: "TIME",
                        name: IO_DATA.IO_COLUMNS.TIME,
                        className: "time sticky-left border-right",
                        rowSpan: "2",
                        colSpan: "1",
                        isAddIconVisible: false
                    }, {
                        id: "2",
                        label: "INTAKE",
                        name: IO_DATA.IO_COLUMNS.INTAKE,
                        className: "intake",
                        rowSpan: "1",
                        colSpan: "5",
                        isAddIconVisible: false,
                    }, {
                        id: "3",
                        label: "OUTPUT",
                        name: IO_DATA.IO_COLUMNS.OUTPUT,
                        className: "output",
                        rowSpan: "1",
                        colSpan: "3",
                        isAddIconVisible: false,
                    }, {
                        id: "4",
                        label: "BALANCE",
                        name: IO_DATA.IO_COLUMNS.BALANCE,
                        className: "balance sticky-right border-left",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: false,
                    }
                ],
                [
                    {
                        id: "5",
                        label: "IV FLUIDS",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.IV_FLUID),
                        name: IO_DATA.IO_COLUMNS.IV_FLUID,
                        className: "iv_fluids",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "6",
                        label: "INFUSIONS",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.INFUSION),
                        name: IO_DATA.IO_COLUMNS.INFUSION,
                        className: "infusions",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "7",
                        label: "ORAL/RT",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.ORAL_RT),
                        name: IO_DATA.IO_COLUMNS.ORAL_RT,
                        className: "oral_rt",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "8",
                        label: "OTHERS",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.OTHERS),
                        name: IO_DATA.IO_COLUMNS.OTHERS,
                        className: "others",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "9",
                        label: "TOTAL",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.INTAKE_TOTAL),
                        name: IO_DATA.IO_COLUMNS.INTAKE_TOTAL,
                        className: "total",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "10",
                        label: "URINE",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.URINE),
                        name: IO_DATA.IO_COLUMNS.URINE,
                        className: "urine",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "11",
                        label: "DRAIN",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.DRAIN),
                        name: IO_DATA.IO_COLUMNS.DRAIN,
                        className: "drain",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "12",
                        label: "TOTAL",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.OUTPUT_TOTAL),
                        name: IO_DATA.IO_COLUMNS.OUTPUT_TOTAL,
                        className: "total",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: true,
                    }, {
                        id: "13",
                        label: "Total Input - Total Output",
                        mode: getModeFromIOColumn(IO_DATA.IO_COLUMNS.TI_TO),
                        name: IO_DATA.IO_COLUMNS.TI_TO,
                        className: "ti-to sticky-right border-left",
                        rowSpan: "1",
                        colSpan: "1",
                        isAddIconVisible: false,
                    }
                ]
            ];

        default:
            return [];
    }
}

export const SIDEBAR_ICON = {
    DEVICE_MAPPER: "DEVICE_MAPPER",
    HOME_PAGE: "HOME_PAGE",
}

export const getAlarmHeader = (device) => {
    if (device === DEVICE_TYPES.MONITOR)
        return ["PRIORITY", "ALARM", "VALUE", "DATE", "TIME"];
    else if (device === DEVICE_TYPES.VENTILATOR)
        return ["TYPE", "PRIORITY", "MESSAGE", "DATE", "TIME"];
}

export const getParameterList = (type) => {

    switch (type) {
        case CHART_OPTIONS.VITALS:
            return [
                {
                    "label": "HR",
                    "name": "hr",
                    "id": "1"
                }, {
                    "label": "SPO2",
                    "name": "spo2",
                    "id": "2"
                }, {
                    "label": "Temp",
                    "name": "temp",
                    "id": "3"
                }, {
                    "label": "RR",
                    "name": "rr",
                    "id": "4"
                }, {
                    "label": "BP (S)",
                    "name": "bpS",
                    "id": "5"
                }, {
                    "label": "BP (D)",
                    "name": "bpD",
                    "id": "6"
                }, {
                    "label": "BP (Mean)",
                    "name": "bpMean",
                    "id": "7"
                }];
        case CHART_OPTIONS.RS:
            return [
                {
                    "label": "Mode",
                    "name": "mode",
                    "id": "1"
                }, {
                    "label": "FIO2",
                    "name": "fio2",
                    "id": "2"
                }, {
                    "label": "Set Tv",
                    "name": "setTv",
                    "id": "3"
                }, {
                    "label": "Pinsp",
                    "name": "pip",
                    "id": "4"
                }, {
                    "label": "PEEP",
                    "name": "peep",
                    "id": "5"
                }, {
                    "label": "Set Mv",
                    "name": "setMv",
                    "id": "6"
                }, {
                    "label": "VTi",
                    "name": "vti",
                    "id": "7"
                }, {
                    "label": "VTe",
                    "name": "vte",
                    "id": "8"
                }, {
                    "label": "PS",
                    "name": "ps",
                    "id": "9"
                }, {
                    "label": "P Mean",
                    "name": "pMean",
                    "id": "10"
                }, {
                    "label": "I:E",
                    "name": "ie",
                    "id": "11"
                }, {
                    "label": "Trigger",
                    "name": "trigger",
                    "id": "12"
                }, {
                    "label": "MVe",
                    "name": "mve",
                    "id": "13"
                }, {
                    "label": "Cuff Pressure",
                    "name": "cuffPressure",
                    "id": "14"
                }];
        case CHART_OPTIONS.CVS:
            return [
                {
                    "label": "RHYTHM",
                    "name": "rhythm",
                    "id": "1"
                }, {
                    "label": "MAP",
                    "name": "map",
                    "id": "2"
                }, {
                    "label": "CVP",
                    "name": "cvp",
                    "id": "3"
                }];
        case CHART_OPTIONS.CNS:
            return [
                {
                    "label": "Eye Opening Response (A)",
                    "name": "eyeOpeningResponse",
                    "id": "1"
                }, {
                    "label": "Verbal (B)",
                    "name": "verbal",
                    "id": "2"
                }, {
                    "label": "Motor (C)",
                    "name": "motor",
                    "id": "3"
                }, {
                    "label": "GCs (A+B+C)",
                    "name": "gcs",
                    "id": "4"
                }, {
                    "label": "Pupil R - Size",
                    "name": "pupilRSize",
                    "id": "5"
                }, {
                    "label": "Pupil L - Size",
                    "name": "pupilLSize",
                    "id": "6"
                }, {
                    "label": "Pupil L - Reaction",
                    "name": "pupilLReaction",
                    "id": "7"
                }, {
                    "label": "Pupil R - Reaction",
                    "name": "pupilRReaction",
                    "id": "8"
                }, {
                    "label": "Sedation Score",
                    "name": "sedationScore",
                    "id": "9"
                }, {
                    "label": "Pain Score",
                    "name": "painScore",
                    "id": "10"
                }];
        case CHART_OPTIONS.NURSING_CHECKS:
            return [
                {
                    "label": "User",
                    "name": "user",
                    "type": "text",
                    "id": "1"
                }, {
                    "label": "GlucoCheck",
                    "name": "glucoCheck",
                    "type": "checkbox",
                    "id": "2"
                }, {
                    "label": "Urine - Sugar",
                    "name": "urineSugar",
                    "type": "checkbox",
                    "id": "3"
                }, {
                    "label": "Urine - Ketone",
                    "name": "urineKetone",
                    "type": "double-checkbox",
                    "id": "4"
                }, {
                    "label": "Pressure Sore / Bed Sore",
                    "name": "pressureSore",
                    "type": "checkbox",
                    "id": "5"
                }, {
                    "label": "Nebulization",
                    "name": "nebulization",
                    "type": "checkbox",
                    "id": "6"
                }, {
                    "label": "Positioning",
                    "name": "positioning",
                    "type": "checkbox",
                    "id": "7"
                }, {
                    "label": "CVC Care",
                    "name": "cvcCare",
                    "type": "checkbox",
                    "id": "8"
                }, {
                    "label": "Chest Physiotherapy",
                    "name": "chestPhysiotherapy",
                    "type": "checkbox",
                    "id": "9"
                }];
        case CHART_OPTIONS.I_O_BAL:
            return [{
                "label": IO_DATA.IO_ROWS.NAME,
                "id": "1",
            }, {
                "label": IO_DATA.IO_ROWS.TOTAL,
                "id": "2",
            }, {
                "label": "08:00",
                "id": "3",
            }, {
                "label": "09:00",
                "id": "4",
            }, {
                "label": "10:00",
                "id": "5",
            }, {
                "label": "11:00",
                "id": "6",
            }, {
                "label": "12:00",
                "id": "7",
            }, {
                "label": "13:00",
                "id": "8",
            }, {
                "label": "14:00",
                "id": "9",
            }, {
                "label": "15:00",
                "id": "10",
            }, {
                "label": "16:00",
                "id": "11",
            }, {
                "label": "17:00",
                "id": "12",
            }, {
                "label": "18:00",
                "id": "13",
            }, {
                "label": "19:00",
                "id": "14",
            }, {
                "label": "20:00",
                "id": "15",
            }, {
                "label": "21:00",
                "id": "16",
            }, {
                "label": "22:00",
                "id": "17",
            }, {
                "label": "23:00",
                "id": "18",
            }, {
                "label": "24:00",
                "id": "19",
            }, {
                "label": "01:00",
                "id": "20",
            }, {
                "label": "02:00",
                "id": "21",
            }, {
                "label": "03:00",
                "id": "22",
            }, {
                "label": "04:00",
                "id": "23",
            }, {
                "label": "05:00",
                "id": "24",
            }, {
                "label": "06:00",
                "id": "25",
            }, {
                "label": "07:00",
                "id": "26",
            }];
        case CHART_OPTIONS.CLINICAL_SCORES:
            return [
                {
                    "label": "SOFA",
                    "name": "SOFA",
                    "id": "1"
                },
                // {
                //     "label": "RISK FALL",
                //     "name": "Risk Fall",
                //     "id": "2"
                // },
                {
                    "label": "GCS",
                    "name": "GCS",
                    "id": "2"
                }];
        default:
            return [];
    }
}
export const CLINICAL_SCORE = () => {
    return
}

export const ALARM_PRIORITY = {
    HIGH_PRIORITY: "high-priority",
    MEDIUM_PRIORITY: "medium-priority",
    LOW_PRIORITY: "low-priority"
}

export const ALARM_STATUS = {
    INACTIVE: "inactive",
    ACTIVE: "active"
}

export const MORE_GRAPH_OPTIONS = {
    MONITOR: [{
        name: "ECG1",
        label: "I",
        type: GRAPH_TYPE.ECG,
        min: 1500,
        max: 2100
    }, {
        name: "ECG2",
        label: "II",
        type: GRAPH_TYPE.ECG,
        min: 1500,
        max: 2100
    }, {
        name: "ECG3",
        label: "III",
        type: GRAPH_TYPE.ECG,
        min: 1500,
        max: 2100
    }, {
        name: "ECGAVF",
        label: "aVF",
        type: GRAPH_TYPE.ECG,
        min: 1500,
        max: 2100
    }, {
        name: "ECGAVL",
        label: "aVL",
        type: GRAPH_TYPE.ECG,
        min: 1500,
        max: 2100
    }, {
        name: "ECGAVR",
        label: "aVR",
        type: GRAPH_TYPE.ECG,
        min: 1800,
        max: 2500
    }, {
        name: "ECG5",
        label: "V",
        type: GRAPH_TYPE.ECG,
        min: 1500,
        max: 2100
    }, {
        name: "RESP",
        label: "Resp",
        type: GRAPH_TYPE.RESP,
        // min: 0,
        // max: 100
    }, {
        name: "PLETH",
        label: "Pleth",
        type: GRAPH_TYPE.PLETH,
        // min: 0,
        // max: 100
    }]
}

export const BED_CARD_VITALS = {
    MONITOR: [{
        "name": "RR",
        "label": "RR"
    },
    {
        "name": "HR",
        "label": "HR"
    },
    {
        "name": "SPO2",
        "label": "SPO2"
    }, {
        "name": "TEMPERATURE1",
        "backup": "TEMPERATURE2",
        "label": "TEMP"
    }, {
        "name": "BP",
        "label": "NIBP"
    }],
    VENTILATOR: [
        {
            "id": 104001,
            "name": "FIO2",
            "label": "FIO2",
            "unit": "%"
        },
        {
            "id": 104007,
            "name": "PEEP",
            "label": "PEEP",
            "unit": "cmH2O"
        },
        {
            "id": 104002,
            "name": "RR",
            "label": "RR",
            "unit": "b/min"
        },
        {
            "id": 104006,
            "name": "PIP",
            "label": "PiP",
            "unit": "cmH2O"
        },
        {
            "id": 104009,
            "name": "VTE",
            "label": "VTe",
            "unit": "ml"
        }]
}

