import axios from "axios";
import "../common/axiosInterceptor/axios-interceptor";
// const BASE_URL = "http://localhost:8080/cms/api/hospitals/";
// const BASE_URL_SIGN_IN = 'http://localhost:8080/cms/api/';
// const BASE_URL_SIGN_IN = 'https://backend-cms.noccarc-web.com/cms/api/';
// const BASE_URL = "https://backend-cms.noccarc-web.com/cms/api/hospitals/";
const BASE_URL_SIGN_IN = `${process.env.REACT_APP_BASE_URL}/cms/api/`;
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/cms/api/hospitals/`;


export const loginAPI = async (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL_SIGN_IN}users/signin`,
        data: data
    })
}

export const loginTrackAPI = async (data) => {
    return axios({
        url: `${BASE_URL}cmsUsageTracking`,
        data: data,
        authRequired: true
    })
}

export const generateEcgChartAPI = async (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}generateEcgReport`,
        data: data,
        authRequired: true
    })
}

export const generateVitalsPdfAPI = async (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}generateReportVitalPdf`,
        data: data,
        authRequired: true
    })
}

export const getHospitalDetailsAPI = (data) => {

    return axios({
        method: "get",
        url: `${BASE_URL}details`,
        data: data,
        authRequired: true
    })
}

export const getNotesAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}getNotes/`,
        data: data,
        authRequired: true
    })
}

export const postNotesAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}notes`,
        data: data,
        authRequired: true
    })
}

export const getChartVitalsAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getVitals`,
        data: data,
        authRequired: true
    })
}

export const getChartRSAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getRS`,
        data: data,
        authRequired: true
    })
}

export const getChartCVSAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getCVS`,
        data: data,
        authRequired: true
    })
}

export const getChartCNSAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getCNS`,
        data: data,
        authRequired: true
    })
}

export const getChartNursingCheckAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getNurseChecks`,
        data: data,
        authRequired: true
    })
}

export const getChartDrugAdministration = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getDrugAdministrations`,
        data: data,
        authRequired: true
    })
}

export const updateChartVitalsAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateVitals`,
        data: data,
        authRequired: true
    })
}

export const updateChartRSAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateRS`,
        data: data,
        authRequired: true
    })
}

export const updateChartCVSAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateCVS`,
        data: data,
        authRequired: true
    })
}

export const updateChartCNSAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateCNS`,
        data: data,
        authRequired: true
    })
}

export const updateChartNurseCheckAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateNurseChecks`,
        data: data,
        authRequired: true
    })
}

export const updateChartDrugAdministration = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateDrugAdministrations`,
        data: data,
        authRequired: true
    })
}

export const addDrugAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}prescription/addDrug`,
        data: data,
        authRequired: true
    })
}

export const getDrugsAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}prescription/getDrugs`,
        data: data,
        authRequired: true
    })
}

export const updateDrugAPI = (data) => {
    return axios({
        method: "put",
        url: `${BASE_URL}prescription/updateDrug`,
        data: data,
        authRequired: true
    })
}

export const addStatOrderAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}prescription/addStatOrder`,
        data: data,
        authRequired: true
    })
}

export const getStatOrdersAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}prescription/getStatOrders`,
        data: data,
        authRequired: true
    })
}

export const updateStatOrderAPI = (data) => {
    return axios({
        method: "put",
        url: `${BASE_URL}prescription/updateStatOrder`,
        data: data,
        authRequired: true
    })
}

export const addInfusionAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}prescription/addInfusion`,
        data: data,
        authRequired: true
    })
}

export const getInfusionsAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}prescription/getInfusions`,
        data: data,
        authRequired: true
    })
}

export const updateInfusionAPI = (data) => {
    return axios({
        method: "put",
        url: `${BASE_URL}prescription/updateInfusion`,
        data: data,
        authRequired: true
    })
}

export const getChartTimelineAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}charting/getTimeline`,
        data: data,
        authRequired: true
    })
}

export const getAlarmHistoryAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}alarmHistory/getAlarms`,
        data: data,
        authRequired: true
    })
}

export const getIOBalanceAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getIOBalance`,
        data: data,
        authRequired: true
    })
}

export const updateIOBalanceAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateIOBalance`,
        data: data,
        authRequired: true
    })
}
export const updateClinicalScoreAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/updateClinicalScores`,
        data: data,
        authRequired: true
    })
}

export const getClinicalScoreAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}charting/getClinicalScores`,
        data: data,
        authRequired: true
    })
}

export const admitPatientAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}admitPatient`,
        data: data,
        authRequired: true
    })
}

export const dischargePatientAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}dischargePatient`,
        data: data,
        authRequired: true
    })
}

export const getTrendDataAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}vitalTrends`,
        data: data,
        authRequired: true
    })
}


export const getVentilatorTrendDataAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}ventilatorVitalTrends`,
        data: data,
        authRequired: true
    })
}

export const getVentilatorAlarmHistoryAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}alarmHistory/getVentilatorEventLogHistory`,
        data: data,
        authRequired: true
    })
}


export const getVentilatorModeParamsAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}getVentilatorModeParameters`,
        data: data,
        authRequired: true
    })
}
