import React, { useState, useEffect } from 'react';

import { Header } from "../../components/Header/Header";
import SideBar from "../../components/SideBar/Sidebar";
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitchNew';

// import {
//     bedDetailAction,
//     bedIdAction,
//     patientDataAction,
//     deviceVitalDataAction,
//     alarmDataAction,
//     notificationDataAction,
//     filterAction,
//     viewTypeAction,
//     wardDetailsAction,
//     addColumnBedListAction,
//     removeColumnBedListAction,
//     hospitalDataAction,
//     bedDataAction,
// } from '../../modules/actions/action';
import "./PatientBook.scss";
import { connect } from 'react-redux';
import { VIEW_TYPES } from '../../common/constant/common-constants';
import BedContainer from '../../container/BedContainer/BedContainer';
import { getHospitalDetails } from '../../helper/home-page-helper';
import { useNavigate } from 'react-router-dom';
// import { getPatientBook } from '../../helper/patient-book-helper';


function PatientBook(props) {

    const [toggle, setToggle] = useState(props.config.viewType === VIEW_TYPES.GRID_VIEW ? false : true);
    const [viewType, setViewType] = useState(props.config.viewType);
    const [modalConfig, setModalConfig] = useState(null);
    const [selectedWard, setSelectedWard] = useState(null);
    const [wardOption, setWardOption] = useState();
    const [hospitalName, setHospitalName] = useState("");

    const navigate = useNavigate();

    /**
         * 
         * Need to set all actions for data processing before opening the socket connection
         */
    // useEffect(() => {

    //     // setActionForSocket();
    //     openSocketConnection();
    //     return () => {
    //         closeSocketConnection();
    //     }

    // }, []);

    // useEffect(() => {
    //     let wardOption = [];
    //     let selectedWard = {};
    //     let bedIdArray = [];
    //     let tempWard = null;

    //     // console.log(" use effect called !!");
    //     getPatientBook().then((res) => {

    //         if (!res || !res.data && !res.data.wards) return;
    //         setHospitalName(res.data.hospitalName);
    //         props.hospitalDataAction(res.data);
    //         /**
    //          * 
    //          * 
    //          * create bed card, add patient Data, 
    //          * 
    //          * create entry in ward
    //          *      - create ward
    //          *      - set ward to bed mapping
    //          * create patient details
    //          *  - create bed- patient mapping
    //          */
    //         let beds = res.data.wards.map((ward) => {
    //             // return [...ward.beds];
    //             // console.log("res in ward data", ward);
    //             /** patient Details */

    //             let bedIdList = ward.patientBook.map((patient) => {

    //                 let newBedId = (patient.bedId + '-' + patient.patientSessionId)
    //                 let data = {
    //                     bedId: newBedId,
    //                     data: {
    //                         "patientId": patient.patientId,
    //                         "name": patient.patientName,
    //                         "age": patient.age,
    //                         "bloodGroup": patient.bloodGroup,
    //                         "gender": patient.gender,
    //                         "uhid": patient.uhid,
    //                         "height": patient.height,
    //                         "weight": patient.weight,
    //                         "sessionId": patient.patientSessionId,
    //                         "bedId": newBedId,
    //                         "admissionDate": patient.admissionDate,
    //                         "admissionTime": patient.admissionTime,
    //                         "dischargeDate": patient.dischargeDate,
    //                         "dischargeTime": patient.dischargeTime,
    //                         "diagnosis": patient.diagnosis,
    //                         "physician": patient.physician,
    //                         "status": patient.status,
    //                         "remark": patient.remark,
    //                         "daysAdmitted": patient.daysAdmitted
    //                     }
    //                 }

    //                 let bedData = {
    //                     bedId: newBedId,
    //                     bedName: patient.bedName,
    //                     bedIdRaw: patient.bedId,
    //                 }
    //                 props.bedDetailAction(bedData);
    //                 props.bedIdAction({ bedId: newBedId });
    //                 props.patientDataAction(data);

    //                 return newBedId;
    //             })

    //             /** Ward Details */
    //             let wardData = {
    //                 id: ward.id,
    //                 data: {
    //                     id: ward.id,
    //                     wardName: ward.name,
    //                     bedList: bedIdList
    //                 }
    //             }
    //             props.wardDetailsAction(wardData);

    //             /** for dropdown */
    //             let wardObj = {
    //                 id: ward.id,
    //                 name: ward.id,
    //                 label: ward.name
    //             };

    //             if (!tempWard) {
    //                 tempWard = wardObj;
    //                 if (!selectedWard.id) {
    //                     setSelectedWard(tempWard);
    //                 }
    //             }

    //             wardOption.push(wardObj);
    //         });
    //         setWardOption(wardOption);
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // }, []);

    // useEffect(() => {

    //     let mConfig = props.config.modalConfig;
    //     setModalConfig(mConfig);
    //     props.wardDetailsAction({ selectedWard: selectedWard })
    // }, [props.config, selectedWard])

    return (
        <div>this is psuedo patient book</div>
        //     <>
        //         <div className={modalConfig && modalConfig.isOpen ? 'patient-book-page blur' : 'patient-book-page'}>
        //             <Header
        //                 handleWardChange={(item) => setSelectedWard(item)}
        //                 wardOption={wardOption}
        //                 selectedWard={selectedWard}
        //                 hospitalName={hospitalName} />

        //             <SideBar />
        //             <div className='patient-book-body-container'>
        //                 <span className="patient-book-header">Patient Book</span>
        //                 {selectedWard && <BedContainer selectedWard={selectedWard} />}
        //             </div>
        //         </div>
        //     </>
    );
}

const mapStateToProps = state => {
    return {
        // bedDetails: state.bedDetailsReducer.bedDetails,
        bedData: state.bedDetailsReducer.bedData,
        wardDetails: state.wardDetailsReducer.wardDetails,
        config: state.configReducer.config,
        hospitalData: state.hospitalDataReducer.hospitalData
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         hospitalDataAction: (payload) => dispatch(hospitalDataAction(payload)),
//         bedDetailAction: (payload) => dispatch(bedDetailAction(payload)),
//         bedIdAction: (payload) => dispatch(bedIdAction(payload)),
//         wardDetailsAction: (payload) => dispatch(wardDetailsAction(payload)),
//         patientDataAction: (payload) => dispatch(patientDataAction(payload)),
//         deviceVitalDataAction: (payload) => dispatch(deviceVitalDataAction(payload)),
//         alarmDataAction: (payload) => dispatch(alarmDataAction(payload)),
//         notificationDataAction: (payload) => dispatch(notificationDataAction(payload)),
//         viewTypeAction: (payload) => dispatch(viewTypeAction(payload)),
//         filterAction: (payload) => dispatch(filterAction(payload)),
//         addColumnBedListAction: (payload) => dispatch(addColumnBedListAction(payload)),
//         removeColumnBedListAction: (payload) => dispatch(removeColumnBedListAction(payload)),
//         bedDataAction: (payload) => dispatch(bedDataAction(payload)),
//     }
// };

export default connect(mapStateToProps, null)(PatientBook);