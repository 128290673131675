import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import logo_svg from "../../common/icons/svg/logo.svg";
import workflow_illustration_svg from "../../common/icons/svg/workflow-illustration.svg";
import hippa_svg from "../../common/icons/svg/hippa.svg";
import connected_devices_svg from "../../common/icons/svg/connected-device.svg";
import accessible_svg from "../../common/icons/svg/accessible.svg";
import "./Login.scss";
import { login, loginTrack } from '../../helper/login-helper';
import { redirect } from '../../common/utils/RedirectionUtils';
import { LOGIN_CONST, PAGE_CONST } from '../../common/constant/page-constants';

import { clearAlarms, clearStore, hospitalDataAction, sidebarActiveIconAction } from '../../modules/actions/action';
import { setLocalStorageCustom } from '../../common/utils/StorageUItils';
import { checkType } from '../../common/constant/common-constants';
import moment from 'moment';


function Login(props) {
    const CONTENT_LIST = [{
        heading: "Workflow Automation",
        body: `1. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Quam mi tortor cursus
            tincidunt purus tortor cursus tincidunt purus.`,
        src: workflow_illustration_svg
    }, {
        heading: "HIPAA Compliant-Data Security and Privacy",
        body: `2. Lorem ipsum dolor sit amet, \
        consectetur adipiscing elit. Quam mi tortor cursus \
        tincidunt purus tortor cursus tincidunt purus.`,
        src: hippa_svg
    }, {
        heading: "Connected Medical Devices",
        body: `3. Lorem ipsum dolor sit amet, \
        consectetur adipiscing elit. Quam mi tortor cursus \
        tincidunt purus tortor cursus tincidunt purus.`,
        src: connected_devices_svg
    }, {
        heading: "Accessible from App and Web",
        body: `4. Lorem ipsum dolor sit amet, \
        consectetur adipiscing elit. Quam mi tortor cursus \
        tincidunt purus tortor cursus tincidunt purus.`,
        src: accessible_svg
    }];

    const navigate = useNavigate();

    const [selectedContentIndex, setSelectedContentIndex] = useState(0);
    const [contentLength] = useState(CONTENT_LIST.length);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleNavigation = (type, count) => {

        let sci = selectedContentIndex;
        if (type === LOGIN_CONST.LEFT_ARROW) {
            if (sci === 0) setSelectedContentIndex(contentLength - 1);
            else setSelectedContentIndex(sci - 1);
        } else if (type === LOGIN_CONST.RIGHT_ARROW) {
            if (sci === (contentLength - 1)) setSelectedContentIndex(0);
            else setSelectedContentIndex(sci + 1);
        } else if (type === LOGIN_CONST.RADIO_BUTTON) {
            setSelectedContentIndex(count);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            let sci = selectedContentIndex;
            if (sci === contentLength - 1) setSelectedContentIndex(0);
            else setSelectedContentIndex(sci + 1);
        }, 2000);
        return () => clearTimeout(timer);
    }, [selectedContentIndex]);

    const handleSubmit = () => {

        setErrorMsg(null);

        if(!email){
            setErrorMsg("Email cannot be empty.");
            return;
        }

        if(!password){
            setErrorMsg("Password cannot be empty.");
            return;
        }

        let data = {
            email: email,
            password: password
        }

        login(data).then((res) => {

            console.log("rest>>>> ", res);
            
            if (res && res.message) {
                
                setErrorMsg(res.message);
                return;
            }
            props.hospitalDataAction(res);
            props.clearStore();
            setLocalStorageCustom('hospitalId', res.hospitalId);
            
            let trackingData = {
                type: checkType.LOGIN,
                hospitalId: props.hospitalData.hospitalId,
                loginDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                username: props.hospitalData.username
            }
            loginTrack(trackingData).then((trackRes) => {
                console.log("sucess!!!", trackRes);
                props.sidebarActiveIconAction(PAGE_CONST.HOME_PAGE);
                redirect(navigate, PAGE_CONST.HOME_PAGE);
            }).catch((err) => {
                console.log("err in tracking API", err);
                props.sidebarActiveIconAction(PAGE_CONST.HOME_PAGE);
                redirect(navigate, PAGE_CONST.HOME_PAGE);
            })

        }).catch((err) => {
            setErrorMsg("Error Occurred !!!");
            
        })
    }

    const handleChange = (type, event) => {

        let value = (event.target && event.target.value) || '';
        
        setErrorMsg(null);
        switch (type) {

            case LOGIN_CONST.EMAIL:
                setEmail(value);
                
                break;
            case LOGIN_CONST.PASSWORD:
                setPassword(value);
                
                break;
            default:
                break;
        }
    }

    return (
        <div 
            className='login-container'
            onKeyDown={(event) => {
                if(event.key === 'Enter'){
                    handleSubmit()
                }
              }
            }
        >
            <div className='left-panel' />
            <div className='right-panel'>
                <div className='content-container'>
                    <div className='logo-container'>
                        <img className='login-logo' src={logo_svg} alt="logo" />
                        <h1 className='sign-in-text-container'>
                            HorizonView
                        </h1>
                    </div>
                    <h2 className='sub-text'>Effortlessly oversee medical devices from any location, on any screen, with HorizonView—an intelligent solution designed for seamless remote monitoring of healthcare equipment.</h2>
                    <div className='login-form-container'>
                        <div className='email-container'>
                            {/* <label className='login-label'>Email Id</label> */}
                            <input
                                className='login-input email'
                                type='email'
                                placeholder='Email ID'
                                onChange={(event) => handleChange(LOGIN_CONST.EMAIL, event)} />
                        </div>
                        <div className='password-container'>
                            {/* <label className='login-label'>Password</label> */}
                            <input
                                className='login-input password'
                                type="password"
                                placeholder='Password'
                                onChange={(event) => handleChange(LOGIN_CONST.PASSWORD, event)}
                            />
                        </div>
                        <div className='error-msg'>
                            {errorMsg}
                        </div>
                        <div className='sign-in-botton-container'>
                            <button
                                className={'sign-in sign-in-botton'}
                                onClick={() => handleSubmit()}
                            >
                                Sign In</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        hospitalData: state.hospitalDataReducer && state.hospitalDataReducer.hospitalData,
    };
}

const mapDispatchToProps = (dispatch) => {

    return {
        sidebarActiveIconAction: (payload) => dispatch(sidebarActiveIconAction(payload)),
        hospitalDataAction: (payload) => dispatch(hospitalDataAction(payload)),
        clearAlarms: (payload) => dispatch(clearAlarms(payload)),
        clearStore: (payload) => dispatch(clearStore(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);