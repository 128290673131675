import { BED_DETAILS_MODULE, DEVICE_TYPES, FILTER_TYPES, VIEW_TYPES } from "../../common/constant/common-constants";

const initialState = {
    bedDetailsReducer: {
        bedDetails: {},
        bedIdArray: [],
        favoriteBedIdArray: [],
        wardDetails: {},
        hospitalData: {},
        selectedBed: '',
        ventilatorHeartbeat: {},
        ventilatorStandby: {}
    },
    configReducer: {
        config: {
            viewType: VIEW_TYPES.GRID_VIEW,
            filterType: FILTER_TYPES.FAVORITE,
            modalConfig: {
                isOpen: false,
                optionType: null
            },
            bedDetailConfig: {
                activeModule: BED_DETAILS_MODULE.PATIENT_DETAILS
            },
            listViewParameters: [
                {
                    name: "HR",
                    label: "HR",
                    device: DEVICE_TYPES.MONITOR
                }, {
                    name: "SPO2",
                    label: "SPO2",
                    device: DEVICE_TYPES.MONITOR
                }, {
                    name: "BPLOW",
                    label: "NIBP",
                    device: DEVICE_TYPES.MONITOR
                }, {
                    name: "RR",
                    label: "RR",
                    device: DEVICE_TYPES.MONITOR
                }, {
                    name: "TEMP",
                    label: "TEMP",
                    device: DEVICE_TYPES.MONITOR
                }],
            activeDevice: DEVICE_TYPES.MONITOR
        }
    },
    deviceVitalDataReducer: {
        monitorHeartbeat: {},
        deviceVitalData: {},
        deviceEcgWaveform: {},
        deviceHrWaveform: {},
        ventilatorVitalData: {},
        ventilatorModesData: {},
        ventilatorWaveformData: {},
        ventilatorHeartbeat: {},
        ventilatorStandby: {}
    },
    wardDetailsReducer: {
        wardData: {},
        selectedWard: {},
    },
    patientDataReducer: {
        patientData: {}
    },
    alarmDataReducer: {
        alarmData: {}
    },
    notificationDataReducer: {
        notificationData: {}
    },
    hospitalDataReducer: {
        hospitalData: {}
    }
}

export default initialState;