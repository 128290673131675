import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import TimePicker from 'react-time-picker';
import trendGraphSvg from "../../common/icons/svg/trend-graph.svg";
import trendGraphSelectedSvg from "../../common/icons/svg/trend-graph-selected.svg";
import trendTableSvg from "../../common/icons/svg/trend-table.svg";
import trendTableSelectedSvg from "../../common/icons/svg/trend-table-selected.svg";
import "./TrendContainer.scss";
import { connect } from "react-redux";
import { CHART_HEADER_CONST, VITAL_OPTION, parameterSorting, parameterOptionList, durationOptionList, DEVICE_TYPES, ventilatorParameterOptionList } from "../../common/constant/common-constants";
import moment from "moment";
import { getTrendData } from "../../helper/report-helper";
import { getVentilatorTrendData } from "../../helper/report-helper";
import TrendChart from "../../components/Table/TrendChart/TrendChart";
import TrendGraph from "../../components/TrendGraph/TrendGraph";
import DevicesTab from "../PatientRightPanel/MachineTabs/DevicesTab";
import {Spinner} from "../../components/Loader/Spinner"

function TrendContainer(props) {

    const [selectedParamList, setSelectedParamList] = useState([]);
    const [duration, setDuration] = useState(null);
    const [trendStartDate, setTrendStartDate] = useState(new Date());
    const [trendEndDate, setTrendEndDate] = useState(new Date());
    const [trendStartTime, setTrendStartTime] = useState(moment(new Date()).format("HH:mm"));
    const [trendEndTime, setTrendEndTime] = useState(moment(new Date()).format("HH:mm"));
    const [interval, setInterval] = useState(null);
    const [data, setData] = useState(null);
    const [trendOption, setTrendOption] = useState(VITAL_OPTION.VITAL_TABLE);
    const [isGenerateReportVisible, setIsGenerateReportVisible] = useState(false);
    const [headerList, setHeaderList] = useState(
        [CHART_HEADER_CONST.PARAMETERS, "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00"]
    );
    const [bedId, setBedId] = useState(null);
    const [loader, setLoader] = useState(false);

    const initialState = () => {
        setData(null)
        setSelectedParamList([]);
        setDuration([]);
        setTrendStartDate(new Date());
        setTrendEndDate(new Date());
        setTrendStartTime(moment(new Date()).format("HH:mm"));
        setTrendEndTime(moment(new Date()).format("HH:mm"));
        setInterval(null);
        setIsGenerateReportVisible(false)
        setBedId(props.bedId);
    }
    console.log("interval", interval);
    useEffect(()=>{
        initialState();
    },[props.config.activeDevice])
    
    useEffect(() => {
        if (bedId !== props.bedId) {
            initialState();
        }
        console.log("new bed Id !!!");
    }, [props.bedId])

    const setParameter = (data) => {

        data.sort(function (a, b) {
            return parameterSorting.indexOf(a.id) - parameterSorting.indexOf(b.id);
        });

        console.log("trend container param select :: " + JSON.stringify(data));
        setSelectedParamList(data);
    }

    const handleTrendShow = (option) => {
        setTrendOption(option);
    }

    useEffect(() => {

        setIsGenerateReportVisible(false);
        if (trendStartDate && trendStartTime && trendEndDate && trendEndTime && interval && selectedParamList) {
            setIsGenerateReportVisible(true);
        }

    }, [trendStartDate, trendStartTime, trendEndDate, trendEndTime, interval, selectedParamList])

    const onGenerateReport = () => {
        setLoader(true);
        let data = {
            bedId: props.bedId,
            patientSessionId: props.patientSessionId,
            startDate: moment(trendStartDate).format("YYYY-MM-DD"),
            startTime: trendStartTime,
            endDate: moment(trendEndDate).format("YYYY-MM-DD"),
            endTime: trendEndTime,
            timeInterval: interval && interval.name
        }

        setIsGenerateReportVisible(false);
        if (props.activeVitalDevice === DEVICE_TYPES['MONITOR']) {
            getTrendData(data)
            .then((res) => {
                setIsGenerateReportVisible(true);
                setLoader(false);
                console.log(res);
                setData(res);

                let headerList = res.headerList || [];

                setHeaderList(headerList);

            })
            .catch((err) => {
                setIsGenerateReportVisible(true);
                setLoader(false);
                console.log(err);
            }) 
        } else {
            getVentilatorTrendData(data)
                .then((res) => {
                    setIsGenerateReportVisible(true);
                    setLoader(false);
                    console.log(res);
                    setData(res);

                    let headerList = res.headerList || [];

                    setHeaderList(headerList);

                })
                .catch((err) => {
                    setIsGenerateReportVisible(true);
                    setLoader(false);
                    console.log(err);
                })
        }
    }

    return (
        <div className="trend-container">
            <div className="generate-report-form">
                {/* <div className="report-heading">
                    Generate Report
                </div> */}
                <DevicesTab
                    activeVitalDevice={props.activeVitalDevice}
                    modeData={props.ventilatorModesData}
                    bedId={props.bedId}
                />
                <div className="report-input-fields">
                    <div className="parameter-select-container form-input">
                        <div className="parameter label">Parameters</div>
                        <CustomSelect
                            closeMenuOnSelect={false}
                            className="parameter-name trend-input"
                            handleChange={(data) => setParameter(data)}
                            value={selectedParamList}
                            label='label'
                            options={props.activeVitalDevice === DEVICE_TYPES['MONITOR'] ? parameterOptionList : ventilatorParameterOptionList}
                            placeholder='Parameter Name'
                            isMulti={true}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    {/* <div className="duration-select-container form-input">
                        <div className="duration label">Duration</div>
                        <CustomSelect
                            className="duration trend-input"
                            handleChange={(item) => setDuration(item)}
                            label='duration'
                            options={durationOptionList}
                            placeholder="Duration"
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div> */}
                    <div className="trend-date-container form-input">
                        <div className="date label">Start Date</div>
                        <div className="date-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <DatePicker
                                className="trend-date"
                                onChange={(date) => setTrendStartDate(date)}
                                value={trendStartDate}
                                clearIcon={false}
                            />
                        </div>
                    </div>
                    <div className="trend-time-container start-time form-input">
                        <div className="date label">Start Time</div>
                        <div className="time-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <TimePicker
                                className="trend-time"
                                onChange={(date) => setTrendStartTime(date)}
                                value={trendStartTime}
                                clearIcon={false}
                                disableClock={true}
                            />
                        </div>
                    </div>
                    <div className="interval-select-container form-input">
                        <div className="interval label">Time Interval</div>
                        <CustomSelect
                            className="interval trend-input"
                            handleChange={(item) => setInterval(item)}
                            label='label'
                            options={durationOptionList}
                            value={interval?.label ? interval.label : "Interval"}
                            placeholder={interval?.label ? interval.label : "Interval"}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className="trend-date-container form-input">
                        <div className="date label">End Date</div>
                        <div className="date-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <DatePicker
                                className="trend-date"
                                onChange={(date) => setTrendEndDate(date)}
                                value={trendEndDate}
                                clearIcon={false}
                            />
                        </div>
                    </div>
                    <div className="trend-time-container form-input">
                        <div className="date label">End Time</div>
                        <div className="time-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <TimePicker
                                className="trend-time"
                                onChange={(date) => setTrendEndTime(date)}
                                value={trendEndTime}
                                clearIcon={false}
                                disableClock={true}
                            />
                        </div>
                    </div>
                    <div className="submit-button-container">
                        <div
                            className={isGenerateReportVisible ? "submit-button" : "submit-button button-disabled"}
                            onClick={() => onGenerateReport()}>Generate Report</div>
                    </div>
                </div>
            </div>
            {loader ?
                <div className="loader">
                    <Spinner />
                </div>
            :
                <div className="trend-data-container">
                    <div className="trend-data-header">
                        <div className="report-text">Report</div>
                        <div className="change-trend-container">
                            <img
                                src={trendOption === VITAL_OPTION.VITAL_TABLE ? trendTableSelectedSvg : trendTableSvg}
                                className="trend-change-icon"
                                alt="vital table"
                                onClick={() => handleTrendShow("VITAL_TABLE")} />
                            <img
                                src={trendOption === VITAL_OPTION.VITAL_GRAPH ? trendGraphSelectedSvg : trendGraphSvg}
                                className="trend-change-icon"
                                alt="vital graph"
                                onClick={() => handleTrendShow("VITAL_GRAPH")} />
                        </div>
                    </div>
                    {(trendOption === VITAL_OPTION.VITAL_TABLE) && data && <div className="trend-table-container">
                        {selectedParamList && selectedParamList.length > 0 && <TrendChart
                            headerList={headerList}
                            parameterList={selectedParamList}
                            data={data}
                        />}
                    </div>}
                    {(trendOption === VITAL_OPTION.VITAL_GRAPH) && data &&
                        <TrendGraph
                            selectedParamList={selectedParamList}
                            data={data}
                        />
                    }
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {

    let pData = state.patientDataReducer.patientData && state.patientDataReducer.patientData[ownProps.bedId];
    let patientSessionId = state.patientDataReducer.patientData
        && state.patientDataReducer.patientData[ownProps.bedId]
        && state.patientDataReducer.patientData[ownProps.bedId].session_id;

    return {
        patientData: pData,
        patientSessionId: patientSessionId,
        config: state.configReducer.config
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendContainer);