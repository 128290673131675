import "./SOFAScore.scss"

import { Header } from "../Header/Header";
import SideBar from "../SideBar/Sidebar";
import React, { useState, useEffect } from 'react';
import mmhgReversible from "../../common/icons/svg/mmHg-reversible.svg";


function SOFAScore(props) {

    const [plateletsScore, setPlateletsScore] = useState(0);
    const [GCSScore, setGCSScore] = useState(0);
    const [bilirubinScore, setBilirubinScore] = useState(0);
    const [arterialPressureScore, setArterialPressureScore] = useState(0);
    const [totalSOFAScore, setTotalSOFAScore] = useState(0);

    const handlePlateletRadio = (score) => {
        setPlateletsScore(score);
    }
    const handleGCSScore = (score) => {
        setGCSScore(score);
    }
    const handleBilirubinScore = (score) => {
        setBilirubinScore(score);
    }
    const handleArterialPressure = (score) => {
        setArterialPressureScore(score);
    }

    useEffect(() => {
        setTotalSOFAScore(plateletsScore + GCSScore + bilirubinScore + arterialPressureScore);
    }, [plateletsScore, GCSScore, bilirubinScore, arterialPressureScore]);

    return (
        <div className="sofa-score-container">
            <Header
            // handleWardChange={(item) => setSelectedWard(item)}
            // wardOption={wardOption}
            // selectedWard={selectedWard} 
            />
            <SideBar />
            <div className="sofa-score-body">
                <div className="sofa-heading-container">
                    <span className="heading">SOFA</span>
                    <div className={`show-score
                                        ${totalSOFAScore <= 3 ? "red-score" : ""} 
                                        ${(totalSOFAScore <= 8) && (totalSOFAScore > 3) ? "yellow-score" : ""}
                                        ${totalSOFAScore > 8 ? "green-score" : ""}
                                         `}>Score: {totalSOFAScore}</div>
                </div>
                <div className="sofa-rating-container">
                    <div className="common-rating-cls">
                        <div className="pao2-container">
                            <div className="pao2  common-param">PaO2</div>
                            <div className="pao2-value">75</div>
                        </div>
                        <div className="common-side-container">
                            <span className="mmHg">mmHg</span>
                            <img src={mmhgReversible} />
                        </div>
                    </div>
                    <div className="common-rating-cls">
                        <div className="fio2-container">
                            <div className="fio2  common-param">FiO2</div>
                            <div className="fio2-value">75</div>
                        </div>
                        <div className="common-side-container">
                            <span className="">%</span>
                        </div>
                    </div>
                    <div className="vent-container">
                        <span>Patient Mechanically Ventilated</span>
                        <div className="vent-input-container">
                            <div className="vent-common">
                                <input type="radio" id="yes" name="vent-yes-no" />
                                <label htmlFor="yes"> Yes</label>
                            </div>
                            <div className="vent-common">
                                <input type="radio" id="no" name="vent-yes-no" />
                                <label htmlFor="no"> No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sofa-mcq-container">
                    <div className="common-mcq">
                        <div className="mcq-heading">Platelets, x10³/µL</div>
                        <div className="mcq-score">{plateletsScore}</div>
                        <div className="mcq-options-common">
                            <div className="radio-opt">
                                <input type="radio" id="1.1" name="platelets" onClick={() => handlePlateletRadio(0)} />
                                <label htmlFor="1.1">≥150 (0)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="1.2" name="platelets" onClick={() => handlePlateletRadio(1)} />
                                <label htmlFor="1.2">100 - 149 (+1)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="1.3" name="platelets" onClick={() => handlePlateletRadio(2)} />
                                <label htmlFor="1.3">50 - 99 (+2)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="1.4" name="platelets" onClick={() => handlePlateletRadio(3)} />
                                <label htmlFor="1.4">20 - 49 (+3)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="1.5" name="platelets" onClick={() => handlePlateletRadio(4)} />
                                <label htmlFor="1.5">&#60; 20 (+4)</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-mcq">
                        <div className="mcq-heading">Glasgow Coma Scale</div>
                        <div className="mcq-score">{GCSScore}</div>
                        <div className="mcq-options-common">
                            <div className="radio-opt">
                                <input type="radio" id="2.1" name="gcs-score" onClick={() => handleGCSScore(0)} />
                                <label htmlFor="2.1">15 (0)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="2.2" name="gcs-score" onClick={() => handleGCSScore(1)} />
                                <label htmlFor="2.2">13 - 14 (+1)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="2.3" name="gcs-score" onClick={() => handleGCSScore(2)} />
                                <label htmlFor="2.3">10 - 12 (+2)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="2.4" name="gcs-score" onClick={() => handleGCSScore(3)} />
                                <label htmlFor="2.4">6 - 9 (+3)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="2.5" name="gcs-score" onClick={() => handleGCSScore(4)} />
                                <label htmlFor="2.5" >&#60; 6 (+4)</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-mcq">
                        <div className="mcq-heading">Bilirubin, mg/dL (μmol/L)</div>
                        <div className="mcq-score">{bilirubinScore}</div>
                        <div className="mcq-options-common">
                            <div className="radio-opt">
                                <input type="radio" id="3.1" name="billirubin" onClick={() => handleBilirubinScore(0)} />
                                <label htmlFor="3.1">&#60; 1.2 (&#60; 20) (0)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="3.2" name="billirubin" onClick={() => handleBilirubinScore(1)} />
                                <label htmlFor="3.2">1.2-1.9 (20-32) (+1)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="3.3" name="billirubin" onClick={() => handleBilirubinScore(2)} />
                                <label htmlFor="3.3">2.0-5.9 (33-101) (+2)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="3.4" name="billirubin" onClick={() => handleBilirubinScore(3)} />
                                <label htmlFor="3.4">6.0-11.9 (102-204) (+3)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="3.5" name="billirubin" onClick={() => handleBilirubinScore(4)} />
                                <label htmlFor="3.5">≥12.0 (&#62; 204) (+4)</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-mcq">
                        <div className="mcq-heading">Mean arterial pressure OR administration of vasoactive agents required</div>
                        <div className="mcq-score">{arterialPressureScore}</div>
                        <div className="mcq-options-common">
                            <div className="radio-opt">
                                <input type="radio" id="4.1" name="arterial-pressure" onClick={() => handleArterialPressure(0)} />
                                <label htmlFor="4.1">No hypotension</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="4.2" name="arterial-pressure" onClick={() => handleArterialPressure(1)} />
                                <label htmlFor="4.2">MAP &#60; 70 mmHg</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="4.3" name="arterial-pressure" onClick={() => handleArterialPressure(2)} />
                                <label htmlFor="4.3">Dopamine ≤ 5 or DOBUTamine (any dose)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="4.4" name="arterial-pressure" onClick={() => handleArterialPressure(3)} />
                                <label htmlFor="4.4">Dopamine &#60; 5, Epinephrine ≤0.1, or Norepinephrin ≤0.1</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="4.5" name="arterial-pressure" onClick={() => handleArterialPressure(4)} />
                                <label htmlFor="4.5">Dopamine &#62; 15, Epinephrine &#62; 0.1, or Norepinephrin  &#62; 0.1</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sofa-total-rating-container">
                    <span>Total SOFA Score</span>
                    <div className="total-rating">{totalSOFAScore}</div>
                </div>
                <div className="submit-btn-container">
                    <div className="submit-btn" onClick={() => props.handleSubmitClick(totalSOFAScore)}>Submit</div>
                </div>


            </div>
        </div>
    )
}
export default SOFAScore;