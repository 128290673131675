import { ALARM_PRIORITY } from "../ENUMS/PARAMETER_ENUM";
import { BED_DETAILS_MODULE, MODAL_ITEMS } from "../constant/common-constants";

// {behavior: "smooth", block: "center", inline: "nearest"}
export const handleOnFocusById = (id) => {

    try {
        document.getElementById(id).scrollIntoView({ block: "center", inline: "nearest" });
    } catch (error) {
        console.log("error occured in handleOnFocus!! ");
    }
}

export const handleOutsideClick = (event, id, setState) => {
    event.stopPropagation();
    setState(true);
    let element = document.getElementById(id);
    window.addEventListener('click', function (e) {
        if (element && element.contains(e.target)) {
        } else {
            setState(false);
        }
    });
}

export const handleScrollToTop = () => {
    window.scrollTo(0, 0);
}

export const handleScrollToBottom = (id) => {

    let ele = document.getElementById(id);
    if (ele) ele.scrollTop = ele.scrollHeight;
}

export const blurApp = () => {

    try {
        let elementList = document.getElementsByTagName("button");

        Array.from(elementList).forEach(function (element) {
            element.classList.add("pointer-event-none");
        });
        document.getElementById('App').classList.add('blur-with-pointer');
    } catch (err) {
        console.log("error occured in blurApp");
    }
}

export const unBlurApp = () => {

    try {
        let elementList = document.getElementsByTagName("button");

        Array.from(elementList).forEach(function (element) {
            element.classList.remove("pointer-event-none");
        });
        document.getElementById('App').classList.remove('blur-with-pointer');
    } catch (err) {
        console.log("error occured in unBlurApp");
    }
}

export const ConvertStringToHex = (hexString) => {
    var arr = [];
    for (var i = 0; i < hexString.length; i++) {
        arr[i] = ("00" + hexString.charCodeAt(i).toString(16)).slice(-4);
    }
    return "\\u" + arr.join("\\u");
}

export const getActiveModuleForBedDetails = (activeItem) => {

    switch (activeItem) {
        case MODAL_ITEMS.CHARTING:
            return BED_DETAILS_MODULE.CHARTING;
        case MODAL_ITEMS.NURSE_NOTES:
            return BED_DETAILS_MODULE.NURSE_NOTES;
        case MODAL_ITEMS.DOCTOR_NOTES:
            return BED_DETAILS_MODULE.DOCTOR_NOTES;
        case MODAL_ITEMS.PRESCRIPTION:
            return BED_DETAILS_MODULE.PRESCRIPTION;
        case MODAL_ITEMS.ALARM_HISTORY:
            return BED_DETAILS_MODULE.ALARMS;
        default:
            return BED_DETAILS_MODULE.PATIENT_DETAILS;
    }
}

export const getAlarmPriority = (priority) => {

    switch (priority) {
        case "HIGH":
            return 2;
        case "MEDIUM":
            return 1;
        case "LOW":
            return 0;
        default:
            return 0;
    }
}

export const alarmBackground = (alarms) => {
    let backgroundColor = alarms?.[0]?.priority;
    alarms?.map((alarm) => {
        if (ALARM_PRIORITY[backgroundColor] < ALARM_PRIORITY[alarm?.priority]){
            backgroundColor = alarm?.priority;
        }
    });
    return backgroundColor ? (backgroundColor + '-priority') : '';
}

export const covertDateString = (observedTime) => observedTime?.slice(0,4) + '-' + observedTime?.slice(4,6) + '-' + observedTime?.slice(6,8);

export const covertTimeString = (observedTime) => {
    return observedTime?.slice(8,10) > 12 ? 
            observedTime?.slice(8,10) - 12 +  ':' + observedTime?.slice(10,12) + ':' + observedTime?.slice(12,14) + 'PM' 
        : observedTime?.slice(8,10) == 12 ? 
            observedTime?.slice(8,10) + ':' + observedTime?.slice(10,12) + ':' + observedTime?.slice(12,14) + 'PM'
        : 
            observedTime?.slice(8,10) + ':' + observedTime?.slice(10,12) + ':' + observedTime?.slice(12,14) + 'AM'
}