import { getVentilatorModeParamsAPI } from "../adapter/cms-adapter";


export const getVentilatorMode = (data) => {
    let reqData = {
        bedId: data
    };
    let promise = new Promise(function (resolve, reject) {
        getVentilatorModeParamsAPI(reqData)
        .then((res) => {
            if (res && res.data && res.data.data) resolve(res.data.data);
            else reject("some error message getVentilatorAlarmHistory");
        }).catch((err) => {
            reject("Error in Ventilator Alarm History");
        })
    })
    return promise;
}