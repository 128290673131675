import React, { useEffect, useState } from "react";
import { ALARM_FILTER, DEVICE_TYPES, getAlarmHeader, PAGE_PARENT } from "../../common/constant/common-constants";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { MonitorAlarmTable } from "../../components/Table/Alarm/MonitorAlarmTable";
import { VentilatorAlarmTable } from "../../components/Table/Alarm/VentilatorAlarmTable";
import DevicesTab from "../PatientRightPanel/MachineTabs/DevicesTab";
import "./AlarmHistoryContainer.scss";

function AlarmHistoryContainer(props) {
    const [header, setHeader] = useState(getAlarmHeader(DEVICE_TYPES.MONITOR));
    const [ventilatorEventFilter, setVentilatorEventFilter] = useState({ name: null, label: 'All', id: 1 });
    const [monitorEventFilter, setMonitorEventFilter] = useState({ name: 'ALL', label: 'All', id: 1 });

    useEffect(() => {
        setHeader(getAlarmHeader(props.activeVitalDevice));
    }, [props.activeVitalDevice])

    const handleEventFilterChange = (option) => {
        if (props.activeVitalDevice === DEVICE_TYPES.MONITOR){
            setMonitorEventFilter(option)
        }
        if(props.activeVitalDevice === DEVICE_TYPES.VENTILATOR){
            setVentilatorEventFilter(option)
        }
    }

return(
        <div className="alarm-history-container">
            {props.parent === PAGE_PARENT.PATIENT_MODAL && <div className="alarm-history-header-container">
                <div className="alarm-history-text">Alarm History</div>
            </div>}
            <div className='device-select-btn-cont'>
                <div className="event-filter-select">
                    {props.activeVitalDevice === DEVICE_TYPES.VENTILATOR ?
                        <CustomSelect
                            className="filter-select"
                            handleChange={(option) => handleEventFilterChange(option)}
                            value={props.activeVitalDevice === DEVICE_TYPES.MONITOR ? monitorEventFilter : ventilatorEventFilter}
                            label='PARAMETER NAME'
                            options={ALARM_FILTER[props.activeVitalDevice]}
                            // isDisabled={patientData ? true : false}
                            placeholder='Event Filter Group'
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                            backgroundColor='#242424'
                            singleValFontSize='14px'
                            optionFontSize='14px'
                            optionPadding='10px'
                            isClearable={false}
                            controlHeight='20px'
                        />
                    :
                        <div style={{width:'210px'}} />
                    }
                </div>
                <DevicesTab
                    activeVitalDevice={props.activeVitalDevice}
                    modeData={props.ventilatorModesData}
                    bedId={props.bedId}
                />
            </div>
            <div className="alarm-history-data-container">
                {props.activeVitalDevice === DEVICE_TYPES.MONITOR && 
                    <MonitorAlarmTable 
                        bedId={props.bedId}
                        header={header}
                        filter={monitorEventFilter}
                    />
                }
                {props.activeVitalDevice === DEVICE_TYPES.VENTILATOR && 
                    <VentilatorAlarmTable 
                        bedId={props.bedId}
                        header={header}
                        ventilatorAlarmData={props.ventilatorAlarmData}
                        filter={ventilatorEventFilter}
                    />
                }
            </div>
        </div>
    );
}

export default AlarmHistoryContainer;