import "./BedCardVentilatorMonitor.scss"
import React, { forwardRef, useState, useEffect } from "react";
// import bellAlert from "../../common/icons/svg/bell-alert.svg";
import favoriteSvg from "../../common/icons/svg/favorite.svg";
import favoriteSelectedSvg from "../../common/icons/svg/favorite-selected.svg";
import blackDownArrow from "../../common/icons/svg/black-down-arrow.svg";
import blackUpArrow from "../../common/icons/svg/black-up-arrow.svg";
import whiteUpArrow from "../../common/icons/svg/white-up-arrow.svg";
import whiteDownArrow from "../../common/icons/svg/white-down-arrow.svg";
import noPatientInfoIcon from "../../common/icons/svg/no-patient-info-icon.svg";
import {
    ADD,
    ALARM_PRIORITY,
    REMOVE,
    VIEW_TYPES,
    MORE_GRAPH_OPTIONS,
    BED_CARD_VITALS,
    GRAPH_TYPE
} from "../../common/constant/common-constants";
import moment from "moment";
import { connect } from "react-redux";
import { alarmBackground, handleOutsideClick } from "../../common/utils/CommonUtils";
import { OBSERVED_PARAMETERS, PATIENT_TYPE } from "../../common/ENUMS/PARAMETER_ENUM";
import { TEMP_MODE } from "../../common/ENUMS/VENTILATOR_MODES_ENUM";

const BedCardVentilatorMonitor = forwardRef((props, ref) => {

    // console.log("props in card alarm", props.alarmData);
    const [showMoreAlarms, setShowMoreAlarms] = useState(null)
    const [selectMoreVitalGraph, setSelectMoreVitalGraph] = useState(null);
    const [ecgDataObj, setEcgDataObj] = useState([]);
    const [plethDataObj, setPlethDataObj] = useState([]);
    const [vitalGraphName, setVitalGraphName] = useState(MORE_GRAPH_OPTIONS.MONITOR[0]);
    const [lastAlarm, setLastAlarm] = useState();
    const [lastPushedPlethTime, setLastPushedPlethTime] = useState(null);
    const [lastPushedEcgTime, setLastPushedEcgTime] = useState(null);
    const [showMoreVentilatorAlarms, setShowMoreVentilatorAlarms] = useState(false);
    // const prevLastPushedEcgTime = usePrevious(lastPushedEcgTime);

    // useEffect(() => {
    //     // when monitor is not connected to a, lastPushedEcgTime are null
    //     // so this runs after the monitor is disconnected. showAlarmData becomes true in this case.
    //     const interval = setInterval(() => {
    //         if (lastPushedEcgTime && prevLastPushedEcgTime) {
    //             // console.log("time", props.bedId, lastPushedEcgTime, prevLastPushedEcgTime)
    //             if (lastPushedEcgTime === prevLastPushedEcgTime) props.handleClearBedAlarm(props.bedId)
    //             else setShowAlarmData(true);
    //         } else {
    //             // props.handleClearBedAlarm(props.bedId);
    //             setShowAlarmData(false);
    //         }

    //     }, 500);
    //     return () => clearInterval(interval);

    // }, [lastPushedEcgTime])

    useEffect(() => {

        if (props.alarmData && props.alarmData.length > 0) {
            let alarm = props.alarmData[0];

            let alarmClass = getAlarmPriorityClass(alarm);
            if (alarmClass === ALARM_PRIORITY.HIGH_PRIORITY &&
                (!lastAlarm || !(lastAlarm.name === alarm.name && lastAlarm.value === alarm.value))) {
                let snd = new Audio("https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3");
                // snd.play();

                setLastAlarm(alarm);
            }
        }
    }, [props.alarmData]);


    useEffect(() => {
        if (props.ventilatorAlarmData && props.ventilatorAlarmData.length > 0) {
            let alarm = props.ventilatorAlarmData[0];

            let alarmClass = getAlarmPriorityClass(alarm);

            if (alarmClass === ALARM_PRIORITY.HIGH_PRIORITY &&
                (!lastAlarm || !(lastAlarm.name === alarm.name && lastAlarm.value === alarm.value))) {
                let snd = new Audio("https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3");
                snd.play();
                setLastAlarm(alarm);
            }
        }
    }, [props.ventilatorAlarmData]);

    const handleShowMoreAlarmsClick = (event) => {
        event.stopPropagation();
        setShowMoreAlarms(!showMoreAlarms);
        if (!showMoreAlarms) {
            handleOutsideClick(event, 'alarm-display-bar', setShowMoreAlarms);
        }
    }

    const handleShowMoreVentilatorAlarmsClick = (event) => {
        event.stopPropagation();
        setShowMoreVentilatorAlarms(!showMoreVentilatorAlarms);
        if (!showMoreVentilatorAlarms) {
            handleOutsideClick(event, 'alarm-display-bar', setShowMoreVentilatorAlarms);
        }
    }


    const handleSelectMoreVital = (event) => {
        event.stopPropagation();
        setSelectMoreVitalGraph(!selectMoreVitalGraph);
        if (!selectMoreVitalGraph) {
            handleOutsideClick(event, 'vital-graph-monitor', setSelectMoreVitalGraph);
        }

    }

    const handleVitalGraphSelect = (event, option) => {
        event.stopPropagation();
        setVitalGraphName(option);
        setSelectMoreVitalGraph(false);
    }


    const getAlarmPriorityClass = (alarmData) => {

        // let id = bedId && bedId.toString();

        switch (alarmData.priority?.toLocaleUpperCase()) {
            case "HIGH":
                return ALARM_PRIORITY.HIGH_PRIORITY;
            case "MEDIUM":
                return ALARM_PRIORITY.MEDIUM_PRIORITY;
            case "LOW":
                return ALARM_PRIORITY.LOW_PRIORITY;
            default:
                return ALARM_PRIORITY.LOW_PRIORITY;
        }
    }

    useEffect(() => {
        if (props.isSelected) return;
        if ([GRAPH_TYPE.ECG].indexOf(vitalGraphName && vitalGraphName.type) < 0) return;

        let ecgDataList = [];
        let ecgWaveformData = props.monitorEcgWaveFormData
            && props.monitorEcgWaveFormData.waveform
            && props.monitorEcgWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (vitalGraphName && vitalGraphName.name)
                }
            )[0];

        if (ecgWaveformData && ecgWaveformData.time) {
            if (lastPushedEcgTime === ecgWaveformData.time) {
                return;
            } else {
                setLastPushedEcgTime(ecgWaveformData.time);
            }

            let rawData = ecgWaveformData.data;
            let time = ecgWaveformData.time;

            // console.log("rawData: ", rawData);
            let formattedData = rawData.split("^");
            // console.log("formattedData: ", formattedData);
            // console.log("formatted time: ", time, " formattedData: ", formattedData);

            let ecgGraphData = [];
            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    ecgGraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            ecgDataList = (ecgDataObj && ecgDataObj.concat(ecgGraphData)) || [];
            if (ecgDataList && ecgDataList.length > 3000) {
                ecgDataList.splice(0, 600);
            }

            if (props.bedId === 8) {
                console.log("ecgDataObj: ", ecgDataObj);
                console.log("ecgDataList ------", ecgDataList);
            }

            setEcgDataObj(ecgDataList);
        }

    }, [props.monitorEcgWaveFormData])

    useEffect(() => {

        if (props.isSelected) return;
        if ([GRAPH_TYPE.RESP, GRAPH_TYPE.PLETH].indexOf(vitalGraphName && vitalGraphName.type) < 0) return;

        let plethDataList;

        let plethWaveformData = props.monitorHrWaveFormData
            && props.monitorHrWaveFormData.waveform
            && props.monitorHrWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (vitalGraphName && vitalGraphName.name)
                }
            )[0];

        if (plethWaveformData && plethWaveformData.time) {
            if (lastPushedPlethTime ===
                plethWaveformData.time) {
                console.log("last pushed pleth time  is same");
                return;
            } else {
                setLastPushedPlethTime(plethWaveformData.time);
            }

            let rawData = plethWaveformData.data;
            let time = plethWaveformData.time;
            let formattedData = rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let plethGraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    plethGraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            plethDataList = (plethDataObj && plethDataObj.concat(plethGraphData)) || [];
            if (plethDataList && plethDataList.length > 3500) {
                plethDataList.splice(0, 600);
            }

            setPlethDataObj(plethDataList);
        }

    }, [props.monitorHrWaveFormData])

    const ventilatorConnected = props.ventilatorHeartbeat?.isConnected;
    const ventilationStatus = props.ventilatorStandby?.ventilationStatus;
    const monitorConnected = props?.monitorHeartBeatData?.isConnected
    return (
        <div
            className={props.config.viewType === VIEW_TYPES.LIST_VIEW ?
                "bedcard-monitor-container left-panel-card" : "bedcard-monitor-container"}
            key={props.bedId}
            ref={props.myForwardedRef}
        >
            <div
                className={props.isSelected === props.bedId ?
                    "bedcard-monitor selected-card" : `bedcard-monitor ${(props.bedDetails?.ventilatorId || props?.bedDetails?.patientMonitorId) ? '' : 'disabled'}`}
                onClick={() => props.handleBedClick(props.bedId)}>
                <div className="bed-patient-details">
                    <div className="bed-header">
                        <div className="bed-number">
                            Bed: {props.data.bedName}
                        </div>
                        {!props.isFavorite && <div className="icon-container favorite-icon">
                            <img 
                                src={favoriteSvg} 
                                alt="notification icon" 
                                className="fav-icon" 
                                onClick={(event) => props.handleFavoriteClick(event, ADD, props.bedId)} 
                            />
                        </div>}
                        {props.isFavorite && <div className="icon-container favorite-icon">
                            <img 
                                src={favoriteSelectedSvg} 
                                alt="favorite icon"
                                className="fav-icon"
                                onClick={(event) => props.handleFavoriteClick(event, REMOVE, props.bedId)} 
                            />
                        </div>}
                    </div>
                    <div className="patient-details-wrapper">
                        <div className="patient-details">
                            {props?.patientData?.name ? 
                                <>
                                    <div className="patient-name">
                                        {(props?.patientData?.name) || 'Dummy Name'}
                                    </div>
                                    <div className="pipe">
                                        |
                                    </div>
                                    <div className="patient-age">
                                        {(props.patientData && props.patientData.age) || 'Age'}
                                    </div>
                                    <div className="pipe">
                                        |
                                    </div>
                                    <div className="patient-gender">
                                        {(props.patientData && props.patientData.gender) || 'Gender'}
                                    </div>
                                </>
                            :
                                <div className="no-patient-detail-container">
                                    <img src={noPatientInfoIcon} alt="info icon" />
                                    <span>Patient Details not entered.</span>
                                    <span
                                        className="add-patient-btn"
                                        onClick={
                                            (event) => { event.stopPropagation(); props.handleAddPatientClick(event) }
                                        }
                                    >
                                        Add Patient
                                    </span>
                                </div>
                            }
                        </div>
                        <>
                            {props?.bedDetails?.patientMonitorId?.split('-')[0]}
                        </>
                    </div>
                    <div className={monitorConnected && props?.bedDetails?.patientMonitorId ? '' : 'not-connected'}>
                        {monitorConnected && props?.monitorData?.vitals && props?.alarmData?.length > 0 ?
                            <div className={`alarm-container ${getAlarmPriorityClass(props.alarmData[0])}`}
                                onClick={(event) => handleShowMoreAlarmsClick(event)}
                                id="alarm-display-bar"
                            >
                                <div className="top-alarm-container">
                                    {props.alarmData[0].name}
                                </div>
                                <div className="alarm-number-container">
                                    {props.alarmData.length}
                                    {showMoreAlarms ? 
                                        <img src={props.alarmData[0]?.priority === "HIGH" ? whiteUpArrow : blackUpArrow} className="arrow-icon" alt="white up arrow" />
                                    :
                                        <img src={props.alarmData[0]?.priority === "HIGH" ? whiteDownArrow : blackDownArrow} className="arrow-icon" alt="white down arrow" />
                                    }
                                </div>
                                {showMoreAlarms && 
                                    <div className="more-alarm-container">
                                        {
                                            props?.alarmData?.map((alarm, count) => {
                                                if (count === 0 || count > 3) return;
                                                return (
                                                    <div className="alarm more-alarm" key={count}>
                                                        {`${alarm.name}: ${alarm.value != null ? alarm.value : ''}`}
                                                        <span className="alarm-time">{alarm.time && moment(alarm.time).format("HH:mm:ss")}</span>
                                                    </div>);
                                            })
                                        }
                                        <div className="see-all-container" onClick={() => props.handleAlarmMoreClick()}>
                                            See all
                                        </div>
                                    </div>
                                }
                            </div>
                        :
                            <div className="alarm-container no-alarm"
                                id="no-alarm-display-bar"
                            >
                                <div className="top-alarm-container">
                                    No active alarms
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={`device-monitor-data ${props?.bedDetails?.patientMonitorId ? '' : 'not-connected'}`}>
                    {
                        BED_CARD_VITALS.MONITOR?.map((vital) => {
                            let vitalData = props?.monitorData?.vitals?.filter(
                                (mvital) => mvital?.name?.toUpperCase() === vital.name
                            )[0];

                            let backup;
                            if (vital.backup) {
                                backup = props.monitorData && props.monitorData.vitals.filter(
                                    (mvital) => mvital.name && mvital.name.toUpperCase() === vital.backup
                                )[0];
                            }

                            return (
                                <div 
                                    className={
                                        vitalData?.data?.[0] !== "null/null" && 
                                            vitalData?.data[0]?.value ?
                                                `${vitalData.data[0]?.status.toLowerCase()} vital-data-detail ${vital.name === 'BP' ? "wide-field" : ""}` 
                                            :
                                                (
                                                    backup?.data[0]?.status ?
                                                        `${backup.data[0].status.toLowerCase()} vital-data-detail ${vital.name === 'BP' ? "wide-field" : ""}` 
                                                    :
                                                        `vital-data-detail ${vital.name === 'BP' ? "wide-field" : ""}`
                                                )
                                        }
                                    key={vital.name}
                                >
                                    <div className="vital-name">
                                        {vital.label}
                                    </div>
                                    <div className="vital-data">
                                        {monitorConnected && props?.monitorHeartBeatData ? 
                                            (
                                                (vitalData?.data?.[0] !== "null/null" && vitalData?.data?.[0]?.value) 
                                            ||
                                                (backup?.data?.[0]?.value) || '-'
                                            )
                                        :
                                            '-'
                                        }
                                        {vitalData?.unit && 
                                            <span className="unit">{vitalData.unit || 'unit'}</span>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='device-ventilator-data-wrapper'>
                    <div className="ventilator-mode">
                        <div className="flex-alignCenter">
                            Ventilator : {ventilatorConnected && TEMP_MODE[props?.ventilatorMode?.modeName]?.mode + ' ' + PATIENT_TYPE[props?.ventilatorMode?.patientType]} 
                        </div>
                        {props?.bedDetails?.ventilatorId ?
                            <div className="flex-alignCenter">
                                {props?.bedDetails?.ventilatorId?.substr(-5)}
                                <div className={`dot ${ventilatorConnected ? (ventilationStatus ? 'connected' : 'standby') : 'disconnected'}`}/>
                            </div>
                        :
                            <div>Not connected</div>
                        }
                    </div>
                    <div className={props?.bedDetails?.ventilatorId ? '' : 'not-connected'}>
                        {ventilatorConnected && ventilationStatus && props?.ventilatorAlarmData?.length > 0 ?
                            <div className={`alarm-container ${getAlarmPriorityClass(props.ventilatorAlarmData[0])}`}
                                onClick={(event) => handleShowMoreVentilatorAlarmsClick(event)}
                                id="alarm-display-bar"
                            >
                                <div className="top-alarm-container">
                                    {props.ventilatorAlarmData[0].name}
                                </div>
                                <div className="alarm-number-container">
                                    {props.ventilatorAlarmData.length}
                                    {showMoreVentilatorAlarms ? 
                                        <img src={props.ventilatorAlarmData[0].priority?.toLocaleUpperCase() === "HIGH" ? whiteUpArrow : blackUpArrow} className="arrow-icon" alt="white up arrow" />
                                    :
                                        <img src={props.ventilatorAlarmData[0].priority?.toLocaleUpperCase() === "HIGH" ? whiteDownArrow : blackDownArrow} className="arrow-icon" alt="white down arrow" />
                                    }
                                </div>
                                {showMoreVentilatorAlarms && 
                                    <div className="more-alarm-container">
                                        {
                                            props?.ventilatorAlarmData?.map((alarm, count) => {
                                                if (count === 0 || count > 3) return;
                                                return (
                                                    <div className="alarm more-alarm" key={count}>
                                                        {alarm.name}
                                                        <span className="alarm-time">{alarm.time && moment(alarm.time).format("HH:mm:ss")}</span>
                                                    </div>);
                                            })
                                        }
                                        <div className="see-all-container" onClick={() => props.handleAlarmMoreClick()}>
                                            See all
                                        </div>
                                    </div>
                                }
                            </div>
                        :
                            <div className="alarm-container no-alarm"
                                id="no-alarm-display-bar"
                            >
                                <div className="top-alarm-container">
                                    No active alarms
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`device-ventilator-data ${props?.bedDetails?.ventilatorId ? '' : 'not-connected'}`}>
                        {
                            BED_CARD_VITALS.VENTILATOR.map((vital) => {
                                let vitalData = props?.ventilatorData?.ventilatorVitalData[vital.name.toLowerCase()];
                                return (
                                    <div 
                                        className={`vital-data-detail ${vital.name === 'VTE' ? "wide-field" : ""} ${ventilatorConnected && ventilationStatus ? alarmBackground(vitalData?.alarms) : ""}`} 
                                        key={vital.name}
                                    >
                                        <div className="vital-name">
                                            {OBSERVED_PARAMETERS?.[vital?.id]?.['TITLE_START']}<sub>{OBSERVED_PARAMETERS?.[vital?.id]?.['TITLE_SUB_SCRIPT']}</sub>{OBSERVED_PARAMETERS?.[vital?.id]?.['TITLE_END']}
                                        </div>
                                        <div className="vital-data">
                                            {isNaN(vitalData?.value) ? '-' : ventilatorConnected && ventilationStatus ? vitalData?.value : '-'}
                                            <div className="unit">
                                                {OBSERVED_PARAMETERS?.[vital?.id]?.['UNIT_START']}<sub>{OBSERVED_PARAMETERS?.[vital?.id]?.['UNIT_SUB_SCRIPT']}</sub>{OBSERVED_PARAMETERS?.[vital?.id]?.['UNIT_END']}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div> 
            </div>
        </div >
    );

})
const mapStateToProps = (state, ownProps) => {
    let bedDetails = state.bedDetailsReducer.bedDetails[ownProps.bedId];
    let ventilatorHeartbeat = state.deviceVitalDataReducer?.ventilatorHeartbeat?.[ownProps.bedId];
    let ventilatorStandby = state.deviceVitalDataReducer?.ventilatorStandby?.[ownProps.bedId];
    return {
        bedDetails: bedDetails,
        ventilatorHeartbeat: ventilatorHeartbeat,
        ventilatorStandby: ventilatorStandby,
        config: state.configReducer.config,
    };
};
// const mapDispatchToProps = (dispatch) => {
//     return {
//         clearBedAlarm: (payload) => dispatch(clearBedAlarm(payload)),
//     }
// }

const ConnectedBedCardVentilatorMonitor = connect(
    mapStateToProps, null
)(BedCardVentilatorMonitor);

const BedCardVentilatorMonitorRef = forwardRef((props, ref) =>
    <ConnectedBedCardVentilatorMonitor key={props.bedId} {...props} myForwardedRef={ref} />
);
export default BedCardVentilatorMonitorRef;