import React from 'react';
import { DEVICE_TYPES } from '../../common/constant/common-constants';
import "./VitalDetailsContainer.scss";
import { MonitorVitalData } from '../../components/VitalData/MonitorVitalData';
import VentilatorVitalData from '../../components/VitalData/VentilatorVitalData';
import DevicesTab from '../PatientRightPanel/MachineTabs/DevicesTab';

function VitalDetailsContainer(props) {
    const {alarmData, handleSeeAllAlarms, activeVitalDevice, setActiveVitalDevice, ventilatorModesData } = props;
    // useEffect(()=>{
    //     if (!bedId){
    //         setBedId(props.bedId);
    //     } else {
    //         if (bedId !== props.bedId) {
    //             //setActiveVitalDevice(DEVICE_TYPES.MONITOR);
    //             window.location.reload()
    //         }
    //     }
    //     //window.location.reload()
    //     //setActiveVitalDevice(DEVICE_TYPES.MONITOR);
    // },[props.bedId]);

    return (
        <div className='vitals-data-container'>
            <DevicesTab 
                alarmData={alarmData}
                handleSeeAllAlarms={handleSeeAllAlarms}
                activeVitalDevice={activeVitalDevice}
                setActiveVitalDevice={setActiveVitalDevice}
                modeData={ventilatorModesData}
                bedId={props.bedId}
            />
            {activeVitalDevice === DEVICE_TYPES.MONITOR &&
                <MonitorVitalData
                    bedId={props.bedId}
                    monitorHeartBeatData={props.monitorHeartBeatData}
                    monitorEcgWaveFormData={props.monitorEcgWaveFormData}
                    monitorHrWaveFormData={props.monitorHrWaveFormData}
                    vitalData={props.vitalData}
                />}
            {activeVitalDevice === DEVICE_TYPES.VENTILATOR &&
                <VentilatorVitalData
                    bedId={props.bedId}
                />}
        </div>
    );
}

export default VitalDetailsContainer;