import React, { useState, useEffect } from 'react';

import { Header } from "../../components/Header/Header";
import SideBar from "../../components/SideBar/Sidebar";
import {
    bedDetailAction,
    bedIdAction,
    patientDataAction,
    deviceVitalDataAction,
    monitorHeartbeatAction,
    alarmDataAction,
    ventilatorAlarmDataAction,
    ventilatorHeartbeatAction,
    notificationDataAction,
    filterAction,
    wardDetailsAction,
    addColumnBedListAction,
    removeColumnBedListAction,
    clearAlarms,
    deviceWaveformDataAction,
    deviceEcgWaveformDataAction,
    deviceHrWaveformDataAction,
    selectedWardAction,
    hospitalDataAction,
    sidebarActiveIconAction,
    ventilatorVitalDataAction,
    ventilatorModesDataAction,
    ventilatorStandbyDataAction,
    // ventilatorWaveformDataAction
} from '../../modules/actions/action';
import {
    openSocketConnection,
    closeSocketConnection,
    setBedDetailsAction,
    setBedIdAction,
    setPatientDataAction,
    setDeviceVitalDataAction,
    setMonitorHeartbeatAction,
    setAlarmDataAction,
    setVentilatorAlarmDataAction,
    setVentilatorHeartbeatDataAction,
    setNotificationDataAction,
    setWardDetailAction,
    // setDeviceWaveformDataAction,
    setDeviceHrWaveformDataAction,
    setDeviceEcgWaveformDataAction,
    setVentilatorVitalDataAction,
    setVentilatorModesDataAction,
    setVentilatorStandbyDataAction,
    // setVentilatorWaveformDataAction
} from '../../socket/socket';

import "./Homepage.scss";
import { connect } from 'react-redux';
import { SORT_TYPES, VIEW_TYPES, checkType, SIDEBAR_ICON } from '../../common/constant/common-constants';
// import SubHeader from '../../components/SubHeader/SubHeader';
import BedContainer from '../../container/BedContainer/BedContainer';
import PatientModal from '../../container/PatientModal/PatientModal';
import { getHospitalDetails } from '../../helper/home-page-helper';
import { PAGE_CONST } from '../../common/constant/page-constants';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { redirect } from '../../common/utils/RedirectionUtils';
import { deleteCookieCustom } from '../../common/utils/StorageUItils';
import { loginTrack } from '../../helper/login-helper';
// import PatientBook from '../PatientBook/PatientBook';


function HomePage(props) {
    // const [sortType, setSortType] = useState(SORT_TYPES.FAVORITE);
    const [modalConfig, setModalConfig] = useState(null);
    const [selectedWard, setSelectedWard] = useState("");
    const [wardOption, setWardOption] = useState({});
    const [signoutModal, setSignoutModal] = useState(false);
    const [hospitalName, setHospitalName] = useState("");
    // const [activeIcon, setActiveIcon] = useState(SIDEBAR_ICON.HOME_PAGE);

    const navigate = useNavigate();

    /**
         * 
         * Need to set all actions for data processing before opening the socket connection
         */
    // useEffect(() => {
    //     redirect(navigate, SIDEBAR_ICON.HOME_PAGE);
    // }, [])


    useEffect(() => {
        setSelectedWard(props.selectedWard);
        setActionForSocket();
        openSocketConnection(props.hospitalData);
        return () => {
            closeSocketConnection();
        }
    }, []);

    useEffect(() => {
        try {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.go(1);
            };
        } catch (err) {
            console.log("issue in blocking back navigation", err);
        }
    }, [])

    useEffect(() => {
        let wardOption = [];
        let tempWard = null;
        getHospitalDetails().then((res) => {
            setHospitalName(res.data.hospitalName);
            props.hospitalDataAction({ hospitalName: res.data.hospitalName, hospitalId: res.data.hospitalId, username: res.data.name });

            if (!res || !res.data && !res.data.wards) return;
            let beds = res.data.wards.map((ward) => {
                let bedIdList = ward.beds.map((bed) => {
                    let data = {
                        bedId: bed.id,
                        data: bed.patientDetails
                    }
                    let monitorAlarmData = {
                        bedId: bed.id,
                        alarms: bed.patientMonitorAlarmsDetails,
                    }
                    let ventilatorAlarmData = {
                        bedId: bed.id,
                        alarms: bed.ventilatorAlarmDetails,
                    }
                    let bedData = {
                        bedId: bed.id,
                        bedName: bed.name,
                        ventilatorId: bed?.ventilatorId,
                        patientMonitorId: bed?.patientMonitorId,
                    }
                    let ventilatorModes = {
                        bedId: bed.id,
                        modeName: bed?.ventilatorModeDetails?.[0]?.modeName,
                        patientType: bed?.ventilatorModeDetails?.[0]?.patientType
                    }

                    let ventilatorStandby = {
                        bedId: bed.id,
                        ventilationStatus: bed?.ventilationStatus
                    }

                    props.ventilatorModesDataAction(ventilatorModes);
                    props.ventilatorStandbyDataAction(ventilatorStandby);
                    props.bedDetailAction(bedData);
                    props.alarmDataAction(monitorAlarmData);
                    props.ventilatorAlarmDataAction(ventilatorAlarmData);
                    props.ventilatorHeartbeatAction(bed.id);
                    props.bedIdAction({bedId: bed.id});
                    props.patientDataAction(data);
                    return bed.id;
                })

                /** Ward Details */
                let wardData = {
                    id: ward.id,
                    data: {
                        id: ward.id,
                        wardName: ward.name,
                        bedList: bedIdList
                    }
                }
                props.wardDetailsAction(wardData);

                /** for dropdown */
                let wardObj = {
                    id: ward.id,
                    name: ward.name,
                    label: ward.name
                };
                if (!tempWard && !(props && props.selectedWard && props.selectedWard.id)) {
                    tempWard = wardObj;
                    handleWardChange(wardObj);
                    // if (!selectedWard.id) setSelectedWard(tempWard)
                }
                wardOption.push(wardObj);
            });
            setWardOption(wardOption);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {

        let mConfig = props.config.modalConfig;
        setModalConfig(mConfig);
        // props.viewTypeAction({ viewType: VIEW_TYPES.GRID_VIEW });

        // test();
    }, [props.config])

    const setActionForSocket = () => {
        setBedDetailsAction(props.bedDetailAction);
        setBedIdAction(props.bedIdAction);
        setPatientDataAction(props.patientDataAction);
        setVentilatorVitalDataAction(props.ventilatorVitalDataAction);
        setVentilatorModesDataAction(props.ventilatorModesDataAction);
        setVentilatorStandbyDataAction(props.ventilatorStandbyDataAction);
        // setVentilatorWaveformDataAction(props.ventilatorWaveformDataAction);
        setDeviceVitalDataAction(props.deviceVitalDataAction);
        setMonitorHeartbeatAction(props.monitorHeartbeatAction);
        setAlarmDataAction(props.alarmDataAction);
        setVentilatorAlarmDataAction(props.ventilatorAlarmDataAction);
        setVentilatorHeartbeatDataAction(props.ventilatorHeartbeatAction);
        setNotificationDataAction(props.notificationDataAction);
        setWardDetailAction(props.wardDetailAction);
        setDeviceEcgWaveformDataAction(props.deviceEcgWaveformDataAction);
        setDeviceHrWaveformDataAction(props.deviceHrWaveformDataAction);
    }

    const handleHelpClick = (event) => {
        // setSignoutModal(!signoutModal)
        if (!signoutModal) {
            event.stopPropagation();
            setSignoutModal(true);
            console.log("clicked signout in if");
            let element = document.getElementById('signout-modal-container');
            window.addEventListener('click', function (e) {
                if (element && element.contains(e.target)) {
                    // let crossIconElement = this.document.getElementById("cross-item-icon");
                    // if (crossIconElement && crossIconElement.contains(e.target)) {
                    //     setIsMoreItemsVisibe(false);
                    // }
                } else {
                    setSignoutModal(false);
                }
            });
            return;
        }
    }
    const handleSignoutCancel = () => {
        setSignoutModal(!signoutModal)
    }

    const handleSigoutClick = () => {

        // api call for logout track goes here
        let data = {
            type: checkType.LOGOUT,
            hospitalId: props.hospitalData.hospitalId,
            logoutDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            username: props.hospitalData.username
        }
        loginTrack(data).then((res) => {
            console.log("sucess!!!", res);
            deleteCookieCustom("accessToken");
            redirect(navigate, PAGE_CONST.LOGIN)
        }).catch((err) => {
            console.log("err in API call", err);
            deleteCookieCustom("accessToken");
            redirect(navigate, PAGE_CONST.LOGIN)
        })
    }
    const handleWardChange = (item) => {
        setSelectedWard(item);
        props.selectedWardAction(item);
    }

    const handleIconClick = (item) => {
        // setActiveIcon(item);
        props.sidebarActiveIconAction(item);
        redirect(navigate, item);
    }

    return (
        <>
            <div className={modalConfig && modalConfig.isOpen ? 'home-page blur' : 'home-page'}>
                {/* {console.log("rerendering HomePage!!!!")} */}
                <Header
                    handleWardChange={(item) => handleWardChange(item)}
                    wardOption={wardOption}
                    selectedWard={selectedWard}
                    hospitalName={hospitalName}
                    isDisabled={props.config.viewType === VIEW_TYPES.LIST_VIEW}
                />
                <div className='home-page-body-container'>
                    <SideBar handleHelpClick={handleHelpClick} handleIconClick={(item) => handleIconClick(item)} />
                    {props.config.activeSidebarIcon === SIDEBAR_ICON.HOME_PAGE && <BedContainer selectedWard={selectedWard} />}
                    {/* {props.config.activeSidebarIcon === SIDEBAR_ICON.DEVICE_MAPPER && <PatientBook selectedWard={selectedWard} />} */}
                    {/* {selectedWard && <BedContainer selectedWard={selectedWard} />} */}
                </div>
            </div>

            {signoutModal &&
                <div className='signout-modal-container'
                    id="signout-modal-container">
                    <div className='modal-content-container'>
                        <span className='signout-text'>Sign Out ?</span>
                        <span className='signout-confirm-text'>Are you sure you want to Sign Out?</span>
                        <span className='signout-final-statement'>
                            You can Sign In with the credentials provided to access the dashboard.
                        </span>
                        <div className='signout-btn-container'>
                            <div className='common-signout-btn' onClick={handleSignoutCancel}>Cancel</div>
                            <div className='common-signout-btn active-signout-btn' onClick={handleSigoutClick}>SignOut</div>
                        </div>
                    </div>
                </div>
            }


            {modalConfig && modalConfig.isOpen && <PatientModal optionType={modalConfig.optionType} bedId={modalConfig.bedId} />}
        </>
    );
}

const mapStateToProps = state => {
    return {
        // bedDetails: state.bedDetailsReducer.bedDetails,
        wardDetails: state.wardDetailsReducer.wardDetails,
        config: state.configReducer.config,
        hospitalData: state.hospitalDataReducer.hospitalData,
        selectedWard: state.wardDetailsReducer.selectedWard,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sidebarActiveIconAction: (payload) => dispatch(sidebarActiveIconAction(payload)),
        hospitalDataAction: (payload) => dispatch(hospitalDataAction(payload)),
        bedDetailAction: (payload) => dispatch(bedDetailAction(payload)),
        selectedWardAction: (payload) => dispatch(selectedWardAction(payload)),
        bedIdAction: (payload) => dispatch(bedIdAction(payload)),
        wardDetailsAction: (payload) => dispatch(wardDetailsAction(payload)),
        patientDataAction: (payload) => dispatch(patientDataAction(payload)),
        deviceVitalDataAction: (payload) => dispatch(deviceVitalDataAction(payload)),
        monitorHeartbeatAction: (payload) => dispatch(monitorHeartbeatAction(payload)),
        ventilatorVitalDataAction: (payload) => dispatch(ventilatorVitalDataAction(payload)),
        ventilatorModesDataAction: (payload) => dispatch(ventilatorModesDataAction(payload)),
        ventilatorStandbyDataAction: (payload) => dispatch(ventilatorStandbyDataAction(payload)),
        // ventilatorWaveformDataAction: (payload) => dispatch(ventilatorWaveformDataAction(payload)),
        deviceEcgWaveformDataAction: (payload) => dispatch(deviceEcgWaveformDataAction(payload)),
        deviceHrWaveformDataAction: (payload) => dispatch(deviceHrWaveformDataAction(payload)),
        alarmDataAction: (payload) => dispatch(alarmDataAction(payload)),
        ventilatorAlarmDataAction: (payload) => dispatch(ventilatorAlarmDataAction(payload)),
        ventilatorHeartbeatAction: (payload) => dispatch(ventilatorHeartbeatAction(payload)),
        notificationDataAction: (payload) => dispatch(notificationDataAction(payload)),
        filterAction: (payload) => dispatch(filterAction(payload)),
        addColumnBedListAction: (payload) => dispatch(addColumnBedListAction(payload)),
        removeColumnBedListAction: (payload) => dispatch(removeColumnBedListAction(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);