import React, { useEffect, useState } from "react";
import "./MonitorVitalData.scss";
import { ProgressBarCustom } from "../ProgressBarCustom/ProgressBarCustom";
import ChartCustom from "../Chart/Chart";
import { Line } from "react-chartjs-2";
import ApexChart from "../../components/Chart/ApexChart";
import whiteUpArrow from "../../common/icons/svg/white-up-arrow.svg";
import whiteDownArrow from "../../common/icons/svg/white-down-arrow.svg";
import { GRAPH_TYPE, VITAL_ECG, MORE_GRAPH_OPTIONS_VITAL, RESP_OBJ, PLETH_OBJ, ETCO2_OBJ, IBP1_OBJ, IBP2_OBJ } from "../../common/constant/common-constants";
import moment from "moment";
import LChart from "../Chart/LCReact";
import monitorOfflineIcon from "../../common/icons/svg/patient-monitor-offline.svg"
import { handleOutsideClick } from "../../common/utils/CommonUtils";

const dataArray = new Array(5000);
const currentTime = new Date();
// Start 10 seconds before the current time
currentTime.setSeconds(currentTime.getSeconds() - 10);

for (let i = 0; i < dataArray.length; i++) {
    const time = new Date(currentTime.getTime() + i * 2); // Increment by 2 milliseconds
    const formattedTime = time.toISOString(); // Format the time as per your requirement
    dataArray[i] = { x: formattedTime, y: NaN };
}
export function MonitorVitalData(props) {


    const TIME_RANGE_IN_MILLISECONDS = 6;
    const [barPercen, setBarPercen] = useState(0);
    const [dataObj, setDataObj] = useState(dataArray);
    const [secondEcgData, setSecondEcgData] = useState(dataArray);
    const [plethObj, setPlethObj] = useState(dataArray);
    const [respObj, setRespObj] = useState(dataArray);
    const [etco2Obj, setEtco2Obj] = useState(dataArray);
    const [ibp1Obj, setIbp1Obj] = useState(dataArray);
    const [ibp2Obj, setIbp2Obj] = useState(dataArray);
    const [vitalECG, setVitalECG] = useState(VITAL_ECG.I);
    const [moreVitalECG, setMoreVitalECG] = useState(false);
    const [secondMoreVitalECG, setSecondMoreVitalECG] = useState(false);
    const [vitalGraphName, setVitalGraphName] = useState(MORE_GRAPH_OPTIONS_VITAL.MONITOR[0]);
    const [secondvitalGraphName, setSecondVitalGraphName] = useState(MORE_GRAPH_OPTIONS_VITAL.MONITOR[1]);
    const [plethTime, setPlethTime] = useState(null);
    const [bedId, setBedId] = useState(null);
    const [lastPushedPlethTime, setLastPushedPlethTime] = useState(null);
    const [lastPushedRespTime, setLastPushedRespTime] = useState(null);
    const [lastPushedEcg1Time, setLastPushedEcg1Time] = useState(null);
    const [lastPushedEcg2Time, setLastPushedEcg2Time] = useState(null);
    const [lastPushedEtco2Time, setLastPushedEtco2Time] = useState(null);
    const [lastPushedIbp1Time, setLastPushedIbp1Time] = useState(null);
    const [lastPushedIbp2Time, setLastPushedIbp2Time] = useState(null);



    const options = (stepSize, plottedPoints, index) => {
        let maxY = plottedPoints[0]?.y;
    
        // Iterate through the array to find the maximum y value
        for (const point of plottedPoints) {
            if (!isNaN(point?.y) && (point?.y > maxY || isNaN(maxY))) {
                maxY = point?.y;
            }
        }
        return(
            {
                animation: {
                    duration: 0, // Set the animation duration to 0 to disable it
                },
                scales: {
                    x: {
                        ticks: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            display: false, // Display tick marks along the y-axis
                            stepSize: stepSize,
                        },
                        type: 'linear',
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Value'
                        },
                        min: null,
                        max: null, //Math.ceil((Math.max(maxY) + Math.max(maxY) * 25 / 100)/index) || 1,
                    }
                },
                plugins: {
                    legend: {
                        display: false, // Hide the legend
                    },
                    tooltip: {
                        enabled: false, // Disable tooltips
                    },
                    annotation: {
                        annotations: {
                            line: {
                                drawTime: 'afterDatasetsDraw',
                                type: 'line',
                                scaleID: 'y',
                                value: 0,
                                borderWidth: 2,
                            },
                        }
                    }
                },
                elements: {
                    line: {
                        tension: 0 // Disable bezier curves to get straight lines
                    }
                },
                maintainAspectRatio: false, // Set to false to allow direct control over width and height
                responsive: true,
            }
        )
    }

    useEffect(() => {

        if (bedId !== props.bedId) {
            setDataObj(dataArray);
            setSecondEcgData(dataArray);
            setPlethObj(dataArray);
            setRespObj(dataArray);
            setEtco2Obj(dataArray);
            setIbp1Obj(dataArray);
            setIbp2Obj(dataArray);

            setBedId(props.bedId);
        }
        // console.log("new bed Id !!!");
    }, [props.bedId])


    useEffect(() => {
        const processWaveformData = (waveformData, lastPushedTime, setLastPushedTime, setData) => {
            if (!waveformData || !waveformData.time || lastPushedTime === waveformData.time) {
                return;
            }
            setLastPushedTime(waveformData.time);
    
            const rawData = waveformData.data;
            const time = waveformData.time;
            const formattedData = rawData && rawData.split("^");
    
            let graphData = [];
            if (formattedData && formattedData.length > 0) {
                const gap = 1000 / formattedData.length;
                let currentMilli = 0;
    
                formattedData.forEach(val => {
                    const tempTime = moment(time).millisecond(currentMilli);
                    graphData.push({
                        x: moment(tempTime).toISOString(),
                        y: val
                    });
                    currentMilli += gap;
                });
            }
    
            // let dataList = graphData || [];
            // if (dataList && dataList.length > 3500) {
            //     dataList.splice(0, 600);
            // }
    
            setData((prevDataObj) => {
                const newDataList = [...prevDataObj, ...graphData];
                return newDataList.slice(-500 * TIME_RANGE_IN_MILLISECONDS);
            });
        };
    
        processWaveformData(
            props.monitorEcgWaveFormData?.waveform?.find(mvital => mvital.name && mvital.name.toUpperCase() === (vitalGraphName && vitalGraphName.name)),
            lastPushedEcg1Time,
            setLastPushedEcg1Time,
            setDataObj
        );
    
        processWaveformData(
            props.monitorEcgWaveFormData?.waveform?.find(mvital => mvital.name && mvital.name.toUpperCase() === (secondvitalGraphName && secondvitalGraphName.name)),
            lastPushedEcg2Time,
            setLastPushedEcg2Time,
            setSecondEcgData
        );
    }, [props.monitorEcgWaveFormData]);
    
    useEffect(() => {
        const processWaveformData = (vitalName, vitalObj, setVitalObj, lastPushedTime, setLastPushedTime, dataLimit) => {
            const waveformData = props.monitorHrWaveFormData &&
                props.monitorHrWaveFormData.waveform &&
                props.monitorHrWaveFormData.waveform.find(mvital =>
                    mvital.name && mvital.name.toUpperCase() === vitalName
                );
    
            if (waveformData && waveformData.time) {
                if (lastPushedTime === waveformData.time) {
                    return;
                } else {
                    setLastPushedTime(waveformData.time);
                }
    
                let rawData = waveformData.data;
                let time = waveformData.time;
                let formattedData = rawData && rawData.split("^");
                let graphData = [];
    
                if (formattedData && formattedData.length > 0) {
                    let gap = 1000 / formattedData.length;
                    let currentMilli = 0;
    
                    formattedData.forEach(val => {
                        let tempTime = moment(time).millisecond(currentMilli);
                        graphData.push({
                            x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                            y: val
                        });
                        currentMilli += gap;
                    });
                }
                setVitalObj(prevDataObj => {
                    const newDataList = [...prevDataObj, ...graphData];
                    return newDataList.slice(-dataLimit);
                });
            }
        };
    
        processWaveformData(PLETH_OBJ.name.toUpperCase(), plethObj, setPlethObj, lastPushedPlethTime, setLastPushedPlethTime, 60 * TIME_RANGE_IN_MILLISECONDS);
        processWaveformData(RESP_OBJ.name.toUpperCase(), respObj, setRespObj, lastPushedRespTime, setLastPushedRespTime, 250 * TIME_RANGE_IN_MILLISECONDS);
        processWaveformData(ETCO2_OBJ.name.toUpperCase(), etco2Obj, setEtco2Obj, lastPushedEtco2Time, setLastPushedEtco2Time, 100 * TIME_RANGE_IN_MILLISECONDS);
        processWaveformData(IBP1_OBJ.name.toUpperCase(), ibp1Obj, setIbp1Obj, lastPushedIbp1Time, setLastPushedIbp1Time, 125 * TIME_RANGE_IN_MILLISECONDS);
        processWaveformData(IBP2_OBJ.name.toUpperCase(), ibp2Obj, setIbp2Obj, lastPushedIbp2Time, setLastPushedIbp2Time, 125 * TIME_RANGE_IN_MILLISECONDS);
    
    }, [props.monitorHrWaveFormData]);
    

    // useEffect(() => {
    //     let plethDataList;
    //     let plethWaveformData = props.monitorHrWaveFormData
    //         && props.monitorHrWaveFormData.waveform
    //         && props.monitorHrWaveFormData.waveform.filter(
    //             (mvital) => {
    //                 return mvital.name && mvital.name.toUpperCase() === (PLETH_OBJ.name)
    //             }
    //         )[0];

    //     if (plethWaveformData && plethWaveformData.time) {
    //         if (lastPushedPlethTime ===
    //             plethWaveformData.time) {
    //             // console.log("last pushed pleth time  is same", plethWaveformData.time, lastPushedPlethTime);
    //             return;
    //         } else {

    //             // console.log("pushing pleth for time :: ", lastPushedEcg1Time);
    //             setLastPushedPlethTime(plethWaveformData.time);
    //         }

    //         let rawData = plethWaveformData.data;
    //         let time = plethWaveformData.time;
    //         let formattedData = rawData && rawData.split("^");
    //         // console.log("formatted time: ", time, " formattedData: ", formattedData);
    //         let plethGraphData = [];

    //         if (formattedData && formattedData.length > 0) {
    //             let gap = 1000 / formattedData.length;
    //             let currentMilli = 0;

    //             formattedData.map((val) => {
    //                 let tempTime = moment(time).millisecond(currentMilli);
    //                 plethGraphData.push({
    //                     x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
    //                     y: val
    //                 });
    //                 currentMilli = currentMilli + gap;
    //             })
    //         }
    //         setPlethObj((prevDataObj) => {
    //             const newDataList = [...prevDataObj, ...plethGraphData];
    //             return newDataList.slice(-450);
    //         });

    //         // plethDataList = (plethObj && plethObj.concat(plethGraphData)) || [];
    //         // if (plethDataList && plethDataList.length > 3500) {
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deleting data !!", ecg1DataList.length)
    //         //     // }
    //         //     plethDataList.splice(0, 600);
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deletedddddd data !!", ecg1DataList.length)
    //         //     // }
    //         // }

    //         // setPlethObj(plethDataList);
    //     }

    //     /** resp graph data !!! */
    //     let respDataList;
    //     let respWaveformData = props.monitorHrWaveFormData
    //         && props.monitorHrWaveFormData.waveform
    //         && props.monitorHrWaveFormData.waveform.filter(
    //             (mvital) => {
    //                 return mvital.name && mvital.name.toUpperCase() === (RESP_OBJ.name)
    //             }
    //         )[0];

    //     if (respWaveformData && respWaveformData.time) {
    //         if (lastPushedRespTime ===
    //             respWaveformData.time) {
    //             // console.log("last pushed resp time  is same");
    //             return;
    //         } else {
    //             setLastPushedRespTime(respWaveformData.time);
    //         }

    //         let rawData = respWaveformData.data;
    //         let time = respWaveformData.time;
    //         let formattedData = rawData && rawData.split("^");
    //         let respGraphData = [];

    //         if (formattedData && formattedData.length > 0) {
    //             let gap = 1000 / formattedData.length;
    //             let currentMilli = 0;

    //             formattedData.map((val) => {
    //                 let tempTime = moment(time).millisecond(currentMilli);
    //                 respGraphData.push({
    //                     x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
    //                     y: val
    //                 });
    //                 currentMilli = currentMilli + gap;
    //             })
    //         }
    //         setRespObj((prevDataObj) => {
    //             const newDataList = [...prevDataObj, ...respGraphData];
    //             return newDataList.slice(-1500);
    //         });
    //         // respDataList = (respObj && respObj.concat(respGraphData)) || [];
    //         // if (respDataList && respDataList.length > 3500) {
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deleting data !!", ecg1DataList.length)
    //         //     // }
    //         //     respDataList.splice(-5000);
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deletedddddd data !!", ecg1DataList.length)
    //         //     // }
    //         // }
    //         // setRespObj(respDataList);
    //     }
    //     // etco2 graph data
    //     let etco2DataList;
    //     let etco2WaveformData = props.monitorHrWaveFormData
    //         && props.monitorHrWaveFormData.waveform
    //         && props.monitorHrWaveFormData.waveform.filter(
    //             (mvital) => {
    //                 return mvital.name && mvital.name.toUpperCase() === (ETCO2_OBJ.name)
    //             }
    //         )[0];

    //     if (etco2WaveformData && etco2WaveformData.time) {
    //         if (lastPushedEtco2Time ===
    //             etco2WaveformData.time) {
    //             // console.log("last pushed resp time  is same");
    //             return;
    //         } else {
    //             setLastPushedEtco2Time(etco2WaveformData.time);
    //         }

    //         let rawData = etco2WaveformData.data;
    //         let time = etco2WaveformData.time;
    //         let formattedData = rawData && rawData.split("^");
    //         // console.log("formatted time: ", time, " formattedData: ", formattedData);
    //         let etco2GraphData = [];

    //         if (formattedData && formattedData.length > 0) {
    //             let gap = 1000 / formattedData.length;
    //             let currentMilli = 0;

    //             formattedData.map((val) => {
    //                 let tempTime = moment(time).millisecond(currentMilli);
    //                 etco2GraphData.push({
    //                     x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
    //                     y: val
    //                 });
    //                 currentMilli = currentMilli + gap;
    //             })
    //         }
    //         setEtco2Obj((prevDataObj) => {
    //             const newDataList = [...prevDataObj, ...etco2GraphData];
    //             return newDataList.slice(-900);
    //         });
    //         // etco2DataList = (etco2Obj && etco2Obj.concat(etco2GraphData)) || [];
    //         // if (etco2DataList && etco2DataList.length > 3500) {
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deleting data !!", ecg1DataList.length)
    //         //     // }
    //         //     etco2DataList.splice(-600);
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deletedddddd data !!", ecg1DataList.length)
    //         //     // }
    //         // }

    //         // setEtco2Obj(etco2DataList);
    //     }

    //     // ibp1 graph data
    //     let ibp1DataList;
    //     let ibp1WaveformData = props.monitorHrWaveFormData
    //         && props.monitorHrWaveFormData.waveform
    //         && props.monitorHrWaveFormData.waveform.filter(
    //             (mvital) => {
    //                 return mvital.name && mvital.name.toUpperCase() === (IBP1_OBJ.name)
    //             }
    //         )[0];

    //     if (ibp1WaveformData && ibp1WaveformData.time) {
    //         if (lastPushedIbp1Time ===
    //             ibp1WaveformData.time) {
    //             // console.log("last pushed resp time  is same");
    //             return;
    //         } else {
    //             setLastPushedIbp1Time(ibp1WaveformData.time);
    //         }

    //         let rawData = ibp1WaveformData.data;
    //         let time = ibp1WaveformData.time;
    //         let formattedData = rawData && rawData.split("^");
    //         // console.log("formatted time: ", time, " formattedData: ", formattedData);
    //         let ibp1GraphData = [];

    //         if (formattedData && formattedData.length > 0) {
    //             let gap = 1000 / formattedData.length;
    //             let currentMilli = 0;

    //             formattedData.map((val) => {
    //                 let tempTime = moment(time).millisecond(currentMilli);
    //                 ibp1GraphData.push({
    //                     x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
    //                     y: val
    //                 });
    //                 currentMilli = currentMilli + gap;
    //             })
    //         }
    //         setIbp1Obj((prevDataObj) => {
    //             const newDataList = [...prevDataObj, ...ibp1GraphData];
    //             return newDataList.slice(-500);
    //         });
    //         // ibp1DataList = (ibp1Obj && ibp1Obj.concat(ibp1GraphData)) || [];
    //         // if (ibp1DataList && ibp1DataList.length > 3500) {
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deleting data !!", ecg1DataList.length)
    //         //     // }
    //         //     ibp1DataList.splice(0, 600);
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deletedddddd data !!", ecg1DataList.length)
    //         //     // }
    //         // }

    //         // setIbp1Obj(ibp1DataList);
    //     }

    //     // ibp2 graph data
    //     let ibp2DataList;
    //     let ibp2WaveformData = props.monitorHrWaveFormData
    //         && props.monitorHrWaveFormData.waveform
    //         && props.monitorHrWaveFormData.waveform.filter(
    //             (mvital) => {
    //                 return mvital.name && mvital.name.toUpperCase() === (IBP2_OBJ.name)
    //             }
    //         )[0];

    //     if (ibp2WaveformData && ibp2WaveformData.time) {
    //         if (lastPushedIbp2Time ===
    //             ibp2WaveformData.time) {
    //             // console.log("last pushed resp time  is same");
    //             return;
    //         } else {
    //             setLastPushedIbp2Time(ibp2WaveformData.time);
    //         }

    //         let rawData = ibp2WaveformData.data;
    //         let time = ibp2WaveformData.time;
    //         let formattedData = rawData && rawData.split("^");
    //         // console.log("formatted time: ", time, " formattedData: ", formattedData);
    //         let ibp2GraphData = [];

    //         if (formattedData && formattedData.length > 0) {
    //             let gap = 1000 / formattedData.length;
    //             let currentMilli = 0;

    //             formattedData.map((val) => {
    //                 let tempTime = moment(time).millisecond(currentMilli);
    //                 ibp2GraphData.push({
    //                     x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
    //                     y: val
    //                 });
    //                 currentMilli = currentMilli + gap;
    //             })
    //         }
    //         setIbp2Obj((prevDataObj) => {
    //             const newDataList = [...prevDataObj, ...ibp2GraphData];
    //             return newDataList.slice(-500);
    //         });

    //         // ibp2DataList = (ibp2Obj && ibp2Obj.concat(ibp2GraphData)) || [];
    //         // if (ibp2DataList && ibp2DataList.length > 3500) {
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deleting data !!", ecg1DataList.length)
    //         //     // }
    //         //     ibp2DataList.splice(0, 600);
    //         //     // if (props.bedId === 5) {
    //         //     //     console.log("deletedddddd data !!", ecg1DataList.length)
    //         //     // }
    //         // }

    //         // setIbp2Obj(ibp2DataList);
    //     }


    // }, [props.monitorHrWaveFormData])

    const handleVitalECGSelect = (option) => {
        // setVitalECG(option)
        setVitalGraphName(option);
        setMoreVitalECG(false);
    }

    const handleSecondVitalECGSelect = (option) => {
        // setVitalECG(option)
        setSecondVitalGraphName(option);
        setSecondMoreVitalECG(false);
    }

    const handleMoreVitalECG = (event) => {
        if (!moreVitalECG) {
            handleOutsideClick(event, 'vital-ecg-graph-1', setMoreVitalECG);
        }

    }

    const handleSecondMoreVitalECG = (event) => {
        if (!secondMoreVitalECG) {
            handleOutsideClick(event, 'vital-ecg-graph-2', setSecondMoreVitalECG);
        }
    }

    if (props?.monitorHeartBeatData?.isConnected) {
        return (
            <div className="vitals-container">
                <div className="graph-data">
                    <div className="graph-vital-wrap">
                        <div className="graph-container">
                            <div className="select-vital-ecg-container" onClick={(event) => handleMoreVitalECG(event)}>
                                <div className="selected-vital-ecg-name">
                                    {vitalGraphName.label}
                                </div>
                                {moreVitalECG && <img src={whiteDownArrow} />}
                                {!moreVitalECG && <img src={whiteUpArrow} />}
                                {moreVitalECG && <div className="vital-graph-options" id="vital-ecg-graph-1">
                                    {
                                        MORE_GRAPH_OPTIONS_VITAL.MONITOR.map((option) => {
                                            return (
                                                <div className={option.name === vitalGraphName.name ? `common-vital-ecg vital-ecg-active` : `common-vital-ecg`}
                                                    onClick={(event) => handleVitalECGSelect(option)} key={option.name}>{option.label}</div>
                                            )
                                        })
                                    }
                                </div>}
                            </div>
                            <div className="ecg-graph-1">
                                {dataObj && 
                                    <Line 
                                        data={{
                                            labels: dataObj.map(dataPoint => dataPoint.x),
                                            datasets: [{
                                                label: 'ECG Graph I',
                                                data: dataObj.map(dataPoint => dataPoint.y/100),
                                                borderColor: '#299842',
                                                fill: false,
                                                pointRadius: 0,
                                                tension: 0.1,
                                                borderWidth: 2
                                            }]
                                        }}
                                        options={options(10, dataObj, 100)}
                                    />
                                }
                            </div>
                            <div className="select-vital-ecg-container" onClick={(event) => handleSecondMoreVitalECG(event)}>
                                <div className="selected-vital-ecg-name">
                                    {secondvitalGraphName.label}
                                </div>
                                {secondMoreVitalECG && <img src={whiteDownArrow} />}
                                {!secondMoreVitalECG && <img src={whiteUpArrow} />}
                                {secondMoreVitalECG && <div className="vital-graph-options" id="vital-ecg-graph-2">
                                    {
                                        MORE_GRAPH_OPTIONS_VITAL.MONITOR.map((option) => {
                                            return (
                                                <div className={option.name === secondvitalGraphName.name ? `common-vital-ecg vital-ecg-active` : `common-vital-ecg`}
                                                    onClick={(event) => handleSecondVitalECGSelect(option)} key={option.name}>{option.label}</div>
                                            )
                                        })
                                    }
                                </div>}
                            </div>
                            <div className="ecg-graph-2">
                                {secondEcgData && 
                                    <Line 
                                        data={{
                                            labels: secondEcgData.map(dataPoint => dataPoint.x),
                                            datasets: [{
                                                label: 'ECG Graph II',
                                                data: secondEcgData.map(dataPoint => dataPoint.y / 100),
                                                borderColor: '#299842',
                                                fill: false,
                                                pointRadius: 0,
                                                tension: 0.1,
                                                borderWidth: 2
                                            }]
                                        }}
                                        options={options(1, secondEcgData, 100)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="data-container">
                            <div className="vital-data ecg">
                                <div className="vital-name-container">
                                    <div className="vital-name">
                                        ECG
                                    </div>
                                    <div className="vital-unit">
                                        {/* {(props.data.ecg && props.data.ecg.unit) || 'b/min'} */}
                                        b/min
                                    </div>
                                </div>
                                <div className="vital-value-container">
                                    {
                                        /** filter ecg */
                                        props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "hr")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "hr"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "hr"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "hr"))[0].data[0].value || "-"

                                    }
                                </div>
                                <div className="vital-lb-container">
                                    <div className="vital-lb-high">
                                        {/* {(props.data.ecg && props.data.ecg.conf && props.data.ecg.conf.high) || '160'} */}
                                        {/* 160 */}
                                    </div>
                                    <div className="vital-lb-low">
                                        {/* {props.data.ecg && props.data.ecg.conf && props.data.ecg.conf.low || '60'} */}
                                        {/* 60 */}
                                    </div>
                                </div>
                            </div>
                            <div className="vital-ecg-option ecg-option-height">
                                <div className="ecg-param-col">
                                    <div className="ecg-entry">
                                        <div className="ecg-param-name">I</div>
                                        <div className="ecg-param-val">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt1")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt1"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt1"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt1"))[0].data[0].value) || "-"

                                            }
                                        </div>
                                    </div>
                                    <div className="ecg-entry">
                                        <div className="ecg-param-name">II</div>
                                        <div className="ecg-param-val">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt2")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt2"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt2"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt2"))[0].data[0].value) || "-"


                                            }
                                        </div>
                                    </div>
                                    <div className="ecg-entry">
                                        <div className="ecg-param-name">III</div>
                                        <div className="ecg-param-val">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt3")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt3"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt3"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt3"))[0].data[0].value) || "-"

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="ecg-param-col">
                                    <div className="ecg-entry">
                                        <div className="ecg-param-name">AVR</div>
                                        <div className="ecg-param-val">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgAvr")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgAvr"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgAvr"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgAvr"))[0].data[0].value) || "-"

                                            }
                                        </div>
                                    </div>
                                    <div className="ecg-entry">
                                        <div className="ecg-param-name">AVL</div>
                                        <div className="ecg-param-val">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgAvl")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgAvl"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgAvl"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgAvl"))[0].data[0].value) || "-"

                                            }
                                        </div>
                                    </div>
                                    <div className="ecg-entry">
                                        <div className="ecg-param-name">AVF</div>
                                        <div className="ecg-param-val">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgAvf")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgAvf"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgAvf"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgAvf"))[0].data[0].value) || "-"

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="ecg-param-col">
                                    <div className="ecg-entry">
                                        <div className="ecg-param-name">V1</div>
                                        <div className="ecg-param-val">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt5")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt5"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ecgSt5"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ecgSt5"))[0].data[0].value) || "-"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {respObj && 
                        <div className="graph-vital-wrap">
                            <div className="graph-container">
                                <div className="resp-graph">
                                    <div style={{fontFamily: "Noto Sans", textAnchor: 'start', dominantBaseline: 'auto', fontSize: '0.833vw', fontWeight: 400, color: 'rgb(252, 195, 49)'}} >RR</div>
                                    <Line 
                                        data={{
                                            labels: respObj.map(dataPoint => dataPoint.x),
                                            datasets: [{
                                                label: 'RR',
                                                data: respObj.map(dataPoint => dataPoint.y / 100),
                                                borderColor: '#FFED47',
                                                fill: false,
                                                pointRadius: 0,
                                                tension: 0.1,
                                                borderWidth: 2
                                            }]
                                        }}
                                        options={options(1, respObj, 100)}
                                    />
                                </div>
                            </div>
                            <div className="data-container">
                                <div className="vital-data resp">
                                    <div className="vital-name-container">
                                        <div className="vital-name">
                                            RR
                                        </div>
                                        <div className="vital-unit">
                                            {/* {(props.data.resp && props.data.resp.unit) || 'b/min'} */}
                                            b/min
                                        </div>
                                    </div>
                                    <div className="vital-value-container">
                                        {
                                            /** filter ecg */
                                            (props.vitalData &&
                                                (props.vitalData.filter((vital) => (vital.name === "rr")).length > 0) &&
                                                props.vitalData.filter((vital) => (vital.name === "rr"))[0] &&
                                                (props.vitalData.filter((vital) => (vital.name === "rr"))[0].data.length > 0) &&
                                                props.vitalData.filter((vital) => (vital.name === "rr"))[0].data[0].value) || "-"

                                        }
                                        {/* {(props.data.resp && props.data.resp.data && props.data.resp.data.value) || '61'} */}

                                    </div>
                                    <div className="vital-lb-container">
                                        <div className="vital-lb-high">
                                            {/* {(props.data.resp && props.data.resp.conf && props.data.spo2.resp.high) || '100'} */}
                                            {/* 100 */}
                                        </div>
                                        <div className="vital-lb-low">
                                            {/* {(props.data.resp && props.data.resp.conf && props.data.resp.conf.low) || '40'} */}
                                            {/* 40 */}
                                        </div>
                                    </div>
                                    <div className="vital-rb-container">
                                        <div className="rb-vital-name source-ecg">
                                            {/* {(props.data.resp && props.data.resp.source) || 'SOURCE ECG'} */}
                                            SOURCE ECG
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {plethObj && 
                        <div className="graph-vital-wrap">
                            <div className="graph-container">
                                <div className="resp-graph">
                                        <>
                                            <div style={{fontFamily: "Noto Sans", textAnchor: 'start', dominantBaseline: 'auto', fontSize: '0.833vw', fontWeight: 400, color: 'rgb(58, 218, 229)'}} >Pleth</div>
                                            <Line 
                                                data={{
                                                    labels: plethObj.map(dataPoint => dataPoint.x),
                                                    datasets: [{
                                                        label: 'Pleth',
                                                        data: plethObj.map(dataPoint => dataPoint.y / 100),
                                                        borderColor: '#20BBC1',
                                                        fill: false,
                                                        pointRadius: 0,
                                                        tension: 0.1,
                                                        borderWidth: 2
                                                    }]
                                                }}
                                                options={options(1, plethObj, 100)}
                                            />
                                        </>
                                </div>
                            </div>
                            <div className="data-container">
                                <div className="vital-data spo2">
                                    <div className="vital-name-container">
                                        <div className="vital-name">
                                            SPO2
                                        </div>
                                    </div>
                                    <div className="vital-value-container">
                                        {/* {(props.data.spo2 && props.data.spo2.data && props.data.spo2.data.value) || '98'} */}
                                        {
                                            /** filter ecg */
                                            (props.vitalData &&
                                                (props.vitalData.filter((vital) => (vital.name === "spo2")).length > 0) &&
                                                props.vitalData.filter((vital) => (vital.name === "spo2"))[0] &&
                                                (props.vitalData.filter((vital) => (vital.name === "spo2"))[0].data.length > 0) &&
                                                props.vitalData.filter((vital) => (vital.name === "spo2"))[0].data[0].value) || "-"

                                        }
                                    </div>
                                    <div className="vital-lb-container">
                                        <div className="vital-lb-high">
                                            {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.high) || '100'} */}
                                            {/* 100 */}
                                        </div>
                                        <div className="vital-lb-low">
                                            {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.low) || '90'} */}
                                            {/* 90 */}
                                        </div>
                                    </div>
                                    <div className="vital-rb-container">
                                        <div className="rb-vital-name">
                                            PR
                                        </div>
                                        <div className="rb-vital-value">
                                            {
                                                /** filter ecg */
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "pr")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "pr"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "pr"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "pr"))[0].data[0].value) || "-"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {etco2Obj?.length && etco2Obj[etco2Obj.length - 1].y ?
                        <div className="graph-vital-wrap">
                            <div className="graph-container">
                                <div className="etco2-graph">
                                    <>
                                        <div style={{fontFamily: "Noto Sans", textAnchor: 'start', dominantBaseline: 'auto', fontSize: '0.833vw', fontWeight: 400, color: 'rgb(109, 29, 240)'}}>EtCO<sub>2</sub></div>
                                        <Line 
                                            data={{
                                                labels: etco2Obj.map(dataPoint => dataPoint.x),
                                                datasets: [{
                                                    label: 'EtCO2',
                                                    data: etco2Obj.map(dataPoint => dataPoint.y / 100),
                                                    borderColor: '#6D1DF0',
                                                    fill: false,
                                                    pointRadius: 0,
                                                    tension: 0.1,
                                                    borderWidth: 2
                                                }]
                                            }}
                                            options={options(1, etco2Obj, 100)}
                                        />
                                    </>
                                </div>
                            </div>
                            <div className="data-container">
                                    <div className="vital-data etco2">
                                        <div className="vital-name-container">
                                            <div className="vital-name">
                                                EtCO₂
                                            </div>
                                            <div className="vital-unit">
                                                {/* {(props.data.resp && props.data.resp.unit) || 'b/min'} */}
                                                b/min
                                            </div>
                                        </div>
                                        <div className="vital-value-container">
                                            {/* {(props.data.spo2 && props.data.spo2.data && props.data.spo2.data.value) || '98'} */}
                                            {
                                                (props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "endTidalCo2")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "endTidalCo2"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "endTidalCo2"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "endTidalCo2"))[0].data[0].value) || "-"
                                            }
                                        </div>
                                        <div className="vital-lb-container">
                                            <div className="vital-lb-high">
                                                {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.high) || '100'} */}
                                                {/* 100 */}
                                            </div>
                                            <div className="vital-lb-low">
                                                {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.low) || '90'} */}
                                                {/* 90 */}
                                            </div>
                                        </div>
                                        <div className="vital-rt-container">
                                            <div className="rt-vital-name">
                                                FiCO₂
                                            </div>
                                            <div className="rt-vital-value">
                                                {
                                                    (props.vitalData &&
                                                        (props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2")).length > 0) &&
                                                        props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2"))[0] &&
                                                        (props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2"))[0].data.length > 0) &&
                                                        props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2"))[0].data[0].value) || "-"
                                                }
                                            </div>
                                        </div>
                                        <div className="vital-rb-container">
                                            <div className="rb-vital-name">
                                                awRR
                                            </div>
                                            <div className="rb-vital-value">
                                                {
                                                    (props.vitalData &&
                                                        (props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate")).length > 0) &&
                                                        props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate"))[0] &&
                                                        (props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate"))[0].data.length > 0) &&
                                                        props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate"))[0].data[0].value) || "-"

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    :
                        <></>
                    }
                    {ibp1Obj?.length && ibp1Obj[ibp1Obj.length - 1].y ?
                        <div className="graph-vital-wrap">
                            <div className="graph-container">
                                <div className="ibp1-graph">
                                    <div style={{fontFamily: "Noto Sans", textAnchor: 'start', dominantBaseline: 'auto', fontSize: '0.833vw', fontWeight: 400, color: 'rgb(240, 29, 206)'}}>{`IBP 1 ${props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ibp1Label")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ibp1Label"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ibp1Label"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ibp1Label"))[0].data[0].value || ""}`}
                                    </div>
                                    <Line 
                                        data={{
                                            labels: ibp1Obj.map(dataPoint => dataPoint.x),
                                            datasets: [{
                                                label: 'IBP 1',
                                                data: ibp1Obj.map(dataPoint => dataPoint.y / 100),
                                                borderColor: '#F01DCE',
                                                fill: false,
                                                pointRadius: 0,
                                                tension: 0.1,
                                                borderWidth: 2
                                            }]
                                        }}
                                        options={options(1, ibp1Obj, 100)}
                                    />
                                </div>
                            </div>
                            <div className="data-container">
                                <div className="vital-data ibp1">
                                    <div className="vital-name-container">
                                        <div className="vital-name">
                                            IBP1
                                        </div>
                                    </div>
                                    <div className="vital-value-container ibp1-val-main">
                                        {
                                            /** filter ecg */
                                            props.vitalData &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp1Systolic")).length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp1Systolic"))[0] &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp1Systolic"))[0].data.length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp1Systolic"))[0].data[0].value || "-"
                                        }
                                        /
                                        {
                                            props.vitalData &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic")).length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic"))[0] &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic"))[0].data.length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic"))[0].data[0].value || "-"
                                        }
                                        {/* {(props.data.resp && props.data.resp.data && props.data.resp.data.value) || '61'} */}

                                    </div>
                                    <div className="vital-lb-container">
                                        <div className="vital-lb-high">
                                            {/* {(props.data.resp && props.data.resp.conf && props.data.spo2.resp.high) || '100'} */}
                                            {/* 100 */}
                                        </div>
                                        <div className="vital-lb-low">
                                            {/* {(props.data.resp && props.data.resp.conf && props.data.resp.conf.low) || '40'} */}
                                            {/* 40 */}
                                        </div>
                                    </div>
                                    <div className="vital-rb-container">
                                        <div className="rb-vital-name">
                                            {/* {(props.data.resp && props.data.resp.source) || 'SOURCE ECG'} */}
                                            Mean
                                            <div className="mean-ibp">
                                                {
                                                    props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ibp1Mean")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ibp1Mean"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ibp1Mean"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ibp1Mean"))[0].data[0].value || " -"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <></>
                    }

                    {ibp2Obj && ibp2Obj.length ?
                        <div className="graph-vital-wrap">
                            <div className="graph-container">
                                <div className="ibp2-graph">
                                    <div style={{fontFamily: "Noto Sans", textAnchor: 'start', dominantBaseline: 'auto', fontSize: '0.833vw', fontWeight: 400, color: 'rgb(240, 29, 206)'}}>
                                        {`IBP 2 ${props.vitalData &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp2Label")).length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp2Label"))[0] &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp2Label"))[0].data.length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp2Label"))[0].data[0].value || ""}`
                                        }
                                    </div>
                                    <Line 
                                        data={{
                                            labels: ibp2Obj.map(dataPoint => dataPoint.x),
                                            datasets: [{
                                                label: 'IBP 2',
                                                data: ibp2Obj.map(dataPoint => dataPoint.y / 100),
                                                borderColor: '#F01DCE',
                                                fill: false,
                                                pointRadius: 0,
                                                tension: 0.1,
                                                borderWidth: 2
                                            }]
                                        }}
                                        options={options(1, ibp2Obj, 100)}
                                    />
                                </div>
                            </div>
                            <div className="data-container">
                                <div className="vital-data ibp2">
                                    <div className="vital-name-container">
                                        <div className="vital-name">
                                            IBP2
                                        </div>
                                    </div>
                                    <div className="vital-value-container ibp2-val-main">
                                        {
                                            props.vitalData &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp2Systolic")).length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp2Systolic"))[0] &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp2Systolic"))[0].data.length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp2Systolic"))[0].data[0].value || "-"
                                        }/
                                        {
                                            props.vitalData &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic")).length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic"))[0] &&
                                            (props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic"))[0].data.length > 0) &&
                                            props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic"))[0].data[0].value || "-"

                                        }
                                        {/* {(props.data.resp && props.data.resp.data && props.data.resp.data.value) || '61'} */}

                                    </div>
                                    <div className="vital-lb-container">
                                        <div className="vital-lb-high">
                                            {/* {(props.data.resp && props.data.resp.conf && props.data.spo2.resp.high) || '100'} */}
                                            {/* 100 */}
                                        </div>
                                        <div className="vital-lb-low">
                                            {/* {(props.data.resp && props.data.resp.conf && props.data.resp.conf.low) || '40'} */}
                                            {/* 40 */}
                                        </div>
                                    </div>
                                    <div className="vital-rb-container">
                                        <div className="rb-vital-name ">
                                            {/* {(props.data.resp && props.data.resp.source) || 'SOURCE ECG'} */}
                                            Mean
                                            <div className="mean-ibp">
                                                {
                                                    props.vitalData &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ibp2Mean")).length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ibp2Mean"))[0] &&
                                                    (props.vitalData.filter((vital) => (vital.name === "ibp2Mean"))[0].data.length > 0) &&
                                                    props.vitalData.filter((vital) => (vital.name === "ibp2Mean"))[0].data[0].value || " -"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <></>
                    }
                </div>
                <div className="footer-wrap">
                    <div className="temp-container">
                        <div className="temp-name-container">
                            <div className="temp-name">
                                Temp
                            </div>
                            <div className="temp-name">
                                &#x2103;
                            </div>
                        </div>
                        <div className="temp-hl-container">
                            <div className="temp-high">
                                {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.high) || '120'} */}
                                {/* 120 */}
                            </div>
                            <div className="temp-low">
                                {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.low) || '70'} */}
                                {/* 70 */}
                            </div>
                        </div>
                        <div className="temp-t1-container">
                            <div className="temp-name">
                                {`T1`}
                            </div>
                            <div className="temp-value">
                                {/* {(props.data.temp && props.data.temp.data && props.data.temp.data.T1_X5) || '32.4'} */}
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "temperature1")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "temperature1"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "temperature1"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "temperature1"))[0].data[0].value) || "-"

                                }
                            </div>
                        </div>
                        <div className="temp-t2-container">
                            <div className="temp-name">
                                {`T2`}
                            </div>
                            <div className="temp-value">
                                {/* {(props.data.temp && props.data.temp.data && props.data.temp.data.T1_X6) || '33.4'} */}
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "temperature2")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "temperature2"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "temperature2"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "temperature2"))[0].data[0].value) || "-"
                                }
                            </div>
                        </div>
                        <div className="temp-td-container">
                            <div className="temp-name">
                                {`TD`}
                            </div>
                            <div className="temp-value">
                                {/* {(props.data.temp && props.data.temp.data && props.data.temp.data.TD) || '34.4'} */}
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "temperatureDelta")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "temperatureDelta"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "temperatureDelta"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "temperatureDelta"))[0].data[0].value) || "-"
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bp-container">
                        {/* <div className="progress-bar-container">
                            <div className="nibp-name-container">
                                <div className="nibp-name">
                                    NIBP
                                </div>
                                <div className="nibp-unit">
                                    mmHg
                                </div>
                            </div>
                            <div className="progress-bar">
                                <ProgressBarCustom
                                    completed={80}
                                    isLabelVisible={true}
                                    labelLeft={'@18.35'}
                                    labelRight={'15 min'}
                                />
                            </div>
                        </div> */}

                        <div className="nibp-name-container">
                            <div className="nibp-name">
                                NIBP
                            </div>
                            <div className="nibp-unit">
                                mmHg
                            </div>
                        </div>
                        <div className="nibp-hl-container">
                            <div className="nibp-high">
                                {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.high) || '120'} */}
                                {/* 120 */}
                            </div>
                            <div className="nibp-low">
                                {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.low) || '70'} */}
                                {/* 70 */}
                            </div>
                        </div>
                        <div className="nibp-data-container">
                            <div className="nibp-data">
                                {/* {(props.data.nibp && props.data.nibp.data && props.data.nibp.data.high) || '112'}/{(props.data.nibp && props.data.nibp.data && props.data.nibp.data.low) || '88'} */}
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "bp")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "bp"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "bp"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "bp"))[0].data[0].value) || "-"
                                }
                            </div>
                        </div>
                        <div className="nibp-avg-data">
                            {/* ({(props.data.nibp && props.data.nibp.data && props.data.nibp.data.avg) || '93'}) */}
                            {
                                /** filter ecg */
                                (props.vitalData &&
                                    (props.vitalData.filter((vital) => (vital.name === "bpMean")).length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "bpMean"))[0] &&
                                    (props.vitalData.filter((vital) => (vital.name === "bpMean"))[0].data.length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "bpMean"))[0].data[0].value)
                                || "--"
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='offline'>
                <img className='monitor-offline' src={monitorOfflineIcon} alt="Monitor Offline" />
                <h2 className='not-in-use-text'>Monitor is currently offline, possibly due to settings or poor internet connection; please check and ensure proper configuration for seamless monitoring.</h2>
            </div>
        )
    }
}
