import './App.css';
import React, {useEffect} from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './modules/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import {routes} from './common/constant/routes-constants';

import "./common/scss/common.scss";
// import { closeSocketConnection, openSocketConnection } from './socket/socket';

function App(props) {

    // useEffect(() => {
    //     openSocketConnection();
    //     return () => {
    //         console.log("in return effect app");
    //         closeSocketConnection();
    //     }
    // },[]);
    
        useEffect(() => {
          let wakeLock = null;
      
          const requestWakeLock = async () => {
            try {
              wakeLock = await navigator.wakeLock.request('screen');
            } catch (err) {
              console.error('Unable to request wake lock:', err);
            }
          };
      
          const releaseWakeLock = () => {
            if (wakeLock) {
              wakeLock.release();
            }
          };
      
          // Request wake lock when the component mounts
          requestWakeLock();
      
          // Release wake lock when the component unmounts
          return () => {
            releaseWakeLock();
          };
        }, []);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
              <div className='app-container'>
                <div className="App" id="App">
                    <BrowserRouter>
                        <Routes>
                            {routes.map((route) => {
                                return (
                                    <Route 
                                      key={route.key} 
                                      path={route.path} 
                                      authNeeded={route.authNeeded}
                                      element={route.element} 
                                    />
                                )
                            })}
                            <Route path="*" element={<Navigate to="/login" />} />
                        </Routes>
                    </BrowserRouter>
                </div>
              </div>
            </PersistGate>
        </Provider>
  );
}


export default App;