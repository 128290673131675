import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ADD, REMOVE, VIEW_TYPES, BED_DETAILS_MODULE, DEVICE_TYPES } from "../../common/constant/common-constants";
import AnimateBed from "../../common/utils/Animations/AnimateBed";
import { activeDeviceAction, favoriteBedAction, selectedBedAction } from "../../modules/actions/action";
import BedDataContainer from "../BedDataContainer/BedDataContainer";
import "./BedContainer.scss";
import PatientRightPanel from "../PatientRightPanel/PatientRightPanel";
import { bedDetailConfigAction, viewTypeAction } from "../../modules/actions/action";
import { configReducer } from "../../modules/reducers/config-reducer";

function BedContainer(props) {

    // const [favArray, setFavArray] = useState([]);
    const [lvSelectedBed, setLvSelectedBed] = useState(props.selectedBed);
    const [bedArray, setBedArray] = useState([]);

    useEffect(() => {

        // const favBedArray = props.favoriteBedIdArray;

        // let currentBedIdArray = [...props.bedIdArray];

        // let bd = (currentBedIdArray && currentBedIdArray.sort((a, b) => favBedArray.indexOf(b) - favBedArray.indexOf(a))) || [];
        // setBedArray([...bd]);

        sortForFavorite();
    }, [props.favoriteBedIdArray])

    useEffect(() => {

        // const favBedArray = props.favoriteBedIdArray;
        // let currentBedIdArray = [...props.bedIdArray];
        // setBedArray(props.bedIdArray);
        sortForFavorite();
        if (props.bedIdArray && props.bedIdArray.length > 0) {
            // let bd = (bedArray && bedArray.sort((a, b) => favBedArray.indexOf(b) - favBedArray.indexOf(a))) || [];
            // setBedArray([...bd]);
            if (!lvSelectedBed && props.config.viewType === VIEW_TYPES.LIST_VIEW) {
                setLvSelectedBed(props.bedIdArray[0]);
                console.log("this runs on no lvselected");
                props.selectedBedAction(props.bedIdArray[0]);
            };
        }
    }, [props.bedIdArray]);

    useEffect(() => {
        setLvSelectedBed(props.selectedBed);
    }, [])

    const sortForFavorite = () => {
        const favBedArray = props.favoriteBedIdArray;
        // console.log("favbedArray", favBedArray)
        let currentBedIdArray = [...props.bedIdArray];
        let bd = (currentBedIdArray && currentBedIdArray.sort((a, b) => favBedArray.indexOf(b) - favBedArray.indexOf(a))) || [];
        setBedArray([...bd]);
    }

    const handleFavoriteClick = (event, type, bedId) => {
        event.stopPropagation();

        let arr = props.favoriteBedIdArray;
        let index = arr.indexOf(bedId);
        switch (type) {
            case ADD:
                if (index < 0) {
                    arr.push(bedId);
                }
                break;
            case REMOVE:
                if (index > -1) {
                    arr.splice(index, 1);
                }
                break;
            default:
                break;
        }

        props.favoriteBedAction([...arr]);

        // setFavArray([...arr]);
    }

    const handleNotificationClick = (bedId) => {
        console.log(`notification icon clicked for ${bedId}`);
    }
    console.log("config", props.config);
    const handleBedClick = (id) => {
        if (props?.bedDetails[id]?.patientMonitorId){
            props.activeDeviceAction(DEVICE_TYPES['MONITOR']);
        } else if (props?.bedDetails[id]?.ventilatorId){
            props.activeDeviceAction(DEVICE_TYPES['VENTILATOR']);
        } else {
            return
        }

        // if (props.config.viewType !== VIEW_TYPES.LIST_VIEW) {
        setLvSelectedBed(id);
        props.selectedBedAction(id);

        props.bedDetailConfigAction({
            activeModule: BED_DETAILS_MODULE.VITAL_DETAILS
        })
        props.viewTypeAction({
            viewType: VIEW_TYPES.LIST_VIEW
        })
    }

    const handleCrossClick = () => {
        setLvSelectedBed(null);
        props.selectedBedAction(null);
        props.viewTypeAction({
            viewType: VIEW_TYPES.GRID_VIEW
        })
    }

    const handleAlarmMoreClick = (id) => {
        setLvSelectedBed(id);

        props.bedDetailConfigAction({
            activeModule: BED_DETAILS_MODULE.ALARMS
        });
        props.viewTypeAction({
            viewType: VIEW_TYPES.LIST_VIEW
        })
    }

    const handleAddPatientClick = (id) => {
        setLvSelectedBed(id);
        props.bedDetailConfigAction({
            activeModule: BED_DETAILS_MODULE.PATIENT_DETAILS
        });
        props.viewTypeAction({
            viewType: VIEW_TYPES.LIST_VIEW
        })
    }

    const getBedArray = () => {

        if (bedArray === null) return;
        let tempBedArray = [...bedArray];

        // to render at the top of the list for the selected bed
        if (lvSelectedBed && tempBedArray && props.config.viewType == VIEW_TYPES.LIST_VIEW) {
            let tempEle = tempBedArray.splice(tempBedArray.indexOf(lvSelectedBed), 1)[0];
            tempBedArray.unshift(tempEle)
            // console.log("tempArray", tempBedArray);
        }

        let html = tempBedArray.map((id) => {

            // let wardId = props.selectedWard.id;

            if (props.wardData && props.wardData.bedList && (props.wardData.bedList.indexOf(id) >= 0)) {
                let ref = React.createRef();
                return (
                    <BedDataContainer
                        key={id}
                        bedId={id}
                        handleFavoriteClick={(event, type, bedId) => handleFavoriteClick(event, type, bedId)}
                        isFavorite={props.favoriteBedIdArray && props.favoriteBedIdArray.indexOf(id) > -1}
                        isSelected={lvSelectedBed}
                        handleBedClick={() => handleBedClick(id)}
                        handleNotificationClick={(bedId) => handleNotificationClick(bedId)}
                        handleAlarmMoreClick={() => handleAlarmMoreClick(id)}
                        handleAddPatientClick={() => handleAddPatientClick(id)}
                        ref={ref}
                    />);
            }
        });

        return html;
    }
    
    return (
        <>
            {/* {console.log("rerendering BedContainer !!!")} */}
            <div className="bed-container" style={props.config && props.config.viewType === VIEW_TYPES.GRID_VIEW ? {overflow:'auto'} : {}}>
                <div className={props.config.viewType === VIEW_TYPES.LIST_VIEW ? "left-panel" : "bed-grid-data-container"}>
                    <AnimateBed >
                        {getBedArray()}
                    </AnimateBed>
                </div>
                {/* } */}
                {props.config.viewType === VIEW_TYPES.LIST_VIEW &&
                    <div className="right-panel">
                        <PatientRightPanel
                            bedId={lvSelectedBed}
                            handleCrossClick={handleCrossClick}
                        />
                    </div>
                }
                {/* { */}
                {/* props.config.viewType === VIEW_TYPES.LIST_VIEW && <div className='bed-list-data-container'> */}

                {/* <div className='bed-list-card'>
                            {getBedArray()}
                        </div> */}

                {/* <BedListContainer wardData={props.wardData} /> */}
                {/* <div className='bed-list-data'>
                            {
                                // lvSelectedBed && <GraphDetail bedId={lvSelectedBed} />
                                <BedListContainer />

                            }
                        </div> */}
                {/* </div> */}
                {/* } */}
            </div>
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    let wardData = state.wardDetailsReducer.wardData && state.wardDetailsReducer.wardData[ownProps.selectedWard.id];
    let bedDetails = state.bedDetailsReducer.bedDetails;

    return {
        bedDetails: bedDetails,
        bedIdArray: state.bedDetailsReducer.bedIdArray,
        selectedBed: state.bedDetailsReducer.selectedBed,
        wardData: wardData,
        config: state.configReducer.config,
        favoriteBedIdArray: state.bedDetailsReducer.favoriteBedIdArray || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // bedDetailAction: (payload) => dispatch(bedDetailAction(payload)),
        // wardDetailAction: (payload) => dispatch(wardDetailsAction(payload)),
        favoriteBedAction: (payload) => dispatch(favoriteBedAction(payload)),
        activeDeviceAction: (payload) => dispatch(activeDeviceAction(payload)),
        bedDetailConfigAction: (payload) => dispatch(bedDetailConfigAction(payload)),
        viewTypeAction: (payload) => dispatch(viewTypeAction(payload)),
        configReducer: (payload) => dispatch(configReducer(payload)),
        selectedBedAction: (payload) => dispatch(selectedBedAction(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BedContainer);