import { useState } from "react";
import { DEVICE_TYPES } from "../../../common/constant/common-constants";
import { ALARM_PRIORITY } from "../../../common/constant/common-constants";
import { handleOutsideClick } from "../../../common/utils/CommonUtils";
import moment from 'moment';
import blackDownArrow from "../../../common/icons/svg/black-down-arrow.svg";
import blackUpArrow from "../../../common/icons/svg/black-up-arrow.svg";
import whiteUpArrow from "../../../common/icons/svg/white-up-arrow.svg";
import whiteDownArrow from "../../../common/icons/svg/white-down-arrow.svg";

// import low-priority from "../../../common/icons/alarm/low-priority-alarm.png";
import './DevicesTab.scss'
import { PATIENT_TYPE } from "../../../common/ENUMS/PARAMETER_ENUM";
import { TEMP_MODE } from "../../../common/ENUMS/VENTILATOR_MODES_ENUM";
import { activeDeviceAction } from "../../../modules/actions/action";
import { connect } from "react-redux";

function DevicesTab({ alarmData, handleSeeAllAlarms, activeVitalDevice, modeData, ...props }) {
    const [showMoreAlarms, setShowMoreAlarms] = useState(null);

    const getAlarmPriorityClass = (alarmData) => {
        // let id = bedId && bedId.toString();
        switch (alarmData?.priority?.toLocaleUpperCase()) {
            case "HIGH":
                return ALARM_PRIORITY.HIGH_PRIORITY;
            case "MEDIUM":
                return ALARM_PRIORITY.MEDIUM_PRIORITY;
            case "LOW":
                return ALARM_PRIORITY.LOW_PRIORITY;
            default:
                return ALARM_PRIORITY.LOW_PRIORITY;
        }
    }
    
    const handleShowMoreAlarmsClick = (event) => {
        event.stopPropagation();
        setShowMoreAlarms(!showMoreAlarms);
        if (!showMoreAlarms) {
            handleOutsideClick(event, 'alarm-display-bar', setShowMoreAlarms);
        }
    }

    const ventilatorConnected = props.ventilatorHeartbeat?.isConnected;
    const ventilationStatus = props.ventilatorStandby?.ventilationStatus;
    const monitorConnected = props?.monitorHeartBeatData?.isConnected
    const deviceAlarmData = activeVitalDevice === DEVICE_TYPES['MONITOR'] ? (monitorConnected ? alarmData?.alarms : []) : ventilatorConnected ? alarmData?.ventilatorAlarms : [];

    return(
        <div className='machine-tabs-wrap'>
            <div className="mode-container">
            {/* {modeData?.modesParameter?.modeName && <img src={adult} alt="patient type" />} */}
                <div className="mode-details">
                    <h2 className="mode">{(ventilatorConnected && ventilationStatus && activeVitalDevice !== DEVICE_TYPES['MONITOR']) ? `${TEMP_MODE[modeData?.modesParameter?.modeName]?.mode} ${PATIENT_TYPE[modeData?.modesParameter?.patientType]}` : '  ' }</h2>
                    {/* <h3 className="mode mt-4">PID: 8765</h3> */}
                </div>
                {deviceAlarmData?.length ?
                    <div className={`alarm-container ${getAlarmPriorityClass(deviceAlarmData[0]) }`}
                        onClick={(event) => handleShowMoreAlarmsClick(event)}
                        id="alarm-display-bar"
                    >
                        {
                        <>
                            <div className="top-alarm-container">
                                {deviceAlarmData[0]?.name}
                                <div style={{ display:'flex', alignItems:'center'}} >
                                    {deviceAlarmData?.length}
                                    {showMoreAlarms ?
                                        <img 
                                            style={{marginLeft: '6px', width:'0.833vw', height:'0.833vw'}} 
                                            src={deviceAlarmData[0]?.priority?.toLocaleUpperCase() === "HIGH" ? whiteUpArrow : blackUpArrow}
                                            className="arrow-icon" alt="accordion arrow" 
                                        />
                                    :
                                        <img 
                                            style={{marginLeft: '6px', width:'0.833vw', height:'0.833vw'}} 
                                            src={deviceAlarmData[0]?.priority?.toLocaleUpperCase() === "HIGH" ? whiteDownArrow : blackDownArrow}
                                            className="arrow-icon" alt="accordion arrow" 
                                        />
                                    }
                                </div>
                            </div>
                            {showMoreAlarms && 
                                <div className="more-alarm-container">
                                    {
                                        deviceAlarmData.map((alarm, count) => {
                                            if (count === 0 || count > 3) return;
                                            return (
                                                <div className="alarm more-alarm" key={count}>
                                                    <div style={{display: 'inline-flex', alignItems:'center'}}>
                                                        <img src={`/assets/icons/alarm/${getAlarmPriorityClass(alarm)}-alarm.png`} style={{ width:'20px', height:'20px', marginRight: '10px'}} alt="alarm icon" />
                                                        {alarm.name}
                                                    </div>
                                                    <span className="alarm-time">{alarm.time && moment(alarm.time).format("HH:mm:ss")}</span>
                                                </div>
                                            );
                                        })
                                    }
                                    <div className="see-all-container" onClick={() => handleSeeAllAlarms()}>
                                        See all
                                    </div>
                                </div>
                            }
                        </>
                        }
                    </div>
                :
                    <div style={{width: '58%', marginRight: '12px'}} />
                }
            </div>
            <div className='device-select-btn-container'>
                <div
                    className={`device-btn ${props?.bedDetails?.patientMonitorId ? props.config.activeDevice === DEVICE_TYPES.MONITOR ? "active-device-component" : "" : "disabled"}`}
                    onClick={() => {props?.bedDetails?.patientMonitorId ? props.activeDeviceAction(DEVICE_TYPES.MONITOR) : console.log('Device not configered') }}
                >
                        PATIENT MONITOR
                </div>
                <div
                    className={`device-btn ${props?.bedDetails?.ventilatorId ? props.config.activeDevice === DEVICE_TYPES.VENTILATOR ? "active-device-component" : "" : "disabled"}`}
                    onClick={() => {props?.bedDetails?.ventilatorId ? props.activeDeviceAction(DEVICE_TYPES.VENTILATOR) : console.log('Device not configered') }}
                >
                    VENTILATOR
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    let bedDetails = state.bedDetailsReducer.bedDetails[ownProps.bedId];
    let ventilatorHeartbeat = state.deviceVitalDataReducer?.ventilatorHeartbeat?.[ownProps.bedId];
    let ventilatorStandby = state.deviceVitalDataReducer?.ventilatorStandby?.[ownProps.bedId];
    let mData = state?.deviceVitalDataReducer?.deviceVitalData?.[ownProps.bedId]?.[DEVICE_TYPES.MONITOR];
    let mHeartBeatData = state?.deviceVitalDataReducer?.monitorHeartbeat?.[ownProps.bedId];
    return {
        bedDetails: bedDetails,
        ventilatorHeartbeat: ventilatorHeartbeat,
        ventilatorStandby: ventilatorStandby,
        config: state.configReducer.config,
        monitorData: mData,
        monitorHeartBeatData: mHeartBeatData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activeDeviceAction: (payload) => dispatch(activeDeviceAction(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesTab);