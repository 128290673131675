import React, { useState, useEffect } from "react";
import ApexChart from "../../components/Chart/ApexChart";
import left_arrow_svg from "../../common/icons/svg/left-arrow.svg";
import right_arrow_svg from "../../common/icons/svg/right-arrow.svg";
import { DEVICE_TYPES, TRIGGER, VENTILATOR_PARAM_LIST } from "../../common/constant/common-constants";

import "./VentilatorDataContainer.scss";
import { connect } from "react-redux";
import { closeSocketConnection, openSocketConnection } from "../../socket/socket";

function VentilatorDataContainer(props) {
    const TIME_RANGE_IN_MILLISECONDS = 5 * 1000;
    const [dataObj, setDataObj] = useState(() => {
        return {
            pressureDataList: [],
            volumeDataList: [],
            flowDataList: []
        }
    });

    const [paramList, setParamList] = useState(null);
    const [listStartCount, setListStartCount] = useState(0);
    const [rightDisabled, setRightDisabled] = useState(false);
    const [leftDisabled, setLeftDisabled] = useState(true);
    console.log("hello-------------------------sdveerer------------------------");
    useEffect(() => {
        console.log("hello-------------------------sdveerer------------------------");
        // setActionForSocket();
        // openSocketConnection();
        // return () => {
        //     closeSocketConnection();
        // }

    }, []);


    useEffect(() => {
        setParamList(VENTILATOR_PARAM_LIST.slice(listStartCount, listStartCount + 6));
    }, [listStartCount])

    useEffect(() => {
        const interval = setInterval(() => {
            setDataObj((prevDataObj) => {
                let pDataList = prevDataObj.pressureDataList;
                let vDataList = prevDataObj.volumeDataList;
                let fDataList = prevDataObj.flowDataList;

                // console.log("count :: " + count);
                // pGraph.map( (graphData) => {
                pDataList.push({ x: new Date(), y: Math.floor(Math.random() * 80) })
                // });
                // vGraph.map( (graphData) => {
                vDataList.push({ x: new Date(), y: Math.floor(Math.random() * 50) })
                //    });
                // fGraph.map( (graphData) => {
                fDataList.push({ x: new Date(), y: Math.floor(Math.random() * 70) })
                // });

                // pDataList.push({x: pGraph.time, y: pGraph.value });
                // vDataList.push({x: vGraph.time, y: vGraph.value });
                // fDataList.push({x: fGraph.time, y: fGraph.value });

                if (pDataList.length > 500) pDataList.splice(0, 30);
                if (vDataList.length > 500) vDataList.splice(0, 30);
                if (fDataList.length > 500) fDataList.splice(0, 30);


                // console.log("pressureDataList: " + JSON.stringify(pDataList));
                return {
                    ...prevDataObj,
                    pressureDataList: pDataList,
                    volumeDataList: vDataList,
                    flowDataList: fDataList
                }
            })
        }, 1000)

        return () => clearInterval(interval);
    }, [dataObj]);


    useEffect(() => {
        console.log(props.deviceData);
    }, [props.deviceData])

    const handleArrowClick = (type) => {
        setLeftDisabled(false);
        setRightDisabled(false);
        if (type === TRIGGER.RIGHT) {
            if (listStartCount + 1 <= VENTILATOR_PARAM_LIST.length - 6) {
                setListStartCount(listStartCount + 1);
            } else {
                setRightDisabled(true);
            }
        } else {
            if (listStartCount - 1 >= 0) {
                setListStartCount(listStartCount - 1);
            } else {
                setLeftDisabled(true);
            }
        }
    }

    return (
        <div className="ventilator-data-container">
            <div className="ventilator-graph-container">
                <div className="pressure-graph-container">
                    <ApexChart className="chart"
                        dataList={dataObj.pressureDataList}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        yMin={0}
                        yMax={80}
                        yTickPoint={4}
                        showYAxis={true}
                        showXAxis={true}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#299842'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#20BBC1'}
                        name='apex-ecg-1'
                        labels={false}
                    />
                </div>
                <div className="flow-graph-container">
                    <ApexChart className="chart"
                        dataList={dataObj.pressureDataList}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        yMin={0}
                        yMax={80}
                        yTickPoint={4}
                        showYAxis={true}
                        showXAxis={true}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#299842'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#20BBC1'}
                        name='apex-ecg-1'
                    />
                </div>
                <div className="volume-graph-container">
                    <ApexChart className="chart"
                        dataList={dataObj.pressureDataList}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        yMin={0}
                        yMax={80}
                        yTickPoint={4}
                        showYAxis={true}
                        showXAxis={true}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#299842'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#20BBC1'}
                        name='apex-ecg-1'
                    />
                </div>
            </div>
            <div className="ventilator-vitals-container">
                <div className={leftDisabled ? "left-arrow-container disabled" : "left-arrow-container"}>
                    <img src={left_arrow_svg} alt="left-arrow" onClick={() => handleArrowClick(TRIGGER.LEFT)} />
                </div>
                {
                    paramList && paramList.map((param) => {
                        let paramObj = props.deviceData && props.deviceData.vitals.filter(
                            (vVital) => vVital.name && vVital.name === param.name
                        )[0];

                        return (<div key={param.paramName} className="vital-data">
                            <div className="param-name">
                                <div className="main-name">
                                    {param.html.name}<sub>{param.html.nameSub}</sub>{param.html.nameSuccessor}
                                </div>
                            </div>
                            <div className="param-value">
                                <div className="value-data">
                                    {(paramObj && paramObj.data[0] && paramObj.data[0].value) || '-'}
                                </div>
                                <span className="value-unit">
                                    {param.html.sub}<sub>{param.html.subSub}</sub>{param.html.subSuccessor}
                                </span>
                                {/* </div> */}
                            </div>

                        </div>);
                    })
                }
                <div className={rightDisabled ? "right-arrow-container disabled" : "right-arrow-container"}>
                    <img
                        src={right_arrow_svg}
                        alt="left-arrow"
                        onClick={() => handleArrowClick(TRIGGER.RIGHT)}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {

    let vData = state.deviceVitalDataReducer.deviceVitalData
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId][DEVICE_TYPES.VENTILATOR];

    return {
        deviceData: vData
    };
};

export default connect(mapStateToProps, null)(VentilatorDataContainer);