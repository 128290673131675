import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BED_DETAILS_MODULE, DEVICE_TYPES } from "../../common/constant/common-constants";
import { PAGE_CONST } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";
import BedCardMonitor from "../../components/BedCardMonitor/BedCardMonitor";
import BedCardVentilatorMonitor from "../../components/BedCardVentilatorMonitor/BedCardVentilatorMonitor";
import { bedDetailConfigAction, clearBedAlarm, modalAction } from "../../modules/actions/action";

import "./BedDataContainer.scss";

function BedDataContainer(props) {
    // console.log("props in data container", props);
    // const navigate = useNavigate();

    const handleFavoriteClick = (event, type, bedId) => {
        event.stopPropagation();
        props.handleFavoriteClick(event, type, bedId);
    }

    const handleNotificationClick = (event, type, bedId) => {
        // event.stopPropagation();
        // console.log(`notification icon clicked for ${bedId}`);
        props.handleNotificationClick();
    }

    const handleClearBedAlarm = (bedId) => {
        let data = {
            bedId: bedId,
        }
        props.clearBedAlarm(data)

    }

    return (
        <BedCardVentilatorMonitor
            key={props.bedId}
            bedId={props.bedId}
            patientData={props.patientData}
            data={props.bedDetails}
            monitorData={props.monitorData}
            monitorHeartBeatData={props.monitorHeartBeatData}
            monitorEcgWaveFormData={props.monitorEcgWaveFormData}
            monitorHrWaveFormData={props.monitorHrWaveFormData}
            alarmData={props.alarmData}
            ventilatorAlarmData={props.ventilatorAlarmData}
            ventilatorMode={props.ventilatorMode.modesParameter}
            // notificationData={props.notificationData}
            ventilatorData={props.ventilatorData}
            isFavorite={props.isFavorite}
            isSelected={props.isSelected}
            handleFavoriteClick={(event, type, bedId) => handleFavoriteClick(event, type, bedId)}
            handleBedClick={props.handleBedClick}
            handleNotificationClick={(bedId) => handleNotificationClick(bedId)}
            handleAlarmMoreClick={props.handleAlarmMoreClick}
            ref={props.myForwardedRef}
            handleAddPatientClick={() => props.handleAddPatientClick(props.bedId)}
            handleClearBedAlarm={(bedId) => handleClearBedAlarm(bedId)}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    // console.log("ownprops", ownProps)
    let bds = state.bedDetailsReducer.bedDetails
        && state.bedDetailsReducer.bedDetails[ownProps.bedId];
    // .filter((bed) => bed.id === ownProps.bedId)[0];

    let pData = state.patientDataReducer.patientData && state.patientDataReducer.patientData[ownProps.bedId];

    let mData = state?.deviceVitalDataReducer?.deviceVitalData?.[ownProps.bedId]?.[DEVICE_TYPES.MONITOR];
    let mHeartBeatData = state?.deviceVitalDataReducer?.monitorHeartbeat?.[ownProps.bedId];
    let mEcgwData = state?.deviceVitalDataReducer?.deviceEcgWaveform?.[ownProps.bedId]?.[DEVICE_TYPES.MONITOR];
    let mHrwData = state.deviceVitalDataReducer.deviceHrWaveform
        && state.deviceVitalDataReducer.deviceHrWaveform[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceHrWaveform[ownProps.bedId][DEVICE_TYPES.MONITOR];

    let vData = state?.deviceVitalDataReducer?.ventilatorVitalData?.[ownProps.bedId];

    let vModeData = state?.deviceVitalDataReducer?.ventilatorModesData?.[ownProps.bedId];
    
    let notificationData = state.notificationDataReducer.notificationData
        && state.notificationDataReducer.notificationData[ownProps.bedId]
        && state.notificationDataReducer.notificationData[ownProps.bedId].notifications;

    let alarmData = state?.alarmDataReducer?.alarmData?.[ownProps.bedId]?.alarms;
    let vAlarmData = state?.alarmDataReducer?.alarmData?.[ownProps.bedId]?.ventilatorAlarms;

    return {
        bedDetails: bds,
        patientData: pData,
        monitorData: mData,
        monitorHeartBeatData: mHeartBeatData,
        monitorEcgWaveFormData: mEcgwData,
        monitorHrWaveFormData: mHrwData,
        ventilatorData: vData,
        ventilatorMode: vModeData,
        notificationData: notificationData,
        alarmData: alarmData && [...alarmData],
        ventilatorAlarmData: vAlarmData && [...vAlarmData],
        config: state.configReducer.config
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalAction: (payload) => dispatch(modalAction(payload)),
        bedDetailConfigAction: (payload) => dispatch(bedDetailConfigAction(payload)),
        clearBedAlarm: (payload) => dispatch(clearBedAlarm(payload)),
    }
}

const ConnectedBedDataContainer = connect(
    mapStateToProps, mapDispatchToProps
)(BedDataContainer);

const BedDataContainerRef = forwardRef((props, ref) =>
    <ConnectedBedDataContainer key={props.bedId} {...props} myForwardedRef={ref} />
);
export default BedDataContainerRef;