import GCSScore from "../../components/GCSScore/GCSScore";
import SOFAScore from "../../components/SOFAScore/SOFAScore";
import BedDetails from "../../pages/bedDetails/BedDetails";
import HomePage from "../../pages/homepage/Homepage";
import Login from "../../pages/login/Login";
import SOFAScorePage from "../../pages/SOFAScore/SOFAScorePage";
import GCSScorePage from "../../pages/GCSScore/GCSScorePage";
import PatientBook from "../../pages/PatientBook/PatientBook";

export const routes = [{
    key: "login",
    path: '/login',
    exact: true,
    authNeeded: false,
    element: <Login />
}, {
    key: 'homepage',
    path: '/home-page',
    exact: true,
    authNeeded: true,
    element: <HomePage />
}, {
    key: 'homepage',
    path: '/bed-details/:id',
    exact: true,
    authNeeded: true,
    element: <BedDetails />
}, {
    key: 'gcs-score',
    path: '/gcs-score/:id',
    exact: true,
    authNeeded: true,
    element: <GCSScorePage />
}, {
    key: 'sofa-score',
    path: '/sofa-score/:id',
    exact: true,
    authNeeded: true,
    element: <SOFAScorePage />
},
{
    key: 'patient-book',
    path: '/patient-book',
    exact: true,
    authNeeded: true,
    element: <PatientBook />
},
]
