import React from "react";

import logo_svg from "../../common/icons/svg/logo.svg";
import print_svg from "../../common/icons/svg/print.svg";
import volume_svg from "../../common/icons/svg/volume.svg";
import CustomSelect from "../CustomSelect/CustomSelect";
import "./Header.scss";

export function Header(props) {
    
    return (
        <div className="header">
            <div className="logo-container">
                <img 
                    className="logo"
                    src={logo_svg} 
                    alt="logo svg" 
                    onClick={() => console.log("logo clicked !! ")} 
                />
                <div className="dashboard-text">HorizonView</div>
            </div>
            <div className="ward-container">
                <div className="hospital-name-container">
                    {/* <img src={print_svg} alt="print svg" onClick={() => console.log("print clicked !! ")} /> */}
                    {props.hospitalName}
                </div>
                {/* <div className="volume-icon-container">
                    <img src={volume_svg} alt="volume svg" onClick={() => console.log("print clicked !! ")} />
                </div> */}
                <div className="select-container">
                    <CustomSelect
                        id="ward-name"
                        value={props.selectedWard}
                        handleChange={(item) => props.handleWardChange(item)}
                        label='label'
                        isClearable={false}
                        options={props.wardOption}
                        placeholder='Select Ward'
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isDisabled={props.isDisabled}
                        isSearchable={false}
                    />
                </div>
            </div>
        </div>
    );
}