import { getHospitalDetailsAPI } from "../adapter/cms-adapter";
import { getLocalStorageCustom } from "../common/utils/StorageUItils";

export const getHospitalDetails = (data) => {

    let reqData = {
        hospital_id: getLocalStorageCustom('hospitalId')
    }

    let promise = new Promise(function (resolve, reject) {

        getHospitalDetailsAPI(reqData).then((res) => {

            if (res && res.data) resolve(res.data);

            else reject("some error message getHospitalDetails");
        })
    })

    return promise;
}