import React, { useEffect, useState } from "react";
import dashboard_svg from "../../common/icons/svg/dashboard.svg";
import signout_svg from "../../common/icons/svg/signout.svg";
import device_mapper_svg_active from "../../common/icons/svg/sidebar-device-mapper-active.svg";
import device_mapper_svg from "../../common/icons/svg/sidebar-device-mapper.svg";
import dashboard_svg_active from "../../common/icons/svg/dashboard-active.svg";
import "./Sidebar.scss";
import { SIDEBAR_ICON } from "../../common/constant/common-constants";
import { redirect, useNavigate } from "react-router-dom";
import { sidebarActiveIconAction } from '../../modules/actions/action';
import { connect } from "react-redux";

function SideBar(props) {

    // const [activeIcon, setActiveIcon] = useState(SIDEBAR_ICON.HOME_PAGE);
    const navigate = useNavigate();

    // useEffect(() => {
    //     redirect(navigate, activeIcon);
    // }, [])


    return (
        <div className="side-bar">
            <div className="sidebar-icon-container">
                <div className="top-icon-container">
                    {props.activeSidebarIcon === SIDEBAR_ICON.HOME_PAGE ?
                        <img src={dashboard_svg_active} alt="dashboard svg" className="activeIcon" /> :
                        <img src={dashboard_svg} alt="dashboard svg" onClick={(event) => props.handleIconClick(SIDEBAR_ICON.HOME_PAGE)} />}
                    {/* {props.activeSidebarIcon === SIDEBAR_ICON.DEVICE_MAPPER ?
                        <img src={device_mapper_svg_active} alt="dashboard svg" className="activeIcon" /> :
                        <img src={device_mapper_svg} alt="dashboard svg" onClick={(event) => props.handleIconClick(SIDEBAR_ICON.DEVICE_MAPPER)} />} */}
                    {/* <div className="alarm-icon-container icon-container">
                        <img src={alarm_svg} alt="alarm svg" onClick={() => console.log("alarm clicked !! ")} />
                    </div>
                    <div className="setting-icon-container icon-container">
                        <img src={setting_svg} alt="setting svg" onClick={() => console.log("setting clicked !! ")} />
                    </div>
                    <div className="help-icon-container icon-container">
                        <img src={info_svg} alt="help svg" onClick={() => console.log("help clicked !! ")} />
                    </div> */}
                </div>
                <div className="bottom-icon-container">
                    <div className="help-icon-container icon-container">
                        <img
                            className="icon"
                            src={signout_svg}
                            alt="signout svg"
                            onClickCapture={(event) => props.handleHelpClick(event)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = state => {
    return {
        activeSidebarIcon: state.configReducer.config.activeSidebarIcon,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sidebarActiveIconAction: (payload) => dispatch(sidebarActiveIconAction(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

