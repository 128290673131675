import React from 'react';
import './TrendGraph.scss'
import { Line } from "react-chartjs-2";
import moment from 'moment';
function TrendGraph(props) {
    return (
        <div className="trend-graph-container">

            {props.selectedParamList && (props.selectedParamList.length > 0) && props.selectedParamList.map((graphParam) => {
                let xgraphData = [props.data.data.map((ele) => {
                    return ele["time"] && moment(ele["time"]).subtract(330, "minutes").format("YYYY-MM-DD, HH:mm");
                })]
                const filteredData = props.data.data.reduce((acc, current) => {
                    const found = acc.find(item => item.time === current.time);
                    if (!found) {
                      acc.push(current);
                    }
                    return acc;
                  }, []);
                let ygraphData = [
                    filteredData.map((ele) => {
                        return ele[graphParam.name]
                    })
                ]
                return (
                    <Line
                        className="line-graph-vital"
                        key={graphParam.name}
                        data={{
                            labels: xgraphData[0],
                            datasets: [{
                                data: ygraphData[0],
                                fill: false,
                                borderColor: 'white',
                                borderWidth: 2,
                                tension: 0,
                                pointRadius: 1.5,
                            }],
                        }}
                        options={{
                            // maintainAspectRatio: false,
                            plugins: {
                                tooltip: {
                                    enabled: true,
                                    position: 'nearest',
                                },
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: true,
                                    text: graphParam.label,
                                    align: 'start',
                                    color: "white",
                                    font: {
                                        size: 16,
                                        weight: 600,
                                    },
                                    padding: {
                                        bottom: 20,
                                    }
                                },
                            },
                            scales: {
                                x: {
                                    grid: {
                                        borderColor: '#4D4D4D'
                                    }
                                },
                                y: {
                                    grid: {
                                        borderColor: '#4D4D4D'
                                    }
                                }
                            }
                        }}
                        height={"80px"}
                    />
                )
            })}
        </div>
    );
}

export default TrendGraph;