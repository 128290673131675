import React, {useEffect, useState} from "react";
import "./MonitorAlarmTable.scss";
import alarm_trend_svg from "../../../common/icons/svg/alarm-trend.svg";
import priority_high_svg from "../../../common/icons/svg/priority-high.svg";
import priority_medium_svg from "../../../common/icons/svg/priority-medium.svg";
import priority_low_svg from "../../../common/icons/svg/priority-low.svg";
import { getAlarmHistory } from "../../../helper/alarm-helper";
import moment from "moment";

export function MonitorAlarmTable(props) {
    const [data, setData] = useState([]);
    const handleChange = (parameter, data, index, newData) => {
        // console.log("row: " + JSON.stringify(row) + " ,data: " + data + " ,index: " + index + " ,newData: " + newData);

        props.handleChange(parameter, data, index, newData);
    }

    useEffect(() => {
        let reqData = {
            bedId: props.bedId,
            patientSessionId: props.patientSessionId
        }
        getAlarmHistory(reqData)
            .then((res) => {
                if (res.alarms) {
                    let dat = res.alarms.map((alrm, count) => {
                        return alrm
                    })
                    setData(dat);
                }
            })
            .catch((err) => {
                console.log("alarm err: ", err);
            })
    }, [props.bedId]);

    const getPrioritySvg = (priority) => {

        let pr = priority.toUpperCase();
        switch (pr) {
            case "HIGH":
                return priority_high_svg;
            case "MEDIUM":
                return priority_medium_svg;
            case "LOW":
                return priority_low_svg;
        }
    }

    return (
        <div className="alarm-table-container">
            <table className="alarm-table">
                <thead className="sticky">
                    <tr>
                        {
                            props.header && props.header.map((data, count) => {
                                return (
                                    <th key={count} className="alarm-header">{data}</th>
                                )
                            })
                        }
                    </tr>
                </thead >
                <tbody >
                    {
                        data.map((row, count) => {
                            return (
                                <tr key={count}>
                                    {
                                        props.header && props.header.map((head) => {
                                            if (head === "PRIORITY") {
                                                return (<td key={head}>
                                                    <div className="alarm-priority">
                                                        <img src={getPrioritySvg(row.priority)} className="priority" />
                                                    </div>
                                                </td>);
                                            } 
                                            else if (head === "ALARM") {
                                                return (
                                                    <td key={head}>
                                                        <div className="alarm-name">{row.name}
                                                        </div>
                                                    </td>);
                                            }
                                            else if (head === "VALUE") {
                                                return (
                                                    <td key={head}>
                                                        <div className="alarm-name">{row.value}
                                                        </div>
                                                    </td>);
                                            }
                                            else if (head === "DATE") {
                                                return (
                                                    <td key={head}>
                                                        <div className="alarm-date">{moment(row.time).format("YYYY/MM/DD")}</div>
                                                    </td>);
                                            }
                                            else if (head === "TIME") {
                                                return (
                                                    <td key={head}>
                                                        <div className="alarm-time">{moment(row.time).format("HH:mm:ss")}</div>
                                                    </td>);
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}