import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import Pagination from '../Pagination/Pagination';
import "./VentilatorVitalData.scss";
// import { getVentilatorMode } from '../../helper/mode-helper';
import { OBSERVED_PARAMETERS } from '../../common/ENUMS/PARAMETER_ENUM';
import { MODE, TEMP_MODE } from '../../common/ENUMS/VENTILATOR_MODES_ENUM';
import offlineIcon from "../../common/icons/svg/ventilator-offline.svg"
import standbyIcon from "../../common/icons/svg/no-ventilation.svg"
import { alarmBackground } from '../../common/utils/CommonUtils';
// import VentilatorWaveform from '../VentilatorChart/VentilatorWaveform';
import VentilatorSetParams from '../VentilatorSetParams/VentilatorSetParams';
import VentilatorChart from '../VentilatorChart/VentilatorChart.jsx';
//import StreamingChart from '../Chart/NewChart.js';


function VentilatorVitalData(props) {
    // console.log("ventilator vital props", props.ventilatorWaveformData.ventilatorWaveformData.pressure);
    const [ventilatorParamData, setVentilatorParamData] = useState("");
    const [ventilatorPermanentData, setVentilatorPermanentData] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTableData, setCurrentTableData] = useState([])

    const permanentData = ["pip", "peep", "pplat", "i", "e", "rr", "vte", "vti", "cal_param_mve", "fio2"];
    useEffect(() => {
        let ventilatorVitals = props?.ventilatorVitalData ? Object.values(props?.ventilatorVitalData) : [];
        let modeName = props?.ventilatorModesData?.modesParameter?.modeName;
        let tempPermanentData = [];
        let tempParamData = [];
        ventilatorVitals.map((param, i) => {
            if (permanentData.includes(param.key)) {
                let index = permanentData.indexOf(param.key)
                tempPermanentData[index] = param;
            }
        })
        let e = ventilatorVitals.filter((param)=> param.key === 'e')?.[0];
        let i = ventilatorVitals.filter((param)=> param.key === 'i')?.[0];
        let rr = ventilatorVitals.filter((param)=> param.key === 'rr')?.[0]?.value;
        let pip = ventilatorVitals.filter((param)=> param.key === 'pip')?.[0]?.value;
        let peep = ventilatorVitals.filter((param)=> param.key === 'peep')?.[0]?.value;
        let vti = ventilatorVitals.filter((param)=> param.key === 'vti')?.[0]?.value;
        let vte = ventilatorVitals.filter((param)=> param.key === 'vte')?.[0]?.value;

        TEMP_MODE[modeName]?.monitorParams.map((id)=>{
            let tempMonitor = ventilatorVitals?.filter((param)=> param.id === id)?.[0];
            let ti = (60*i?.value)/(rr*(i?.value+e?.value));
            let te = (60*e?.value)/(rr*(i?.value+e?.value));
            if (id <= 104030){
                tempParamData.push(tempMonitor);
            } else {
                switch(id) {
                    case 104032:
                        if (tempMonitor){
                            tempMonitor.value = parseFloat(tempMonitor?.value/1000).toFixed(1)
                            tempParamData.push(tempMonitor);
                        }
                        break;
                    case 104033:
                        if (tempMonitor){
                            tempMonitor.value = parseFloat(tempMonitor?.value/1000).toFixed(1)
                            tempParamData.push(tempMonitor);
                        }
                        break;
                    case 104034:
                        if ((ti + te)){
                            tempParamData.push({
                                value: parseFloat(peep + ((pip - peep) * ti )/(ti+te)).toFixed(1),
                                id: 104034,
                            });
                        }
                        break;
                    case 104035:
                        tempParamData.push({
                            value: (vti && (vti-vte) >= 0) ? Math.round((vti-vte)*100/vti) : '0',
                            id: 104035
                        });
                        break;
                    case 104036:
                        if (i?.value && e?.value && rr*(i.value+e.value)){
                            tempParamData.push({
                                value: parseFloat(te).toFixed(1),
                                id: 104036
                            });
                        }
                        break;
                    case 104037:
                        if (i?.value && e?.value && rr*(i.value+e.value)){
                            tempParamData.push({
                                value: parseFloat(ti).toFixed(1),
                                id: 104037
                            });
                        }
                        break;
                    case 104038:
                        tempParamData.push({
                            value: parseFloat((rr*1000)/vti).toFixed(1),
                            id: 104038
                        });
                        break;
                    default:
                        break
                }
            }
        })

        let permanentMonitorParam = [];
        let i_e;
        tempPermanentData?.map((param, index) => {
                if (!['i', 'e'].includes(param?.key?.toLowerCase())) {
                    permanentMonitorParam.push({
                        ...param, 
                        ...OBSERVED_PARAMETERS[param?.id]
                    })
                } 
                else if (param?.key?.toLowerCase() === 'i' || param?.key?.toLowerCase() === 'e') {
                    i_e = (i?.value >= e?.value) ?
                        `${parseFloat((i?.value) / (e?.value)).toFixed(1)} : 1`
                    :
                        `1 : ${parseFloat((e?.value) / (i?.value)).toFixed(1)}`
            }
        });
        permanentMonitorParam.push({
            value: i_e,
            ...OBSERVED_PARAMETERS['i_e']
        })
        setVentilatorParamData(tempParamData)
        setVentilatorPermanentData(permanentMonitorParam)
        updateCurrentTable();
    }, [props?.ventilatorVitalData])
    


    const updateCurrentTable = () => {
        if (!ventilatorParamData?.length) return;
        const firstPageIndex = currentPage === 1 ? 0 : ((currentPage - 1) * 10) - 6;
        const lastPageIndex = currentPage === 1 ? firstPageIndex + 4 : firstPageIndex + 10;
        let tempData = [...ventilatorParamData.slice(firstPageIndex, lastPageIndex)];
        setCurrentTableData(tempData);
    }

    useEffect(() => {
        updateCurrentTable();
    }, [currentPage]);

    const LeftBoxParamContainer = ({index, fontColor}) => (
        <div className={`left-column-containers ${fontColor} ${ventilatorPermanentData?.[index]?.alarms?.length ? alarmBackground(ventilatorPermanentData?.[index]?.alarms) : ''}`}>
            <div className='left-box-header'>
                <div className='param-name'>
                    <div className='param-name-text'>{ventilatorPermanentData?.[index]?.['TITLE_START']}<sub>{ventilatorPermanentData?.[index]?.['TITLE_SUB_SCRIPT']}</sub>{ventilatorPermanentData?.[index]?.['TITLE_END']}</div>
                    <div className='param-unit-text'>({ventilatorPermanentData?.[index]?.['UNIT_START']}<sub>{ventilatorPermanentData?.[index]?.['UNIT_SUB_SCRIPT']}</sub>{ventilatorPermanentData?.[index]?.['UNIT_END']})</div>
                </div>
                <div className='param-name'>
                    <div className='param-st-val'>{ventilatorPermanentData?.[index]?.alarm_limit?.[1]}</div>
                    <div className='param-st-val'>{ventilatorPermanentData?.[index]?.alarm_limit?.[0]}</div>
                </div>
            </div>
            <div className={`param-val ${fontColor} ${ventilatorPermanentData?.[index]?.alarms?.length ? alarmBackground(ventilatorPermanentData?.[index]?.alarms) : ''}`}>
                {(index === 6 ? parseFloat(ventilatorPermanentData?.[index]?.value/1000).toFixed(1) : ventilatorPermanentData?.[index]?.value) || "--"}
            </div>
        </div>
    )

    const RightBoxParamContainer = ({index, fontColor}) => (
        <div className={`right-column-containers ${ventilatorPermanentData?.[index]?.alarms?.length ? alarmBackground(ventilatorPermanentData?.[index]?.alarms) : ''}`}>
            <div className='right-box-param'>
                <div className='right-box-param-name'>{ventilatorPermanentData?.[index]?.['TITLE_START']}<sub>{ventilatorPermanentData?.[index]?.['TITLE_SUB_SCRIPT']}</sub>{ventilatorPermanentData?.[index]?.['TITLE_END']}</div>
                {ventilatorPermanentData?.[index]?.['UNIT_START'] ?
                    <div className='right-box-param-unit'>({ventilatorPermanentData?.[index]?.['UNIT_START']}<sub>{ventilatorPermanentData?.[index]?.['UNIT_SUB_SCRIPT']}</sub>{ventilatorPermanentData?.[index]?.['UNIT_END']})</div>
                :
                    <div className='right-box-param-unit'/>
                }
            </div>
            <div className={`right-param-val ${fontColor} ${ventilatorPermanentData?.[index]?.alarms?.length ? alarmBackground(ventilatorPermanentData?.[index]?.alarms) : ''}`}>{ventilatorPermanentData?.[index]?.value || "--"}</div>
            <div className='right-st-container'>
                <div className='param-st-val'>{ventilatorPermanentData?.[index]?.alarm_limit?.[1]}</div>
                <div className='param-st-val'>{ventilatorPermanentData?.[index]?.alarm_limit?.[0]}</div>
            </div>
        </div>
    )
    
    if (props.ventilatorHeartbeat?.isConnected){
        return (
            <div className='ventilator-vitals-container'>
                {/* <div className='waveform-param-container'> */}
                <div className='waveform-and-footer-container'>
                    {(props.ventilatorStandby?.ventilationStatus) ?
                        // <VentilatorWaveform
                        //     bedId={props.bedId}
                        // />
                        <>
                        <VentilatorChart
                            bedId={props.bedId}
                        />
                        {/*type !== '?type=1' ?
                            
                            <ApexChartVent
                                bedId={props.bedId}
                            />
                        :
                            <div key={'Ventilator-graphs'} style={{height: '88%', marginLeft: '2%'}}>
                                <StreamingChart
                                    style={{ height: '100%' }}
                                    modeName = {props?.ventilatorModesData?.modesParameter?.modeName}
                                    bedId={props.bedId}
                                />
                            </div>
                    */}
                        </>
                    :
                        <div className='standby'>
                            <img
                                className='not-in-use-imge'
                                src={standbyIcon} 
                                alt="Ventilator on Standby" />
                            <h2 className='not-in-use-text'>Ventilator is on Standby</h2>
                        </div>
                    }
                    <VentilatorSetParams
                        bedId={props.bedId}
                    />
                </div>
                {props.ventilatorStandby?.ventilationStatus &&
                    <div className='param-vitals-container'>
                        <div className='vital-param-boxes-container'>
                            {currentPage === 1 &&
                                <div className='param-grid-representation'>
                                    {props?.ventilatorModesData?.modesParameter?.modeName?.toUpperCase() !== MODE[1012].modeName &&
                                    <>
                                    <LeftBoxParamContainer index={0} fontColor={'blue-font'} />
                                    <div className='param-right-row'>
                                        <RightBoxParamContainer index={1} fontColor={'blue-font'} />
                                        <RightBoxParamContainer index={2} fontColor={'blue-font'} />
                                    </div>
                                    <LeftBoxParamContainer index={3} fontColor={'green-font'} />
                                    <div className='param-right-row'>
                                        <RightBoxParamContainer index={4} fontColor={'green-font'} />
                                        <RightBoxParamContainer index={5} fontColor={'green-font'} />
                                    </div>
                                    <LeftBoxParamContainer index={6} fontColor={'yellow-font'} />
                                    <div className='param-right-row'>
                                        <RightBoxParamContainer index={7} fontColor={'yellow-font'} />
                                        <RightBoxParamContainer index={8} fontColor={'yellow-font'} />
                                    </div>
                                    </>}
                                    {currentTableData && currentTableData?.map((item, count) => {
                                        if (count < 4) {
                                            return (
                                                <>
                                                    <div className={`left-column-containers non-graph-param ${item?.alarms?.length ? alarmBackground(item.alarms) : ''}`}>
                                                        <div className='left-box-header'>
                                                            <div className='param-name'>
                                                                <div className='param-name-text'>{OBSERVED_PARAMETERS[item?.id]?.['TITLE_START']}<sub>{OBSERVED_PARAMETERS[item?.id]?.['TITLE_SUB_SCRIPT']} </sub> {OBSERVED_PARAMETERS[item?.id]?.['TITLE_END']}</div>
                                                                {OBSERVED_PARAMETERS[item?.id]?.['UNIT_START'] ? 
                                                                    <div className='param-unit-text'>({OBSERVED_PARAMETERS[item?.id]?.['UNIT_START']}<sub>{OBSERVED_PARAMETERS[item?.id]?.['UNIT_SUB_SCRIPT']}</sub>{OBSERVED_PARAMETERS[item?.id]?.['UNIT_END']})</div>
                                                                :
                                                                    <div className='param-unit-text'></div>
                                                                }
                                                            </div>
                                                            <div className='param-name'>
                                                                <div className='param-st-val'>{item?.alarm_limit?.[0]}</div>
                                                                <div className='param-st-val'>{item?.alarm_limit?.[1]}</div>
                                                            </div>

                                                        </div>
                                                        <div className={`param-val ${item?.alarms?.length ? alarmBackground(item.alarms) : ''}`}>{isNaN(item?.value) ? '--' :  item?.value}</div>
                                                    </div>
                                                </>
                                            );
                                        }
                                    })}
                                </div>
                            }
                            {currentPage !== 1 && 
                                <div className='param-grid-representation no-curr'>
                                    {currentTableData && currentTableData.map((item, count) => {
                                        return (
                                            <div key={count}>
                                                <div className={`left-column-containers non-graph-param ${item?.alarms?.length ? alarmBackground(item.alarms) : ''}`}>
                                                    <div className='left-box-header'>
                                                        <div className='param-name'>
                                                            <div className='param-name-text'>{OBSERVED_PARAMETERS[item?.id]?.['TITLE_START']}<sub>{OBSERVED_PARAMETERS[item?.id]?.['TITLE_SUB_SCRIPT']}</sub>{OBSERVED_PARAMETERS[item?.id]?.['TITLE_END']}</div>
                                                            {OBSERVED_PARAMETERS[item?.id]?.['UNIT_START'] ?
                                                                <div className='param-unit-text'>({OBSERVED_PARAMETERS[item?.id]?.['UNIT_START']}<sub>{OBSERVED_PARAMETERS[item?.id]?.['UNIT_SUB_SCRIPT']}</sub>{OBSERVED_PARAMETERS[item?.id]?.['UNIT_END']})</div>
                                                            :
                                                                <div className='param-unit-text' />
                                                            }
                                                        </div>
                                                        <div className='param-name'>
                                                            <div className='param-st-val'>{item?.alarm_limit?.[0]}</div>
                                                            <div className='param-st-val'>{item?.alarm_limit?.[1]}</div>
                                                        </div>
                                                    </div>
                                                    <div className={`param-val ${item?.alarms?.length ? alarmBackground(item.alarms) : ''}`}>{isNaN(item?.value) ? '--' : ([531, 533].includes(item?.id) ? parseFloat((item?.value)/1000).toFixed(1) : item?.value)}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={props?.ventilatorVitalData ? (Object.keys(props?.ventilatorVitalData)?.length - 14) : 1}
                            // totalCount={28}
                            pageSize={5}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                }
            </div >
        );
    } else {
        return (
            <div className='offline'>
                <img className='not-in-use-imge' src={offlineIcon} alt="Ventilator Offline" />
                <h2 className='not-in-use-text'>Ventilator is currently offline, possibly due to settings or poor internet connection; please check and ensure proper configuration for seamless monitoring.</h2>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let vData = state.deviceVitalDataReducer.ventilatorVitalData
        && state.deviceVitalDataReducer.ventilatorVitalData[ownProps.bedId]
        && state.deviceVitalDataReducer.ventilatorVitalData[ownProps.bedId]['ventilatorVitalData'];

    let vModeData = state.deviceVitalDataReducer?.ventilatorModesData?.[ownProps.bedId];

    let ventilatorHeartbeat = state.deviceVitalDataReducer?.ventilatorHeartbeat?.[ownProps.bedId];

    let ventilatorStandby = state.deviceVitalDataReducer?.ventilatorStandby?.[ownProps.bedId];

    return {
        ventilatorVitalData: vData,
        ventilatorModesData: vModeData,
        ventilatorHeartbeat: ventilatorHeartbeat,
        ventilatorStandby: ventilatorStandby,
        config: state.configReducer.config.bedDetailConfig,
    }
}

export default connect(mapStateToProps, null) (VentilatorVitalData);