import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

export const deviceVitalDataReducer = (state = initialState.deviceVitalDataReducer, action) => {
    switch (action.type) {
        case Types.VENTILATOR_HEARTBEAT_DATA: {
            let bedId = action.payload.bedId;
            let heartbeat = action.payload?.isConnected || false; 
            if (!bedId) return {
                ...state
            };

            let ventilatorHeartbeatState = state.ventilatorHeartbeat;

            ventilatorHeartbeatState[bedId] = {
                isConnected: heartbeat
            }
            
            return {
                ...state,
                ventilatorHeartbeat: { ...ventilatorHeartbeatState }
            }
        };

        case Types.VENTILATOR_VITAL_DATA: {
            let bedId = action.payload.bedId;
            let ventilatorId = action.payload.deviceId;
            let deviceType = action.payload.deviceType && action.payload.deviceType.toUpperCase();
            let ventilatorVitalData = action.payload.monitorsData;
            if (!bedId || !ventilatorId || !ventilatorVitalData) {
                return {
                    ...state
                }
            };

            let vStateData = state.ventilatorVitalData;

            vStateData[bedId] = {
                ...vStateData[bedId],
                bedId: bedId,
                ventilatorId: ventilatorId,
                deviceType: deviceType,
                ventilatorVitalData: ventilatorVitalData
            }
            return {
                ...state,
                ventilatorVitalData: { ...vStateData }
            }
        }

        case Types.VENTILATOR_STANDBY_DATA: {
            let bedId = action.payload.bedId;
            let ventilationStatus = action.payload.ventilationStatus;
            
            if (!bedId) {
                return {
                    ...state
                }
            };

            let vStateData = state.ventilatorStandby;

            vStateData[bedId] = {
                ...vStateData[bedId],
                bedId: bedId,
                ventilationStatus: ventilationStatus
            }
            return {
                ...state,
                ventilatorStandby: { ...vStateData }
            }
        }

        case Types.VENTILATOR_WAVEFORM_DATA: {
            if (!action.payload) return {
                ...state
            };
            let bedId = action.payload.bedId;
            let ventilatorId = action.payload.deviceId;
            let deviceType = action.payload.deviceType && action.payload.deviceType.toUpperCase();
            let waveformdata = action.payload.waveformdata;
            let observedAt = action.payload.observedAt;
            let packetNumber = action.payload.packetCounter
            //console.log("packetNumber", packetNumber);
            if (!bedId || !ventilatorId || !waveformdata) return;
            let vStateWaveformData = state.ventilatorWaveformData || {};


            let currentPacketObservation = observedAt;
            // console.log("packetNumber", props?.ventilatorWaveformData?.packetNumber)
            if (vStateWaveformData?.[bedId] && !vStateWaveformData?.[bedId]?.lag) {
                vStateWaveformData[bedId].lag = Date.now() - currentPacketObservation;
                return {
                    ...state
                };
            }
            let currentPacketObservationTime = currentPacketObservation + vStateWaveformData?.[bedId]?.lag;
            let pressurePoints = waveformdata.pressure || [];
            let flowPoints = waveformdata.flow || [];
            let volumePoints = waveformdata.volume || [];
            
            let pressureStreamArray = [];
            pressurePoints.map((point, i) => {
                if (!isNaN(currentPacketObservationTime) && point !== undefined && point !== null) {
                    pressureStreamArray.push(
                        {
                            x: (currentPacketObservationTime + (1000 / pressurePoints.length) * i),
                            y: point / 100,
                        }
                    )
                }
                // eslint-disable-next-line array-callback-return
                return;
            })

            let flowStreamArray = [];
            flowPoints.map((point, i) => {
                if (!isNaN(currentPacketObservationTime) && point !== undefined && point !== null) {
                    flowStreamArray.push(
                        {
                            x: (currentPacketObservationTime + (1000 / flowPoints.length) * i),
                            y: point / 100
                        }
                    )
                }
                // eslint-disable-next-line array-callback-return
                return;
            })

            let volumeStreamArray = [];
            volumePoints.map((point, i) => {
                if (!isNaN(currentPacketObservationTime) && point !== undefined && point !== null) {
                    volumeStreamArray.push(
                        {
                            x: currentPacketObservationTime + (1000 / volumePoints.length) * i,
                            y: point
                        }
                    )
                }
                // eslint-disable-next-line array-callback-return
                return;
            })
            
            let pressurePointsQueue = vStateWaveformData?.[bedId]?.ventilatorWaveformData?.pressure?.splice(-5500) || [{x: NaN, y: NaN}];
            let flowPointsQueue = vStateWaveformData?.[bedId]?.ventilatorWaveformData?.flow?.splice(-5500) || [{x: NaN, y: NaN}];
            let volumePointsQueue = vStateWaveformData?.[bedId]?.ventilatorWaveformData?.volume?.splice(-5500) || [{x: NaN, y: NaN}];

            vStateWaveformData[bedId] = {
                ...vStateWaveformData[bedId],
                bedId: bedId,
                packetNumber: packetNumber,
                ventilatorId: ventilatorId,
                deviceType: deviceType,
                observedAt: observedAt,
                ventilatorWaveformData: {
                    pressure: [ ...pressurePointsQueue, ...pressureStreamArray ],
                    flow: [ ...flowPointsQueue, ...flowStreamArray ],
                    volume: [ ...volumePointsQueue, ...volumeStreamArray ]
                }
            }
            //console.log("packetNumber", vStateWaveformData[bedId].ventilatorWaveformData.pressure);
            return {
                ...state,
                ventilatorWaveformData: { ...vStateWaveformData }
            }
        }
        case Types.VENTILATOR_MODES_DATA: {
            let payload = action.payload
            let bedId = payload.bedId;
            let ventilatorId = payload.deviceId;
            let deviceType = payload.deviceType && payload.deviceType.toUpperCase();
            let ventilatorModesData = payload.modesParameter;
            let modeName = payload.modeName;
            let patientType = payload.patientType;

            let vStateData = state.ventilatorModesData;

            vStateData[bedId] = {
                modesParameter:{
                    modeName: modeName,
                    patientType: patientType,
                }
            }

            if (!bedId || !ventilatorId || !ventilatorModesData) {
                return {
                    ...state,
                    ventilatorModesData: {...vStateData}
                }
            };

            // console.log("vStateData", vStateData)

            vStateData[bedId] = {
                ...vStateData[bedId],
                modeName: modeName,
                bedId: bedId,
                patientType: patientType,
                ventilatorId: ventilatorId,
                deviceType: deviceType,
                modesParameter: ventilatorModesData
            }
            
            return {
                ...state,
                ventilatorModesData: { ...vStateData }
            }
        }

        case Types.MONITOR_HEARTBEAT_DATA: {
            let bedId = action.payload.bedId;
            let heartbeat = action.payload?.isConnected || false; 
            if (!bedId) return {
                ...state
            };

            let monitorHeartbeatState = state.monitorHeartbeat || {};
            monitorHeartbeatState[bedId] = {
                isConnected: heartbeat
            }
            
            return {
                ...state,
                monitorHeartbeat: { ...monitorHeartbeatState }
            }
        };


        case Types.DEVICE_VITAL_DATA: {
            let bedId = action.payload.bedId;
            let patientMonitorId = action.payload.deviceId;
            let dongleId = action.payload.dongleId;
            let deviceType = action?.payload?.deviceType?.toUpperCase();
            let vitals = action.payload.vitals;

            if (!bedId || !patientMonitorId || !vitals) {
                return {
                    ...state
                }
            };

            let deviceVitalData = state.deviceVitalData;
            deviceVitalData[bedId] = {
                ...deviceVitalData[bedId],
                dongleId: dongleId,
                bedId: bedId,
                [deviceType]: {
                    patientMonitorId: patientMonitorId,
                    deviceType: deviceType,
                    vitals: vitals
                }
            }

            return {
                ...state,
                deviceVitalData: { ...deviceVitalData }
            }
        }
        case Types.DEVICE_ECG_WAVEFORM_DATA: {
            if (!action.payload) return state;
            let bedId = action?.payload?.bedId;
            let patientMonitorId = action?.payload?.deviceId;
            let dongleId = action?.payload?.dongleId;
            let deviceType = action?.payload?.deviceType?.toUpperCase();
            let waveform = action?.payload?.waveform;
            if (!(bedId && patientMonitorId && waveform)) return;
            let deviceEcgWaveform = state.deviceEcgWaveform || {};

            // console.log("", deviceWaveform);
            let stateWaveform = (deviceEcgWaveform
                && deviceEcgWaveform[bedId]
                && deviceEcgWaveform[bedId][deviceType]
                && deviceEcgWaveform[bedId][deviceType]['waveform']);

            deviceEcgWaveform[bedId] = {
                ...deviceEcgWaveform[bedId],
                dongleId: dongleId,
                bedId: bedId,
                [deviceType]: {
                    patientMonitorId: patientMonitorId,
                    deviceType: deviceType,
                    waveform: [...waveform]
                }
            }
            // if (stateWaveform) {
            //     if (waveform) {
            //         waveform.map((vitalWaveform) => {

            //             let name = vitalWaveform.name;
            //             let stateIndex = stateWaveform.findIndex((element) => element.name === name);

            //             if (stateIndex < 0) {
            //                 stateWaveform.push(vitalWaveform);
            //             } else {
            //                 stateWaveform[stateIndex] = vitalWaveform;
            //             }

            //             deviceEcgWaveform[bedId] = {
            //                 ...deviceEcgWaveform[bedId],
            //                 dongleId: dongleId,
            //                 bedId: bedId,
            //                 [deviceType]: {
            //                     patientMonitorId: patientMonitorId,
            //                     deviceType: deviceType,
            //                     waveform: stateWaveform
            //                 }
            //             }
            //         })
            //     }
            // } else {
            //     deviceEcgWaveform[bedId] = {
            //         ...deviceEcgWaveform[bedId],
            //         dongleId: dongleId,
            //         bedId: bedId,
            //         [deviceType]: {
            //             patientMonitorId: patientMonitorId,
            //             deviceType: deviceType,
            //             waveform: waveform
            //         }
            //     }
            // }
            
            return {
                ...state,
                deviceEcgWaveform: { ...deviceEcgWaveform }
            }
        }
        case Types.DEVICE_HR_WAVEFORM_DATA: {

            if (!action.payload) return state;
            // console.log(action.payload);
            let bedId = action.payload.bedId;
            let patientMonitorId = action.payload.deviceId;
            let dongleId = action.payload.dongleId;
            let deviceType = action.payload.deviceType && action.payload.deviceType.toUpperCase();
            let waveform = action.payload.waveform;

            if (!bedId || !patientMonitorId || !waveform) return;
            let deviceHrWaveform = state.deviceHrWaveform || {};

            // console.log(deviceWaveform);
            let stateWaveform = (deviceHrWaveform
                && deviceHrWaveform[bedId]
                && deviceHrWaveform[bedId][deviceType]
                && deviceHrWaveform[bedId][deviceType]['waveform']);

            deviceHrWaveform[bedId] = {
                ...deviceHrWaveform[bedId],
                dongleId: dongleId,
                bedId: bedId,
                [deviceType]: {
                    patientMonitorId: patientMonitorId,
                    deviceType: deviceType,
                    waveform: [...waveform]
                }
            }
            // if (stateWaveform) {
            //     // if (waveform) {
            //     //     waveform.map((vitalWaveform) => {

            //     //         let name = vitalWaveform.name;
            //     //         let stateIndex = stateWaveform.findIndex((element) => element.name === name);

            //     //         if (stateIndex < 0) {
            //     //             stateWaveform.push(vitalWaveform);
            //     //         } else {
            //     //             stateWaveform[stateIndex] = vitalWaveform;
            //     //         }

            //     //         deviceHrWaveform[bedId] = {
            //     //             ...deviceHrWaveform[bedId],
            //     //             dongleId: dongleId,
            //     //             bedId: bedId,
            //     //             [deviceType]: {
            //     //                 patientMonitorId: patientMonitorId,
            //     //                 deviceType: deviceType,
            //     //                 waveform: stateWaveform
            //     //             }
            //     //         }
            //     //     })
            //     // }

            //     deviceHrWaveform[bedId] = {
            //         ...deviceHrWaveform[bedId],
            //         dongleId: dongleId,
            //         bedId: bedId,
            //         [deviceType]: {
            //             patientMonitorId: patientMonitorId,
            //             deviceType: deviceType,
            //             waveform: waveform
            //         }
            //     }
            // } else {
            //     deviceHrWaveform[bedId] = {
            //         ...deviceHrWaveform[bedId],
            //         dongleId: dongleId,
            //         bedId: bedId,
            //         [deviceType]: {
            //             patientMonitorId: patientMonitorId,
            //             deviceType: deviceType,
            //             waveform: waveform
            //         }
            //     }
            // }

            return {
                ...state,
                deviceHrWaveform: { ...deviceHrWaveform }
            }
        }
        default:
            return state;
    }
}

