import { loginAPI, loginTrackAPI } from "../adapter/cms-adapter";
import { LOGIN_CONST } from "../common/constant/page-constants";
import { setCookieCustom } from '../common/utils/StorageUItils';

export const login = (data) => {

    let reqData = {
        email: data.email,
        password: data.password
    }

    let promise = new Promise(function (resolve, reject) {

        loginAPI(reqData).then((res) => {

            if (res && res.data && res.data.data) {
                let accessToken = res.data.data.accessToken;
                let refreshToken = res.data.data.accessToken;

                if (accessToken && refreshToken) {
                    setCookieCustom(LOGIN_CONST.ACCESS_TOKEN, accessToken);
                    setCookieCustom(LOGIN_CONST.REFRESH_TOKEN, refreshToken);
                }
                resolve(res.data.data);
            } else {
                if (res?.data?.data) reject(res.data.data.message);
            }
        }).catch((err) => {
            reject("Login Failure");
        })
    });

    return promise;
}

export const loginTrack = (data) => {
    let reqData = data;
    let promise = new Promise(function (resolve, reject) {
        loginTrackAPI(reqData).then((res) => {
            if (res && res.data) {
                resolve(res.data);
            } else {
                reject("error in tracker promise data");
            }
        }).catch((err) => {
            reject("error in tracker promise", err)

        })
    })
    return promise;
}