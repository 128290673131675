import { ALARM_STATUS } from "../../common/constant/common-constants";
import { getAlarmPriority } from "../../common/utils/CommonUtils";
import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";
import { DEVICE_TYPES } from "../../common/constant/common-constants";

export const alarmDataReducer = (state = initialState.alarmDataReducer, action) => {

    switch (action.type) {
        case Types.ALARM_DATA: {
            let bedId = action.payload.bedId;
            let deviceId = action.payload.deviceId || "";
            let dongleId = action.payload.dongleId || "";
            let deviceType = action.payload.deviceType;
            let newAlarms = action.payload.alarms || [];

            if (!bedId ||
                // !deviceId 
                !newAlarms) return;
            let alarmData = state.alarmData;

            let stateAlarms = (state?.alarmData?.[bedId]?.alarms) || [];

            let currentAlarms = [...stateAlarms];

            if (newAlarms && newAlarms.length > 0) {
                newAlarms.map((nAlarm) => {

                    let status = nAlarm.status;

                    if (status === ALARM_STATUS.INACTIVE) {

                        const index = currentAlarms.findIndex((cAlarm) => cAlarm.name === nAlarm.name);

                        // console.log("inactive alarm index: " + index, currentAlarms);
                        if (index > -1) currentAlarms.splice(index, 1);

                    } else {

                        // delete previous if already exist
                        const index = currentAlarms.findIndex((cAlarm) => {
                            return (cAlarm.name === nAlarm.name)
                        });

                        // console.log("active alarm index: " + index);
                        if (index > -1) currentAlarms.splice(index, 1);

                        // push new alarm
                        currentAlarms.unshift(nAlarm);
                    }
                })
            }

            // console.log("final currentAlarms: ", currentAlarms);
            // for newAlarm, if not exist, push data
            // currentAlarms.unshift(...newAlarms);
            if (currentAlarms.length > 20) currentAlarms.splice(20);

            currentAlarms.sort(function (alarm1, alarm2) {
                let alarm1Priority = getAlarmPriority(alarm1.priority);
                let alarm2Priority = getAlarmPriority(alarm2.priority);

                return alarm2Priority - alarm1Priority;
            });

            alarmData[bedId] = {
                ...alarmData[bedId],
                dongleId: dongleId,
                bedId: bedId,
                alarms: [...currentAlarms]
            }

            return {
                ...state,
                alarmData: { ...alarmData }
            }
        }

        case Types.VENTILATOR_ALARM_DATA: {
            let bedId = action.payload.bedId;
            let newAlarms = action.payload.alarms || [];

            if (!bedId || !newAlarms) return;
            let alarmData = state.alarmData;

            newAlarms.sort(function (alarm1, alarm2) {
                let alarm1Priority = getAlarmPriority(alarm1.priority);
                let alarm2Priority = getAlarmPriority(alarm2.priority);
                return alarm1.isNotification || alarm2Priority - alarm1Priority;
            });

            alarmData[bedId] = {
                ...alarmData[bedId],
                bedId: bedId,
                ventilatorAlarms: [...newAlarms]
            }
            return {
                ...state,
                alarmData: { ...alarmData }
            }
        }

        case Types.CLEAR_BED_ALARM:
            let bedId = action.payload.bedId;
            let deviceId = action.payload.deviceId || "";
            let dongleId = action.payload.dongleId || "";
            let deviceType = action.payload.deviceType;
            if (!bedId) return;

            // let bedAlarmData = state.alarmData && state.alarmData[bedId] && state.alarmData[bedId].alarms;
            // if (bedId === 19)
            //     console.log("before action", bedAlarmData);

            // // bedAlarmData = [];
            let alarmData = state.alarmData;
            // if (bedId === 19)
            //     console.log("before action", alarmData);
            // if (bedId === 19)
            //     console.log("after action", alarmData);
            return {
                ...state,
                alarmData: {
                    ...state.alarmData,
                    [bedId]: {
                        ...state.alarmData[bedId],
                        alarms: []
                    }
                }
            }
        case Types.CLEAR_ALARM:
            return {
                ...state,
                alarmData: {}
            }
        default:
            return state;
    }
}