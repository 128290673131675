import React, { useEffect, useState } from 'react';
import { getVentilatorMode } from '../../helper/mode-helper';
import { TEMP_MODE } from '../../common/ENUMS/VENTILATOR_MODES_ENUM';
import { SET_PARAMETERS } from '../../common/ENUMS/PARAMETER_ENUM';
import { connect } from "react-redux";
import './VentilatorSetParams.scss'

function VentilatorSetParams(props) {
    const [ventilatoModesData, setVentilatoModesData] = useState([]);

    useEffect(() => {
        getVentilatorMode(props.bedId)
            .then((res) => {
                if (res.modesParameter) {
                    let setParams = res?.modesParameter?.parameters;
                    let modeName = res.modesParameter.modeName;
                    let tempModeArr = [];
                    if (setParams?.length){
                        let i = setParams.filter((par)=> par.name === 'i')?.[0]?.value;
                        let e = setParams.filter((par)=> par.name === 'e')?.[0]?.value;
                        if (i || e) {
                            let i_e = (i >= e) ?
                                `${parseFloat(i/e).toFixed(1)} : 1`
                            :
                                `1 : ${parseFloat(e/i).toFixed(1)}`

                            console.log('i_e', i_e);
                            setParams.push(
                                {
                                    id: 100506,
                                    name: 'I:E',
                                    value: i_e
                                })
                        }
                        TEMP_MODE[modeName]?.setParams?.map((param)=>{
                            if (param === 100508){
                                let trigger_senstivity = (setParams.filter((par)=> par.name === 'flow_trigger')?.[0]?.value || setParams.filter((par)=> par.name === 'pressure_trigger')?.[0]?.value || '0');
                                setParams.push(
                                    {
                                        id : 100508,
                                        name:'trigger_senstivity',
                                        value: trigger_senstivity,
                                    }
                                );
                            }
                            let obj = [param, setParams.filter((par)=> par.id === param)?.[0]?.value];
                            tempModeArr.push(obj);
                        });
                        setVentilatoModesData(tempModeArr);
                    }
                }
            })
            .catch((err) => {
                console.log("alarm err: ", err);
            });
    }, [props.bedId])


    useEffect(() => {
        let setParams = props?.ventilatorModesData?.modesParameter?.parameters;
        let modeName = props?.ventilatorModesData?.modesParameter?.modeName;
        let tempModeArr = [];
        if (setParams?.length){
            let i = setParams.filter((par)=> par.name === 'i')?.[0]?.value;
            let e = setParams.filter((par)=> par.name === 'e')?.[0]?.value;
            if (i && e) {
                let i_e = (i >= e) ?
                    `${parseFloat(i/e).toFixed(1)} : 1`
                :
                    `1 : ${parseFloat(e/i).toFixed(1)}`
                setParams.push(
                    {
                        id: 100506,
                        name: 'I:E',
                        value: i_e
                    })
            }
            
            TEMP_MODE[modeName]?.setParams?.map((param)=>{
                if (param === 'trigger_senstivity'){
                    let trigger_senstivity = (setParams.filter((par)=> par.name === 'flow_trigger')?.[0]?.value || setParams.filter((par)=> par.name === 'pressure_trigger')?.[0]?.value || '0');
                    setParams.push(
                        {
                            id : 100508,
                            name:'trigger_senstivity',
                            value: trigger_senstivity,
                        }
                    );
                }
                let obj = [param, setParams.filter((par)=> par.id === param)?.[0]?.value];
                tempModeArr.push(obj);
            });
            setVentilatoModesData(tempModeArr);
        }
    }, [props?.ventilatorModesData])


    return (
        <div className='vital-footer-container'>
            {ventilatoModesData && ventilatoModesData?.map((item, i) => {
                    return (
                        <div className='footer-param-box'>
                            <div className='footer-param-name'>{SET_PARAMETERS?.[item[0]]?.TITLE_START}<sub>{SET_PARAMETERS?.[item[0]]?.TITLE_SUB_SCRIPT}</sub>{SET_PARAMETERS?.[item[0]]?.TITLE_END}</div>
                            <div className='footer-param-value'>{!isNaN(item[1]) ? (item[1] % 1 !== 0 ? parseFloat(item[1]).toFixed(2) : item[1]) : item[1] || '--'}</div>
                            <div className='footer-param-unit'>{SET_PARAMETERS?.[item[0]]?.UNIT_START}<sub>{SET_PARAMETERS?.[item[0]]?.UNIT_SUB_SCRIPT}</sub>{SET_PARAMETERS?.[item[0]]?.UNIT_END}</div>
                        </div>
                    )
                }
            )}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    let vModeData = state.deviceVitalDataReducer?.ventilatorModesData?.[ownProps.bedId];

    return {
        ventilatorModesData: vModeData,
        config: state.configReducer.config.bedDetailConfig
    }
}

export default connect(mapStateToProps, null) (VentilatorSetParams);