import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './TrendChart.scss';

function TrendChart(props) {
    const [headerList, setHeaderList] = useState(['', ...props.headerList]);
    const [parameterList, setParameterList] = useState(props.parameterList);

    const [data, setData] = useState();

    useEffect(() => {
        if (props.headerList) setHeaderList(['', ...props.headerList]);
        setParameterList([...props.parameterList]);
        setData({ ...props.data });
    }, [props]);

    const getHtml = () => {

        let html = headerList && headerList.map((header, count) => {
            /* for rendering parameters in first column */
            console.log("header", header);
            if (count === 0) {
                return (
                    <div className="column parameters" key={header}>
                        <div className="cell heading param">Time</div>
                        {parameterList.map((param) => {
                            return (<div className="cell body param" key={param.label}>{param.label}</div>)
                        })}
                    </div>
                );
            } else {
                /** rendering data from the data array **/
                let col = (
                    data && data.data &&
                    data.data.filter(
                        (obj) => moment(obj.time).isSame(header)
                    )[0]
                ) || null;
                return (
                    <div className="column" key={header}>
                        <div className="cell heading param">{moment(header).subtract(330, 'minutes').format("HH:mm")}</div>
                        {parameterList.map((param) => {
                            return (
                                <div className="cell body" key={param.name}>
                                    {col ? col[param.name] : ""}
                                </div>);
                        })}
                    </div>
                );
            }
        });

        return html;
    }
    return (
        <div className='table'>
            {getHtml()}
        </div>
    );
}

export default TrendChart;