import { getTrendDataAPI } from "../adapter/cms-adapter";
import { getVentilatorTrendDataAPI } from "../adapter/cms-adapter";

export const getTrendData = (data) => {

    let reqData = data;

    let promise = new Promise(function (resolve, reject) {

        getTrendDataAPI(reqData).then((res) => {

            if (res && res.data && res.data.data) resolve(res.data.data);
            else reject("some error message getAlarmHistory");
        })
    })

    return promise;
}


export const getVentilatorTrendData = (data) => {

    let reqData = data;

    let promise = new Promise(function (resolve, reject) {

        getVentilatorTrendDataAPI(reqData).then((res) => {

            if (res && res.data && res.data.data) resolve(res.data.data);
            else reject("some error in calling getVentilatorTrendData");
        })
    })

    return promise;
}