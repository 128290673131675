import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import moment from "moment";
import { MODE } from "../../common/ENUMS/VENTILATOR_MODES_ENUM";
import './index.scss';
import { setVentilatorWaveformData } from "../../socket/socket";
import { Line } from "react-chartjs-2";

const dataArray = new Array(5000);
const currentTime = new Date();

// Start 10 seconds before the current time
currentTime.setSeconds(currentTime.getSeconds() - 10);

for (let i = 0; i < dataArray.length; i++) {
    const time = new Date(currentTime.getTime() + i * 2); // Increment by 2 milliseconds
    const formattedTime = time.toISOString(); // Format the time as per your requirement
    dataArray[i] = { x: formattedTime, y: NaN };
}
const VentilatorChart = (props) => {
  const [bedId, setBedId] = useState(null);
  const [pressurePlotted, setPressurePlotted] = useState(dataArray);
  const [flowPlotted, setFlowPlotted] = useState(dataArray);
  const [volumePlotted, setVolumePlotted] = useState(dataArray);

  const waveSocket = (event) => {
    if (event.bedId !== props.bedId || !event.observedAt) return;
    const { observedAt, waveformdata } = event;

    const updateGraphData = (data, setData) => {
      if (!data || data.length === 0) return;
      const gap = 1000 / data.length;
      let currentMilli = 0;
      const mappedData = [];
  
      data.forEach((val) => {
        const tempTime = moment(observedAt).millisecond(currentMilli);
        mappedData.push({
          x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
          y: val
        });
        currentMilli += gap;
      });
  
      setData((prevDataObj) => {
        const newDataList = [...prevDataObj, ...mappedData];
        return newDataList.slice(-5000); // Keep only the last 5000 points
      });
    };
  
    updateGraphData(waveformdata.pressure, setPressurePlotted);
    updateGraphData(waveformdata.flow, setFlowPlotted);
    updateGraphData(waveformdata.volume, setVolumePlotted);
  };
  

useEffect(()=>{
  setVentilatorWaveformData(waveSocket)
},[])

useEffect(() => {
  if (bedId !== props.bedId) {
    setVentilatorWaveformData(waveSocket)
    setPressurePlotted(dataArray);
    setFlowPlotted(dataArray);
    setVolumePlotted(dataArray);
    setBedId(props.bedId);
  }
}, [props.bedId])


const options = (stepSize, plottedPoints, index) => {
    let maxY = plottedPoints[0]?.y;

    // Iterate through the array to find the maximum y value
    for (const point of plottedPoints) {
        if (!isNaN(point?.y) && (point?.y > maxY || isNaN(maxY))) {
            maxY = point?.y;
        }
    }
    return(
        {
            animation: {
                duration: 0, // Set the animation duration to 0 to disable it
            },
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        borderColor: '#666666',
                    },
                    display: true,
                    position: {y: 0},
                    beginAtZero: true,
                },
                y: {
                    grid: {
                        color: '#111',
                        borderColor: '#666666',
                    },
                    max: Math.ceil((Math.max(maxY) + Math.max(maxY) * 25 / 100)/index) || 1,
                    ticks: {
                        display: true, // Display tick marks along the y-axis
                        stepSize: stepSize,
                    },
                    type: 'linear',
                    beginAtZero: true,
                    display: true,
                    scaleLabel: {
                        display: modeName?.toUpperCase() === MODE[1012].modeName ? false : true,
                        labelString: 'Value'
                    },
                    min: modeName?.toUpperCase() === MODE[1012].modeName ? 0 : null
                }
            },
            plugins: {
                legend: {
                    display: false, // Hide the legend
                },
                tooltip: {
                    enabled: false, // Disable tooltips
                },
                customCanvasBackgroundColor: {
                  color: 'transparent',
                },
                annotation: {
                    annotations: {
                      line: {
                          drawTime: 'afterDatasetsDraw',
                          type: 'line',
                          scaleID: 'y',
                          value: 0,
                          borderWidth: 2,
                      },
                    }
                }
            },
            elements: {
                line: {
                    tension: 0 // Disable bezier curves to get straight lines
                }
            },
            maintainAspectRatio: false, // Set to false to allow direct control over width and height
            responsive: true,
        }
    )
}

const modeName = props?.ventilatorModesData?.modesParameter?.modeName;
  return (
    <>
        {modeName?.toUpperCase() !== MODE[1012].modeName &&
          <div className='graph' key={'pressure-graph'} style={{height: '27%'}}>
            <>
              <div className='graph-label'>Pressure (cmH<sub>2</sub>O)</div>
              <Line 
                  data={{
                      labels: pressurePlotted.map(dataPoint => dataPoint.x),
                      datasets: [{
                          label: 'Pressure (cmH2O)',
                          data: pressurePlotted.map(dataPoint => dataPoint.y / 100),
                          borderColor: '#3ADAE5',
                          fill: false,
                          pointRadius: 0,
                          tension: 0.1,
                          borderWidth: 2
                      }]
                  }}
                  options={options(1, pressurePlotted, 100)}
                  />
            </>
          </div>
        }
        <div className='graph' key={'flow-graphs'} style={{height: modeName?.toUpperCase() === MODE[1012].modeName ? '87%' : '27%'}}>
          <div className='graph-label'>Flow (l/min)</div>
          <Line 
              data={{
                labels: flowPlotted.map(dataPoint => dataPoint.x),
                datasets: [{
                  label: 'Flow (ml)',
                  data: flowPlotted.map(dataPoint => dataPoint.y / 100),
                  borderColor: '#299842',
                  fill: false,
                  pointRadius: 0,
                  tension: 0.1,
                  borderWidth: 2
                }]
              }}
              options={options(2, flowPlotted, 100)}
            />
        </div>
        {modeName?.toUpperCase() !== MODE[1012].modeName &&
          <div className='graph' key={'volume-graph'} style={{height: '27%'}}>
            <div className='graph-label'>Volume (ml)</div>
            <Line 
                data={{
                    labels: volumePlotted.map(dataPoint => dataPoint.x),
                    datasets: [{
                        label: 'Volume (ml)',
                        data: volumePlotted.map(dataPoint => dataPoint.y),
                        borderColor: '#FCC331',
                        fill: false,
                        pointRadius: 0,
                        tension: 0.1,
                        borderWidth: 2
                    }]
                }}
                options={options(1, volumePlotted, 1)}
            />
          </div>
        }
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  let waveformData = state.deviceVitalDataReducer?.ventilatorWaveformData?.[ownProps.bedId];
  let vModeData = state.deviceVitalDataReducer?.ventilatorModesData?.[ownProps.bedId];

  return {
      ventilatorWaveformData: waveformData,
      ventilatorModesData: vModeData,
      config: state.configReducer.config.bedDetailConfig
  }
}

export default connect(mapStateToProps, null) (VentilatorChart);